import { stringify as safeStableStringify } from 'safe-stable-stringify'
import { t } from 'ttag'
import { IDBModDoc } from './DBTypes'
import { ElectronAPI } from './ElectronAPI'
import { DOCS_API_GET_LOCAL_SPOTS, DOCS_API_GET_REMOTE_SPOTS, DOCS_API_GET_STORED_LOCAL_CLIENT_IDS, DOCS_API_RETRIEVE_LOCAL_CLIENT_DOCS, DOCS_API_RETRIEVE_REMOTE_DOCS, DOCS_API_SAVE_LOCAL_SPOTS, DOCS_API_SAVE_REMOTE_SPOTS, DOCS_API_STORE_LOCAL_DOCS, DOCS_API_STORE_REMOTE_DOCS, GetLocalSpotsArgs, GetLocalSpotsResponse, GetRemoteSpotsArgs, GetRemoteSpotsResponse, GetStoredLocalClientIdsArgs, GetStoredLocalClientIdsResponse, RetrieveLocalClientDocsArgs, RetrieveLocalClientDocsResponse, RetrieveRemoteDocsArgs, RetrieveRemoteDocsResponse, SaveLocalSpotsArgs, SaveRemoteSpotsArgs, SaveRemoteSpotsResponse, StoreLocalDocsArgs, StoreLocalDocsResponse, StoreRemoteDocsArgs, StoreRemoteDocsResponse } from './lanStorage/docs.d'
import { ListVcrFilesArgs, ListVcrFilesResponse, RetrieveVcrsArgs, RetrieveVcrsResponse, StoreVcrArgs, StoreVcrResponse, VIDEO_CACHE_RECORDS_API_LIST_VCR_FILES, VIDEO_CACHE_RECORDS_API_RETRIEVE_VCRS, VIDEO_CACHE_RECORDS_API_STORE_VCR } from './lanStorage/vcrs.d'
import { generate4DigitHex } from './utils/hashUtils'
import { CLIENTS_API_REGISTER_CLIENT_USER, RegisterClientUserArgs, RegisterClientUserResponse } from './lanStorage/clients.d'
import { BLOBS_API_RETRIEVE_ALL_BLOB_IDS, BLOBS_API_RETRIEVE_BLOB, BLOBS_API_STORE_BLOB, RetrieveAllBlobIdsArgs, RetrieveAllBlobIdsResponse, RetrieveBlobArgs, RetrieveBlobResponse, StoreBlobArgs, StoreBlobResponse } from './lanStorage/blobs.d'
import { CONNECTIONS_API_CONNECT_TO_URL, CONNECTIONS_API_PROBE, ConnectToUrlArgs, ConnectToUrlResponse, ProbeConnectionsArgs, ProbeConnectionsResponse } from './lanStorage/connections.d'
import { userError } from '../components/utils/Errors'

const log = require('debug')('sltt:SlttAppStorage')

type WindowInSlttApp = Window & typeof globalThis & { electron: ElectronAPI }
const windowInSlttApp = window as WindowInSlttApp

export function getClientId() {
    if (!localStorage.slttStorageClientId) {
        localStorage.slttStorageClientId = generate4DigitHex()
    }
    return localStorage.slttStorageClientId
}

let _isSlttAppStorageEnabled: boolean = false

export const getHasElectronContext = () => !!windowInSlttApp.electron
const getHasValidLANStorageConnectionUrl = (connectionUrl?: string) => (connectionUrl ? connectionUrl?.length >= 3 : false)

export const isSlttAppStorageEnabled = () => {
    const hasElectronContext = getHasElectronContext()
    const hasValidLANStorageConnectionUrl = getHasValidLANStorageConnectionUrl(localStorage.LANStorageConnectionUrl)
    const newIsSlttAppStorageEnabled = hasElectronContext && hasValidLANStorageConnectionUrl
    if (hasElectronContext && _isSlttAppStorageEnabled !== newIsSlttAppStorageEnabled) {
        log('isSlttAppStorageEnabled', { hasElectronContext, 'localStorage.LANStorageConnectionUrl': localStorage.LANStorageConnectionUrl, _isSlttAppStorageEnabled, newIsSlttAppStorageEnabled })
        _isSlttAppStorageEnabled = newIsSlttAppStorageEnabled
    }
    return _isSlttAppStorageEnabled
}

const probeLANStorageConnections = async (urls?: string[]): Promise<ProbeConnectionsResponse> => {
    if (!getHasElectronContext()) throw new Error('probeLANStorageConnections should be called from an electron context')
    
    const clientId = getClientId()
    const args: ProbeConnectionsArgs = { clientId, urls }
    const response: ProbeConnectionsResponse = await windowInSlttApp.electron.ipcRenderer.invoke(CONNECTIONS_API_PROBE, args)
    console.log(`probeLANStorageConnections ${CONNECTIONS_API_PROBE} response`, response)
    console.log('localStorage.LANStorageConnectionUrl', localStorage.LANStorageConnectionUrl)
    return response
}

const connectToLANStorage = async (url: string = localStorage.LANStorageConnectionUrl): Promise<ConnectToUrlResponse> => {
    if (!getHasElectronContext()) throw new Error('connectToLANStorage should be called from an electron context')
    const hasLANStorageConnectionUrl = getHasValidLANStorageConnectionUrl(url)
    if (!hasLANStorageConnectionUrl) {
        throw new Error(`Expected valid lanStorageConnectionUrl got '${url}'. localStorage.LANStorageConnectionUrl '${localStorage.LANStorageConnectionUrl}'`)
    }
    console.log('localStorage.LANStorageConnectionUrl (orig)', localStorage.LANStorageConnectionUrl)
    console.log('connectToLANStorage', { lanStorageConnectionUrl: url })
    const clientId = getClientId()
    const args: ConnectToUrlArgs = { clientId, url }
    const response: ConnectToUrlResponse = await windowInSlttApp.electron.ipcRenderer.invoke(CONNECTIONS_API_CONNECT_TO_URL, args)
    console.log(`connectToLANStorage ${CONNECTIONS_API_CONNECT_TO_URL} response`, response)
    localStorage.LANStorageConnectionUrl = url
    console.log('localStorage.LANStorageConnectionUrl (new)', localStorage.LANStorageConnectionUrl)
    return response
}

const disconnectFromLANStorage = () => {
    if (!getHasElectronContext()) throw new Error('disconnectFromLANStorage should be called from an electron context')
    localStorage.removeItem('LANStorageConnectionUrl')
}

(window as any).probeLANStorageConnections = probeLANStorageConnections;
(window as any).connectToLANStorage = connectToLANStorage;
(window as any).disconnectFromLANStorage = disconnectFromLANStorage;

export class SlttAppStorageDisabledError extends Error {
    constructor(apiCall: string, logContext: string) {
        super(`isSlttAppStorageEnabled should be true when invoking api '${apiCall}' from context: ${logContext}`)
        this.name = 'SlttAppStorageDisabledError';
    }
}

export const registerClientUser = async ({ username }: Omit<RegisterClientUserArgs, 'clientId'>, logContext: string): Promise<RegisterClientUserResponse | null> => {
    if (!isSlttAppStorageEnabled()) throw new SlttAppStorageDisabledError('registerClientUser', logContext)
    if (await getHasLostConnection('registerClientUser', logContext)) return null

    const clientId = getClientId()
    const response: RegisterClientUserResponse = await windowInSlttApp.electron.ipcRenderer.invoke(CLIENTS_API_REGISTER_CLIENT_USER, { clientId, username })
    log(`${logContext} ${CLIENTS_API_REGISTER_CLIENT_USER} response`, response)
    return response
}

export const storeBlob = async (blob: Blob, { blobId }: { blobId: string }, logContext: string) => {
    if (!isSlttAppStorageEnabled()) throw new SlttAppStorageDisabledError('storeBlob', logContext)
    if (await getHasLostConnection('storeBlob', logContext)) return

    const arrayBuffer = await blob.arrayBuffer()
    const clientId = getClientId()
    const storeBlobArgs: StoreBlobArgs = { clientId, blobId, arrayBuffer }
    log(`${logContext} ${BLOBS_API_STORE_BLOB} to windowInSlttApp...`, clientId, blobId, arrayBuffer)
    const response: StoreBlobResponse = await windowInSlttApp.electron.ipcRenderer.invoke(BLOBS_API_STORE_BLOB, storeBlobArgs)
    log(`${logContext} ${BLOBS_API_STORE_BLOB} response`, response)
}

export const retrieveBlob = async ({ blobId }: Omit<RetrieveBlobArgs, 'clientId'>, logContext: string): Promise<Blob | null> => {
    if (!isSlttAppStorageEnabled()) throw new SlttAppStorageDisabledError('retrieveBlob', logContext)
    if (await getHasLostConnection('retrieveBlob', logContext)) return null

    const clientId = getClientId()
    const retrieveBlobArgs: RetrieveBlobArgs = { clientId, blobId }
    const arrayBuffer: RetrieveBlobResponse = await windowInSlttApp.electron.ipcRenderer.invoke(BLOBS_API_RETRIEVE_BLOB, retrieveBlobArgs)
    log(`${logContext} ${BLOBS_API_RETRIEVE_BLOB} from windowInSlttApp`, blobId, arrayBuffer)
    return arrayBuffer ? new Blob([arrayBuffer]) : null
}

export const retrieveAllBlobIds = async (logContext: string): Promise<RetrieveAllBlobIdsResponse> => {
    if (!isSlttAppStorageEnabled()) throw new SlttAppStorageDisabledError('retrieveAllBlobIds', logContext)
    if (await getHasLostConnection('retrieveAllBlobIds', logContext)) return []
    const retrieveAllBlobIdsArgs: RetrieveAllBlobIdsArgs = { clientId: getClientId() }
    const response: RetrieveAllBlobIdsResponse = await windowInSlttApp.electron.ipcRenderer.invoke(BLOBS_API_RETRIEVE_ALL_BLOB_IDS, retrieveAllBlobIdsArgs)
    log(`${logContext} ${BLOBS_API_RETRIEVE_ALL_BLOB_IDS} response`, response)
    return response
}

export const storeVideoCacheRecord = async ({ videoCacheRecord: vcr }: Omit<StoreVcrArgs, 'clientId'>, logContext: string): Promise<StoreVcrResponse | null> => {
    if (!isSlttAppStorageEnabled()) throw new SlttAppStorageDisabledError('storeVideoCacheRecord', logContext)
    if (await getHasLostConnection('storeVideoCacheRecord', logContext)) return null
    const clientId = getClientId()
    const serializableVcr = makeObjSerializable(vcr)
    const storeVcrArgs: StoreVcrArgs = { clientId, videoCacheRecord: serializableVcr }
    const response = await windowInSlttApp.electron.ipcRenderer.invoke(
        VIDEO_CACHE_RECORDS_API_STORE_VCR, storeVcrArgs 
    )
    log(`${logContext} ${VIDEO_CACHE_RECORDS_API_STORE_VCR} response`, response)
    return response
}

/**
 * Lists all video cache records in the storage.
 * 
 * This function queries the local storage to retrieve a filename list of all cached video records.
 * 
 * @project The project name to list video cache records for. Pass empty string for all projects
 * 
 * @returns {Promise<string[]>} A promise that resolves with an array of video record filenames.
 */
export const listVideoCacheRecordFiles = async ({ project }: Omit<ListVcrFilesArgs, 'clientId'>, logContext: string): Promise<ListVcrFilesResponse> => {
    if (!isSlttAppStorageEnabled()) throw new SlttAppStorageDisabledError('listVideoCacheRecordFiles', logContext)
    if (await getHasLostConnection('listVideoCacheRecordFiles', logContext)) return []

    const clientId = getClientId()
    const listVcrArgs: ListVcrFilesArgs = { clientId, project }
    const response = await windowInSlttApp.electron.ipcRenderer.invoke(
        VIDEO_CACHE_RECORDS_API_LIST_VCR_FILES, listVcrArgs
    )
    log(`${logContext} ${VIDEO_CACHE_RECORDS_API_LIST_VCR_FILES} response`, response)
    return response
}

export const retrieveVideoCacheRecords = async ({ filename }: Omit<RetrieveVcrsArgs, 'clientId'>, logContext: string): Promise<RetrieveVcrsResponse> => {
    if (!isSlttAppStorageEnabled()) throw new SlttAppStorageDisabledError('retrieveVideoCacheRecords', logContext)
    if (await getHasLostConnection('retrieveVideoCacheRecords', logContext)) return {}

    const clientId = getClientId()
    const retrieveVcrArgs: RetrieveVcrsArgs = { clientId, filename }
    debugger;
    const response = await windowInSlttApp.electron.ipcRenderer.invoke(
        VIDEO_CACHE_RECORDS_API_RETRIEVE_VCRS, retrieveVcrArgs
    )
    log(`${logContext} ${VIDEO_CACHE_RECORDS_API_RETRIEVE_VCRS} response`, response)
    return response
}

export const storeRemoteDocs = async (
    { project, seqDocs }: Omit<StoreRemoteDocsArgs<IDBModDoc>, 'clientId'>, logContext: string
): Promise<StoreRemoteDocsResponse | null> => {
    if (!isSlttAppStorageEnabled()) throw new SlttAppStorageDisabledError('storeRemoteDocs', logContext)
    if (await getHasLostConnection('storeRemoteDocs', logContext)) return null

    const clientId = getClientId()
    const storeRemoteDocsArgs: StoreRemoteDocsArgs<IDBModDoc> = { clientId, project, seqDocs }
    const response = await windowInSlttApp.electron.ipcRenderer.invoke(DOCS_API_STORE_REMOTE_DOCS, storeRemoteDocsArgs)
    log(`${logContext} ${DOCS_API_STORE_REMOTE_DOCS} response`, response)
    return response
}

export const retrieveRemoteDocs = async (
    { project, spot }: Omit<RetrieveRemoteDocsArgs, 'clientId'>, logContext: string
): Promise<RetrieveRemoteDocsResponse<IDBModDoc> | null> => {
    if (!isSlttAppStorageEnabled()) throw new SlttAppStorageDisabledError('retrieveRemoteDocs', logContext)
    if (await getHasLostConnection('retrieveRemoteDocs', logContext)) return null

    const clientId = getClientId()
    const retrieveRemoteDocsArgs: RetrieveRemoteDocsArgs = { clientId, project, spot }
    const response = await windowInSlttApp.electron.ipcRenderer.invoke(DOCS_API_RETRIEVE_REMOTE_DOCS, retrieveRemoteDocsArgs)
    log(`${logContext} ${DOCS_API_RETRIEVE_REMOTE_DOCS} response`, response)
    return response
}

export const saveRemoteDocsSpots = async (
    { project, spots }: Omit<SaveRemoteSpotsArgs, 'clientId'>, logContext: string
): Promise<SaveRemoteSpotsResponse | null> => {
    if (!isSlttAppStorageEnabled()) throw new SlttAppStorageDisabledError('saveRemoteDocsSpots', logContext)
    if (await getHasLostConnection('saveRemoteDocsSpots', logContext)) return null

    const clientId = getClientId()
    const saveRemoteSpotsArgs: SaveRemoteSpotsArgs = { clientId, project, spots }
    const response = await windowInSlttApp.electron.ipcRenderer.invoke(DOCS_API_SAVE_REMOTE_SPOTS, saveRemoteSpotsArgs)
    log(`${logContext} ${DOCS_API_SAVE_REMOTE_SPOTS} response`, response)
    return response
}

export const getRemoteSpots = async (
    { project }: Omit<GetRemoteSpotsArgs, 'clientId'>, logContext: string
): Promise<GetRemoteSpotsResponse> => {
    if (!isSlttAppStorageEnabled()) throw new SlttAppStorageDisabledError('getRemoteSpots', logContext)
    if (await getHasLostConnection('getRemoteSpots', logContext)) return {}

    const clientId = getClientId()
    const getRemoteSpotsArgs: GetRemoteSpotsArgs = { clientId, project }
    const response = await windowInSlttApp.electron.ipcRenderer.invoke(DOCS_API_GET_REMOTE_SPOTS, getRemoteSpotsArgs)
    log(`${logContext} ${DOCS_API_GET_REMOTE_SPOTS} response`, response)
    return response
}

export const storeLocalDocs = async (
    { project, docs }: Omit<StoreLocalDocsArgs<IDBModDoc>, 'clientId'>, logContext: string
): Promise<StoreLocalDocsResponse | null> => {
    if (!isSlttAppStorageEnabled()) throw new SlttAppStorageDisabledError('storeLocalDocs', logContext)
    if (await getHasLostConnection('storeLocalDocs', logContext)) return null

    const clientId = getClientId()
    const storeLocalDocsArgs: StoreLocalDocsArgs<IDBModDoc> = { clientId, project, docs }
    const response = await windowInSlttApp.electron.ipcRenderer.invoke(DOCS_API_STORE_LOCAL_DOCS, storeLocalDocsArgs)
    log(`${logContext} ${DOCS_API_STORE_LOCAL_DOCS} response`, response)
    return response
}

export const getStoredLocalClientIds = async (
    { project }: Omit<GetStoredLocalClientIdsArgs, 'clientId'>, logContext: string
): Promise<GetStoredLocalClientIdsResponse> => {
    if (!isSlttAppStorageEnabled()) throw new SlttAppStorageDisabledError('getStoredLocalClientIds', logContext)
    if (await getHasLostConnection('getStoredLocalClientIds', logContext)) return []

    const response = await windowInSlttApp.electron.ipcRenderer.invoke(DOCS_API_GET_STORED_LOCAL_CLIENT_IDS, { project })
    log(`${logContext} ${DOCS_API_GET_STORED_LOCAL_CLIENT_IDS} response`, response)
    return response
}

export const retrieveLocalClientDocs = async (
    { localClientId, project, spot }: Omit<RetrieveLocalClientDocsArgs, 'clientId'>, logContext: string
): Promise<RetrieveLocalClientDocsResponse<IDBModDoc> | null> => {
    if (!isSlttAppStorageEnabled()) throw new SlttAppStorageDisabledError('retrieveLocalClientDocs', logContext)
    if (await getHasLostConnection('retrieveLocalClientDocs', logContext)) return null

    const clientId = getClientId()
    const retrieveLocalClientDocsArgs: RetrieveLocalClientDocsArgs = { clientId, localClientId, project, spot }
    const response = await windowInSlttApp.electron.ipcRenderer.invoke(DOCS_API_RETRIEVE_LOCAL_CLIENT_DOCS, retrieveLocalClientDocsArgs)
    log(`${logContext} ${DOCS_API_RETRIEVE_LOCAL_CLIENT_DOCS} response`, response)
    return response
}

export const saveLocalSpots = async (
    { project, spots }: Omit<SaveLocalSpotsArgs, 'clientId'>, logContext: string
): Promise<SaveRemoteSpotsResponse> => {
    if (!isSlttAppStorageEnabled()) throw new SlttAppStorageDisabledError('saveLocalSpots', logContext)
    if (await getHasLostConnection('saveLocalSpots', logContext)) return

    const clientId = getClientId()
    const saveLocalSpotsArgs: SaveLocalSpotsArgs = { clientId, project, spots }
    const response = await windowInSlttApp.electron.ipcRenderer.invoke(DOCS_API_SAVE_LOCAL_SPOTS, saveLocalSpotsArgs)
    log(`${logContext} ${DOCS_API_SAVE_LOCAL_SPOTS} response`, response)
    return response
}

export const getLocalSpots = async (
    { project }: Omit<GetLocalSpotsArgs, 'clientId'>, logContext: string
): Promise<GetLocalSpotsResponse> => {
    if (!isSlttAppStorageEnabled()) throw new SlttAppStorageDisabledError('getLocalSpots', logContext)
    if (await getHasLostConnection('getLocalSpots', logContext)) return {}

    const clientId = getClientId()
    const getLocalSpotsArgs: GetLocalSpotsArgs = { clientId, project }
    const response = await windowInSlttApp.electron.ipcRenderer.invoke(DOCS_API_GET_LOCAL_SPOTS, getLocalSpotsArgs)
    log(`${logContext} ${DOCS_API_GET_LOCAL_SPOTS} response`, response)
    return response
}

const makeObjSerializable = <T>(obj: T): T => {
    const stringified = safeStableStringify(obj)
    return stringified && JSON.parse(stringified)
}
async function getHasLostConnection(apiContext: string, logContext: string) {
    try {
        await connectToLANStorage()
    } catch (e) {
        console.error(`Failed to connect to LAN storage (${localStorage.LANStorageConnectionUrl}) when invoking api '${apiContext}' from context: ${logContext}: ${e.message}`)
        localStorage.removeItem('LANStorageConnectionUrl')
        userError(t`Unexpectedly disconnected from LAN storage. Please reconnect when ready.`)
        return true
    }

    return !isSlttAppStorageEnabled()
}

