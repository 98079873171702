import _ from 'underscore'

import { 
        DBObject,
        findMember, 
        findMemberIndex,
         IVideoCacheAcceptor, 
        Member, 
        Passage, 
        PassageDocument, 
        PassageGloss, 
        PassageHighlight,    
        PassageNote, 
        PassageNoteItem, 
        PassageSegment, 
        PassageSegmentApproval, 
        PassageSegmentGloss,
        PassageSegmentLabel,
        PassageVideo, 
        PassageVideoReference, 
        Portion, 
        Project, 
        ProjectImage, 
        ProjectMessage,
        ProjectPlan,
        ProjectStage, 
        ProjectTask,
        ProjectTerm,
        FfmpegParameters,
        MultilingualString,
        PassageThumbnailVideo,
    } from './ProjectModels'

import { displayInfo, systemError } from '../components/utils/DynamicErrors'
import { RefRange } from '../scrRefs/RefRange'
import { getBookNames } from '../scrRefs/bookNames'
import { fmt, s } from '../components/utils/Fmt'

import { convertOldProjectPlan, convertOldProjectPlans } from './OldProjectPlan'  // force it to be included
import { minLocalSeq } from './DBTypes'
import { normalizeUsername as normalizeUsernameUtil } from './DBAcceptor.utils'

const log = require('debug')('sltt:DBAcceptor') 
const dbg = require('debug')('slttdbg:DBAcceptor') 

export function normalizeUsername(username: string) {
    return normalizeUsernameUtil(username)
}

let logDocs = localStorage.getItem('logDocs')

/* REMINDER
   
   If you create a new object the contains a url for a media object uploaded to S3,
   you must include code like this to trigger the video cache to upload/down the video.

        if (doc.videoUrl) {
            this.videoCacheAcceptor(doc.videoUrl, doc.creationDate)
                .catch(systemError)
        }
 */


// Portion/Passage/PassageVideo/PassageNote/PassageNoteItem

// _rev = This is a non persisted attribute. We increment it to force views that
// depend on any aspect of a model object to re-render when that object changes.
// This is probably overkill but the model does not change very often (a few times
// time an hour on the average) and extra re-renders are less problematic than
// too few re-renders.

class Builder {
    constructor(public factory: any,
        public acceptor: (target: any, env: any[], doc: any) => void,
        public attr: string) {}
}

let currentTarget: any = null
let currentDoc: any = {}
let docAttributeCheckedStatus: { [attribute: string]: boolean } = {}
let targetAttributeCheckedStatus: { [attribute: string]: boolean } = {}

const skipCheckKeys = ['_id', 'modDate', 'db', '_rev']
const shouldSkipKeyWithValue = (key: string, value: any) => {
    if (skipCheckKeys.includes(key)) return true
    if (key.startsWith('_x')) return true // from AppRoot.recreateDbDocs()
    if (typeof value === 'function') return true
    if (Array.isArray(value)) {
        // known dbObject properties that are arrays (from getBuilders()
        if ([
            'portions', 'passages', 'documents',
            'videos', 'segments', 'glosses', 'highlights',
            'references', 'notes', 'items'
        ].includes(key)) return true
    }
    return false
}

function checkSetAttributes<T extends DBObject>(target: T, doc: any) {
    if (currentDoc !== doc) {
        if (currentDoc && currentTarget) {
            // join the attributes that weren't used
            const unusedAttributes = Object.entries(docAttributeCheckedStatus).filter(([,checked]) => !checked).map(([k]) => k).join(', ')
            if (unusedAttributes) {
                log(`### checkSetAttributes: attributes (${unusedAttributes}) not checked from (${currentTarget!.constructor.name}) currentDoc: ${s(currentDoc)}`)
            }
        }
        if (target !== currentTarget) {
            currentTarget = target
        }
        targetAttributeCheckedStatus = {}
        currentDoc = doc
        docAttributeCheckedStatus = (Object.entries(doc) as any).reduce((acc: {
            [key: string]: boolean
        }, [key, value]: [string, any]) => {
            if (key === 'model' || shouldSkipKeyWithValue(key, value)) return acc
            acc[key] = false
            return acc
        }, {})
    }
}

const shouldCheckAttributeInDbDoc = localStorage.debug?.startsWith('sltt') || false
log(`shouldCheckAttributeInDbDoc`, { shouldCheckAttributeInDbDoc, debug: localStorage.debug })

function checkAttributeInDbDoc<T extends DBObject>(target: T, attributeName: string, doc: any) {
    if (!shouldCheckAttributeInDbDoc) return
    if (!(target instanceof Portion || target instanceof Passage)) return // audit only Portion and Passage docs for now
    const targetClass = target.constructor.name
    checkSetAttributes(target, doc)
    if (targetAttributeCheckedStatus[attributeName]) {
        log(`### checkAttributeInDbDoc: duplicate '${attributeName}' already checked in doc for (in ${targetClass})`)
    }
    targetAttributeCheckedStatus[attributeName] = true
    docAttributeCheckedStatus[attributeName] = true
}

function accept<T extends DBObject>(target: T, doc: any, key: keyof T, 
    fnBeforeChange?: (value: T[keyof T]) => void,
    fnAfterChange?: (value: T[keyof T]) => void 
): void {
    acceptKey<T[keyof T]>(target, doc, key as string, fnBeforeChange, fnAfterChange)
}

// If attribute is present and different than current value in target, accept new value
function acceptKey<K = any>(target: DBObject, doc: any, attributeName: string,
    fnBeforeChange?: (value: K) => void,
    fnAfterChange?: (value: K) => void
) {
    checkAttributeInDbDoc(target, attributeName, doc)
    let value: K = doc[attributeName]
    if (value === undefined) return

    if ((target as any)[attributeName] === value) return
    
    fnBeforeChange?.(value);// <-- no why clue semi-colon needed by typescript here!!
    (target as any)[attributeName] = value
    fnAfterChange?.(value)
}

function acceptJSON(target: DBObject, doc: any, attributeName: string) {
    let newValue = doc[attributeName]
    if (newValue === undefined) return
    newValue = JSON.parse(newValue)

    let currentValue = (target as any)[attributeName]

    if (currentValue === undefined || JSON.stringify(currentValue) !== JSON.stringify(newValue)) {
        (target as any)[attributeName] = newValue
    }
}

function matchLogDoc(_id: string, logDoc: string) {
    let parts = logDoc.split('=')
    if (parts[0] && !_id.startsWith(parts[0])) { return false }
    if (!parts[1]) return true
    let count = parseInt(parts[1])
    if (isNaN(count)) {
        return _id.includes(parts[1])
    }
    return (_id.split('/').length === count)
}

// Log all accepted docs whose _id matches this regex
// NOTE: The acceptorRegex is a string that should not include the initial / and the trailing /g
// The following works to see note items:
// localStorage.acceptorRegex='(/.*){4}'
const acceptorRegex = localStorage.getItem('acceptorRegex')

// Log all accepted docs whose modate is in this range
const acceptorModDate = localStorage.getItem('acceptorModDate')
const acceptorModDateEndDate = localStorage.getItem('acceptorModEndDate') // optional
export type PathInfo = {
    accepted: boolean,
    itemPath: string,
    itemPathNoTag: string,
    item: DBObject,
    itemParent: DBObject,
    itemsProperty: string,
    items: DBObject[],
    itemIndex: number,
    deletedFromParent?: boolean,
    doc: any,
    docRemoved: boolean,
    docSeq: number | string,
}

export class DBAcceptor {
    removedIds: Set<string> = new Set()
    private hasAcceptedNewPlan = false
    // Max global sequence number that we have synced
    maxSeqSynced: number = -1

    constructor(
        public project: Project, 
        public videoCacheAcceptor: IVideoCacheAcceptor,
        public model: number,
        private allowInvalidIds = false, // Useful for testing, where we don't yet generate real ids
        private applyEndPositionFix = !(localStorage.skipEndPositionFix === 'true') && 
            !localStorage.acceptLocalThruKey // set false when wanting to preserve original (reduce diffs during undelete)
        ) {
    }

        // Review: onGotPathInfo could technically be a response for accept() to return (or yield)
    accept(doc: any, label?: string, seq?: number | string, 
        onGotPathInfo?: (trackInfo: PathInfo) => void) // used only for debugging with queryLocalPathInfo
    {
        seq = seq ?? -1

        // Keep track of the highest sequence number we have synced
        // (this does not include local sequence numbers that have not been synced yet)
        if (typeof seq === 'number' && seq > this.maxSeqSynced && seq < minLocalSeq) {
            this.maxSeqSynced = seq
        }

        // We don't know how to handle any documents that specify a model newer than ours
        if (doc.model !== undefined && doc.model > this.model) {
            log("### doc with model ignored", doc)
            return
        }

        this.logDoc(doc, label)

        let { project } = this

        let parts = doc._id.split('/')
        
        // Accept a doc that applies to the project as a whole
        let isProject = parts.length === 1 &&
            (parts[0] === 'members'
            || parts[0] === 'project'
            || parts[0] === 'member'
            || parts[0] === 'projectPreferences'
            || parts[0] === 'teamPreferences'
            || parts[0] === 'publicationPreferences')
        if (isProject) {
            this.acceptProject(project, [], doc)
            return
        }
        
        // Accept a project message doc
        let isProjectMessage = parts.length === 2 && parts[0] === 'notify'
        if (isProjectMessage) {
            this.acceptProjectMessage(project, doc, seq)
            return
        }

        let pathInfo: PathInfo | undefined = undefined

        let builders = this.getBuilders(parts)

        let target: any = this.project

        // This an array of all the parent objects corresponding to the doc we have just received
        let env: any[] = [ project ]

        /**
         * For an object with _id X/Y/Z, look at X, X/Y, and X/Y/Z.
         * If any of these objects do not exist (in theory this should not happen), create them.
         * Push each object onto the env array so that we can access them later, e.g.
         * when we see a new passage, we need to know the portion it belongs to so that we can add it there.
         */
        for (let i: number = 0; i < parts.length; ++i) {
            parts[i] = parts[i].trim()
            if (parts[i] === '') throw Error(`Empty part in path [${doc._id}]`)
            
            let path = parts.slice(0, i+1).join('/')

            const itemsProperty = builders[i].attr
            const itemParent = target
            const upsertResponse = this.upsert(target, itemsProperty, path, builders[i].factory, doc.removed, i === parts.length-1)
            
            pathInfo = onGotPathInfo && createPathInfo(upsertResponse, path, itemParent, itemsProperty, doc, seq)             
            target = upsertResponse.item
            if (!target) { /* null target means doc.removed */
                onGotPathInfo && this.onGotPathInfoDocRemoved(doc, pathInfo!, onGotPathInfo) // publish debug info
                return // item was removed, nothing else to do
            }

            env.push(target)
        }

        // Once we have found/created all the parent objects
        // set the attributes of the object corresponding to the doc we received
        let acceptor = builders[parts.length - 1].acceptor.bind(this)
        acceptor(target, env, doc)

        onGotPathInfo && pathInfo && onGotPathInfo(pathInfo) // publish debug info
    }

    // A model object was removed. Publish debug info about this removal.
    onGotPathInfoDocRemoved(doc: any, pathInfo: PathInfo, onGotPathInfo: (trackInfo: PathInfo) => void) {
        // apply removed and modBy to final item state
        if (pathInfo.item) {
            pathInfo.item.removed = true
            if (doc.modBy)
                pathInfo.item.modBy = doc.modBy
        } else {
            // hasn't been added to parent yet, so can't update item
        }

        onGotPathInfo(pathInfo)
    }

    logDoc(doc: any, label?: string) {
        // if (doc._id.includes('tsk_') || doc._id.includes('stg_')) {
        //     log("!!!", s(doc))
        // }

        // Prevent a passage from being removed so that you can copyPassage it.
        // if (doc._id.startsWith('220218_101925/220816_092810') && doc.removed) {
        //     log('!!!', s(doc))
        //     return
        // }

        // log doc contents if doc._id matches acceptorRegex
        if (acceptorRegex && doc._id.match(acceptorRegex)) {
            log('!!!acceptorRegex', s(doc))
        }

        if (acceptorModDate && doc.modDate >= acceptorModDate) {
            if (!acceptorModDateEndDate || doc.modDate <= acceptorModDateEndDate) {
                log('!!!acceptorModDate', s(doc))
            }
        }

        if (logDocs) {
            let _logDocs: string[] = JSON.parse(logDocs) || []
            if (_logDocs.find(_logDoc => matchLogDoc(doc._id, _logDoc))) {
                log('logDoc', s(doc))
            }
        }

        dbg(`accept`, doc)

        if (label) {
            log(label, JSON.stringify(doc, null, 4))
        }

        //if (doc._id.startsWith("200718_152925/201030_171904")) {
        //    log('accept doc', JSON.stringify(doc, null, 4))
        //}

        //if (doc._id.startsWith("200718_152925/201030_171904")) {
        //    log('accept doc', JSON.stringify(doc, null, 4))
        //}
    }

    // Generate list of factories, acceptors, and attributes to get to this object
    // Also valid to get to any object along the path
    private getBuilders(parts: string[]) {
        let builders: Builder[] = []
        if (parts.length === 0) {
            return builders
        }
        
        let part = parts[0]
        if (part.startsWith('prjImg_')) {
            builders.push(new Builder(ProjectImage, this.acceptProjectImage.bind(this), 'images'))
        } else if (part.startsWith('plan_')) {
            builders.push(new Builder(ProjectPlan, this.acceptProjectPlan.bind(this), 'plans'))
        } else if (part.startsWith('term_')) {
            builders.push(new Builder(ProjectTerm, this.acceptProjectTerm.bind(this), 'terms'))
        } else {
            builders.push(new Builder(Portion, this.acceptPortion.bind(this), 'portions'))
        }

        if (parts.length === 1) {
            return builders
        }

        part = parts[1]
        if (part.startsWith('stg_')) {
            builders.push(new Builder(ProjectStage, this.acceptProjectPlanStage.bind(this), 'stages'))
        } else {
            builders.push(new Builder(Passage, this.acceptPassage.bind(this), 'passages'))
        }

        if (parts.length === 2) {
            return builders
        }

        part = parts[2]
        if (part.startsWith('tsk_')) {
            builders.push(new Builder(ProjectTask, this.acceptProjectPlanTask.bind(this), 'tasks'))
        } else if (part.startsWith('pasDoc_')) {
            builders.push(new Builder(PassageDocument, this.acceptPassageDocument.bind(this), 'documents'))
        } else if (part.startsWith('thumbVid_')) {
            builders.push(new Builder(PassageThumbnailVideo, this.acceptPassageThumbnailVideo.bind(this), 'thumbnailVideos'))
        } else {
            builders.push(new Builder(PassageVideo, this.acceptPassageVideo.bind(this), 'videos'))
        }

        if (parts.length === 3) {
            return builders
        }

        part = parts[3]
        if (part.startsWith('seg_')) {
            builders.push(new Builder(PassageSegment, this.acceptPassageSegment.bind(this), 'segments'))
        } else if (part.startsWith('gls_')) {
            builders.push(new Builder(PassageGloss, this.acceptPassageGloss.bind(this), 'glosses'))
        } else if (part.startsWith('hgh_')) {
            builders.push(new Builder(PassageHighlight, this.acceptPassageHighlight.bind(this), 'highlights'))
        } else if (part.startsWith('ref_')) {
            builders.push(new Builder(PassageVideoReference, this.acceptPassageVideoReference.bind(this), 'references'))
        } else {
            builders.push(new Builder(PassageNote, this.acceptPassageNote.bind(this), 'notes'))
        }

        if (parts.length === 4) {
            return builders
        }

        builders.push(new Builder(PassageNoteItem, this.acceptPassageNoteItem.bind(this), 'items'))
        return builders
    }

    // Find or create an object with the given path.
    // If we are processing a removed object notification we do not create anything.
    // However we may still need to find higher level parents in order to locate the
    // item that is being deleted.
    upsert(target: any, attr: string, path: string, 
            targetClass: 
                typeof ProjectImage | typeof Portion | typeof PassageDocument | typeof ProjectStage
                | typeof ProjectTask | typeof ProjectPlan | typeof Passage | typeof PassageVideo | typeof PassageSegment
                | typeof PassageGloss | typeof PassageHighlight | typeof PassageVideoReference
                | typeof PassageNote | typeof PassageNoteItem, 
            removed: boolean,
            lastPart: boolean): UpsertResponse {

        // Paths may have a tag (@viewedby, @task) at the end.
        // Remove it when looking for a matching object.
        path = removePathTag(path)

        // Get the items that already exist on the parent correspond to the target
        let items = target[attr]
        if (items === undefined) {
            throw Error('Could not find attributes')
        }

        let idx = _.findIndex(items, { _id: path })
        if (idx >= 0) {
            if (lastPart) {
                if (removed) {
                    let deletedFromParent: DBObject | undefined
                    this.removedIds.add(path)

                    if (targetClass === PassageVideo) {
                        items[idx].removed = true
                    } else {
                        // NOTE: the following code was probably for older code
                        // when duration was a number rather than a function, 
                        // so just test typeof duration before bumping duration 
                        // If we are removing a gloss and it is not the first gloss,
                        // increase the duration of the previous gloss to take up the
                        // space that the removed gloss had
                        if (targetClass === PassageGloss && idx > 0 && typeof items[idx].duration === 'number') {
                            items[idx-1].duration += items[idx].duration
                        }

                        const deletedItems = items.splice(idx, 1)
                        deletedFromParent = deletedItems[0]
                    }
                    
                    target._rev += 1    // force rerender
                    return { item: null, items, idx, path, deletedFromParent  }
                }
                
                // check for undeleteVideo function
                if (removed === false) {
                    if (targetClass === PassageVideo) {
                        this.removedIds.delete(path)
                        items[idx].removed = false
                    }
                    target._rev += 1    // force rerender
                }
            }

            return { item: items[idx], items, idx, path }
        }

        // If we received a note that an item has been removed
        // but we have not yet created part of the path that contains it,
        // do not create a parent item, just return null
        if (removed) {
            this.removedIds.add(path)
            return { item: null, items, idx: -1, path }
        }

        let item = new targetClass(path, this.project.db)

        /**
         * Normally we insert new items at the end of the list.
         * For project plans however there is normally only one plan and it is the zero'th item.
         * If a new plan is created however we want that plan to replace a previously created plan.
         * Normally the only reason to create a new plan would be as some kind of bug fix
         * that required remigrating the plan.
         */
        let newIndex = items.length
        if (targetClass === ProjectPlan) {
            items.unshift(item)
            newIndex = 0
        } else {
            items.push(item)
        }
        return { item, items, idx: newIndex, path }
    }

    acceptProject(project: Project, env: any[], doc: any) {
        if (doc.copyrightStatement !== undefined && project.copyrightStatement !== doc.copyrightStatement) {
            project.copyrightStatement = doc.copyrightStatement
        }

        this.acceptMembers(doc)
        this.acceptOldProjectPlan(project, [], doc)
        this.acceptBookName(project, doc)
        this.acceptMember(project, [], doc)
        this.acceptProjectPreferences(project, [], doc)
        this.acceptPublicationPreferences(project, doc)
        this.acceptTeamPreferences(project, [], doc)
    }

    acceptProjectPreferences(project: Project, env: any[], doc: any) {
        accept(project, doc, 'displayName')
        accept(project, doc, 'description')
        accept(project, doc, 'region')
        accept(project, doc, 'projectType')
    }

    acceptPublicationPreferences(project: Project, doc: any) {
        accept(project, doc, 'inputLanguagePrimary')
        accept(project, doc, 'copyrightNotice')
        accept(project, doc, 'license')
        accept(project, doc, 'organizationLogoUrl')
    }

    acceptTeamPreferences(project: Project, env: any[], doc: any) {
        if (doc.noteColors !== undefined && JSON.stringify(project.noteColors) !== JSON.stringify(doc.noteColors)) {
            project.noteColors = doc.noteColors
        }

        accept(project, doc, 'dateFormat')
        accept(project, doc, 'videoTimeCodeFormat')
        if (doc.compressedVideoCRF !== undefined && project.compressedVideoQuality !== doc.compressedVideoCRF) {
            project.compressedVideoQuality = doc.compressedVideoCRF
        }
        accept(project, doc, 'compressedVideoResolution')
        accept(project, doc, 'maxVideoSizeMB')
        accept(project, doc, 'emojis')
    }

    acceptProjectPlan(project: Project, env: any[], doc: any) {
        // No fields to update at this point
        this.hasAcceptedNewPlan = true
        log('acceptProjectPlan', env.slice(-1)[0]._id)
    }

    acceptProjectPlanStage(stage: ProjectStage, env: any[], doc: any) {
        this.acceptCreationInfo(stage, doc)

        this.hasAcceptedNewPlan = true
        if (doc.name !== undefined && stage.name !== doc.name) {
            stage.name = doc.name
        }

        if (doc.rank !== undefined && stage.rank !== doc.rank) {
            stage.rank = doc.rank
        }

        log(`acceptProjectPlanStage ${doc.name}/${doc.rank}`)

        let projectPlan: ProjectPlan = env[1]
        projectPlan.stages = projectPlan.stages.slice().sort(this.sortByRank)
        projectPlan.updateIndices()
    }

    acceptProjectPlanTask(task: ProjectTask, env: any[], doc: any) {
        this.acceptCreationInfo(task, doc)
        
        this.hasAcceptedNewPlan = true
        let { name, details, rank, id} = doc
        let stageName = env[2].name
        log('acceptProjectPlanTask', fmt({ stageName, name, details, rank, id }))

        if (doc.name !== undefined && task.name !== doc.name) {
            task.name = doc.name
        }

        if (doc.details !== undefined && task.details !== doc.details) {
            task.details = doc.details
        }

        if (doc.difficulty !== undefined && task.difficulty !== doc.difficulty) {
            task.difficulty = doc.difficulty
        }

        if (doc.rank !== undefined && task.rank !== doc.rank) {
            task.rank = doc.rank
        }

        if (doc.id !== undefined && task.id !== doc.id) {
            task.id = doc.id
        }

        let projectStage: ProjectStage = env[2]
        projectStage.tasks = projectStage.tasks.slice().sort(this.sortByRank)
        projectStage.updateIndices()
    }

    acceptProjectImage(projectImage: ProjectImage, env: any[], doc: any) {
        this.acceptCreationInfo(projectImage, doc)

        if (doc.src !== undefined && projectImage.src !== doc.src) {
            projectImage.src = doc.src
        }

        if (doc.rank !== undefined && projectImage.rank !== doc.rank) {
            projectImage.rank = doc.rank
        }

        env[0].images = env[0].images.slice().sort(this.sortByRank)
    }

    acceptProjectTerm(projectTerm: ProjectTerm, env: any[], doc: any) {
        accept(projectTerm, doc, 'lexicalLink')
        accept(projectTerm, doc, 'isKeyTerm')
        accept(projectTerm, doc, 'glosses')

        let project = env[0] as Project
        project.acceptProjectTerm(projectTerm)
    }

    acceptBookName(project: Project, doc: any) {
        // bbbccc is realy only bbb (no chapter number present)
        let { bbbccc, projectBookName } = doc
        if (bbbccc === undefined
            || projectBookName === undefined
            || !Object.keys(project.bookNames).includes(bbbccc)
            || project.bookNames[bbbccc] === projectBookName
        ) {
            return
        }

        project.bookNames[bbbccc] = projectBookName
    }

    // Accept changes for email/role of all user.
    // Thes changes ARE persisted by the backend in the project table.
    acceptMembers(doc: any) {
        let { project } = this

        if (doc.members === undefined) return

        // Delete any project members that are not found in the doc
        for (let i = project.members.length - 1; i >= 0; --i) {
            let member = project.members[i]
            if (!findMember(doc.members, member.email)) {
                project.members.splice(i, 1)
            }
        }

        // Add any doc project members that are not found in the project and set their role attribute from the doc
        for (const docMember of doc.members) {
            let member = findMember(project.members, docMember.email)
            if (!member) {
                member = new Member(docMember.email, docMember.role || 'observer')
                project.members.push(member)
            }

            if (docMember.role !== undefined && member.role !== docMember.role) {
                member.role = docMember.role
            }
        }
    }

    // Accept changes for a single member.
    // These changes are NOT persisted by the backend in the project table.
    acceptMember(project: Project, env: any[], doc: any) {
        if (doc._id !== 'member') return

        let email = normalizeUsername(doc.email ?? '')
        if (doc._removed) {
            let index = findMemberIndex(project.members, email)
            if (index >= 0) {
                project.members.splice(index, 1)
            }

            return
        }

        let member = findMember(project.members, email)
        if (!member) {
            if (doc._added) {
                if (!email) {
                    log('### member to be added has no email address')
                    return
                }

                project.members.push(new Member(email, doc.role || 'observer'))
            }
            
            return
        }

        const newEmail = normalizeUsername(doc.newEmail ?? '')
        if (newEmail) {
            member.email = newEmail
            email = normalizeUsername(email)

            const previousEmails = member.previousEmails
            if (!previousEmails.includes(email)) {
                previousEmails.unshift(email)
            }
        }

        accept(member, doc, 'imageUrl')
        accept(member, doc, 'notifications')
        accept(member, doc, 'name')
    }

    // Convert old-style project plan to the new style. We manually create
    // DBObjects here. Why don't we handle this in the upsert function? The old-style
    // docs that project plans were a part of do not have an id that gives us hints on
    // how to create the required DBObjects.
    acceptOldProjectPlan(project: Project, env: any[], doc: any) {
        // log('acceptOldProjectPlan', doc.modDate?.slice(0, 10))

        let _window = window as any
        let { plan } = doc
        
        if (!plan) { return }

        project.plans?.length && log('###warning OLD PLAN CHANGE')

        project.oldPlanDoc = doc

        _window._cvt = () => {
            convertOldProjectPlan().catch(console.log)
        }
        _window._cvts = () => {
            convertOldProjectPlans().catch(console.log)
        }


        // let key = `defaults.${project.name}.oldStyleProjectPlan`
        // let latest = localStorage.getItem(key)

        // // Keep track of old project plans. We want to tell users if someone updated
        // // an old-style project plan, since we ignore any changes to old-style plans
        // // after changes have been made to a new-style plan.
        // if (latest === null) {
        //     localStorage.setItem(key, JSON.stringify(doc))
        // } else {
        //     let savedDoc = JSON.parse(latest)
        //     let savedDate = new Date(savedDoc.modDate).getTime()
        //     let newDate = new Date(doc.modDate).getTime()
        //     if (newDate > savedDate && latest !== JSON.stringify(plan)) {
        //         if (this.hasAcceptedNewPlan) {
        //             displayInfo(t`A change was made to this project's plan in SLTT 1.3.x
        //             or earlier. Ignoring. Please only edit project plans in SLTT 1.4.0
        //             and later.`)
        //         }
        //         localStorage.setItem(key, JSON.stringify(doc))
        //     }
        // }
    }

    acceptProjectMessage(project: Project, doc: any, seq: number | string) {
        // let { _id, removed, subject, globalMessage } = doc
        // log('acceptProjectMessage', fmt({ _id, removed, subject, globalMessage, seq }))

        if (doc.videoUrl && doc.videoUrl.startsWith('_test1/')) { 
            return // Doc produced by serverless test case. Ignore it.
        }

        let { messages } = project

        let _parent: ProjectMessage | undefined = undefined
        
        if (doc.parent ?? '') {
            _parent = messages.find(n => n._id === doc.parent)
            if (!_parent) {
                log(`### project message parent not found: ${doc.parent}`)
                return
            }

            messages = _parent.responses
        }

        if (doc.removed) {
            let i = messages.findIndex(n => n._id === doc._id)
            if (i >= 0) {
                messages.splice(i, 1)
            }

            return
        }

        let newMessage = false
        let message = messages.find(n => n._id === doc._id)
        if (!message) {
            newMessage = true
            message = new ProjectMessage(doc._id, this.project.db)
            this.acceptCreationInfo(message, doc)
        }

        if (doc.text !== undefined) { message.text = doc.text }
        if (doc.videoUrl !== undefined) { message.videoUrl = doc.videoUrl }
        if (doc.globalMessage !== undefined) { message.globalMessage = doc.globalMessage }
        if (doc.subject !== undefined) { message.subject = doc.subject }
        if (doc.parent !== undefined) { message.parent = doc.parent }
        if (doc.viewed !== undefined) { message.viewed = doc.viewed }
        
        // We track the seq number from the local db in order to make it easy to directly update
        // the record to set the 'viewed' attribute.
        if (message.seq !== undefined) { message.seq = seq }

        // This call triggers an uploaded if the video has been created locally
        // but not yet uploaded.
        if (doc.videoUrl) {
            this.videoCacheAcceptor(doc.videoUrl, doc.creationDate)
                .catch(systemError)
        }

        if (newMessage) {
            messages.push(message)
        }
    }

    acceptCreationInfo(dbobject: DBObject, doc: any) {
        checkAttributeInDbDoc(dbobject, 'creator', doc)
        let { creator } = doc
        if (creator !== undefined) {
            creator = normalizeUsername(creator)
            if (dbobject.creator !== creator) {
                dbobject.creator = creator
            }
        }
        accept(dbobject, doc, 'modBy')
        accept(dbobject, doc, 'creationDate')
    }

    acceptPortion(portion: Portion, env: any[], doc: any) {
        if (!this.allowInvalidIds && !doc._id.match(/^[0-9]{6}_[0-9]{6}$/)) {
            log('### Rejecting document with invalid portion _id.', doc)
            return
        }

        accept(portion, doc, 'name')
        accept(portion, doc, 'rank')
        accept(portion, doc, 'isGlossary')

        this.acceptCreationInfo(portion, doc)

        accept(portion, doc, 'firstPassageIsThumbnail')
        acceptAnyTitles(portion, doc, portion.titles)
        
        env[0].portions = env[0].portions.slice().sort(this.sortByRank)
        
        portion._rev += 1
    }

    acceptPassage(passage: Passage, env: any[], doc: any) {
        accept(passage, doc, 'difficulty')
        accept(passage, doc, 'name')
        accept(passage, doc, 'rank')

        this.acceptCreationInfo(passage, doc)

        accept(passage, doc, 'assignee')
        accept(passage, doc, 'contentType')
        accept(passage, doc, 'hashtags')
        accept(passage, doc, 'publishedUrl')

        checkAttributeInDbDoc(passage, 'references', doc)
        if (doc.references !== undefined) {
            let docReferences = JSON.parse(doc.references)
            if (JSON.stringify(passage.references) !== JSON.stringify(docReferences)) {
                passage.references = docReferences.map((ref: any) => new RefRange(ref.startRef, ref.endRef))
                passage.terms_rev = -1 // force recalc of termsByGloss
            }
        }

        acceptAnyTitles(passage, doc, passage.titles)

        env[1].passages = env[1].passages.slice().sort(this.sortByRank)

        passage._rev += 1
    }

    acceptPassageDocument(passageDocument: PassageDocument, env: any[], doc: any) {
        this.acceptCreationInfo(passageDocument, doc)
        accept(passageDocument, doc, 'title')
        accept(passageDocument, doc, 'text', () => {
            passageDocument.textHistory.push(passageDocument.text)
            
            passageDocument.creationHistory.push((doc.modBy || doc.creator) + '|' + (doc._xModDate || doc.modDate))
            // passageDocument.textHistory = passageDocument.textHistory.slice(-10)
            if (doc.text?.startsWith('s3:')) {
                // trigger any pending uploads
                const s3Url = doc.text.slice(3)
                // older s3Urls might not end with -{number}, so default to `-1`
                const s3UrlNormalized = s3Url.match(/-\d+$/) ? s3Url : s3Url + '-1'
                this.videoCacheAcceptor(s3UrlNormalized, doc.modDate /* download if recent */)
                    .catch(systemError)
            }
        })
        accept(passageDocument, doc, 'editable')
        accept(passageDocument, doc, 'rank')

        env[2].documents = env[2].documents.slice().sort(this.sortByRank)
    }

    acceptPassageThumbnailVideo(passageThumbnailVideo: PassageThumbnailVideo, env: any[], doc: any) {
        const project: Project = env[0]
        const passage: Passage = env[2]
        
        // log('#### Accepting passageThumbnailVideo document.', { doc })

        this.acceptCreationInfo(passageThumbnailVideo, doc)

        if (doc.url !== undefined && passageThumbnailVideo.url !== doc.url) {
            passageThumbnailVideo.url = doc.url
        }

        if (doc.srcVideoUrl !== undefined && passageThumbnailVideo.srcVideoUrl !== doc.srcVideoUrl) {
            passageThumbnailVideo.srcVideoUrl = doc.srcVideoUrl
        }

        if (doc.selectionStartTime !== undefined && passageThumbnailVideo.selectionStartTime !== doc.selectionStartTime) {
            passageThumbnailVideo.selectionStartTime = doc.selectionStartTime
        }

        if (doc.selectionEndTime !== undefined && passageThumbnailVideo.selectionEndTime !== doc.selectionEndTime) {
            passageThumbnailVideo.selectionEndTime = doc.selectionEndTime
        }

        if (doc.fileType !== undefined && passageThumbnailVideo.fileType !== doc.fileType) {
            passageThumbnailVideo.fileType = doc.fileType
        }

        if (doc.size !== undefined && passageThumbnailVideo.size !==  doc.size) {
            passageThumbnailVideo.size = doc.size
        }

        try {
            passageThumbnailVideo.validate(project.name, passage._id)    
        } catch (error) {
            log('### Rejecting invalid passageThumbnailVideo document.', fmt({ doc, error }))
            // systemError(error)
            env[2].thumbnailVideos = []
            return
        }

        // currently only accept the latest item
        env[2].thumbnailVideos = [passageThumbnailVideo]

        passage._rev += 1

        // Normally every passageThumbnailVideo should have a url.
        // If there is a url, notify the cache so that it (potentially) be downloaded.
        // This call triggers an upload if the video has been created locally
        // but not yet uploaded.
        if (passageThumbnailVideo.url) {
            this.videoCacheAcceptor(passageThumbnailVideo.url, passageThumbnailVideo.creationDate)
                .catch(systemError)
        }
    }

    acceptPassageVideo(passageVideo: PassageVideo, env: any[], doc: any) {
        let passage: Passage = env[2]

        // If this doc includes a viewedByUser field it is intended to
        // only update thie viewedBy attribute and no others
        if (doc.viewedByUser) {
            let viewedByUser = normalizeUsername(doc.viewedByUser)
            if (passageVideo.viewedBy.includes(viewedByUser)) return
            passageVideo.viewedBy.push(viewedByUser)
        }

        if (doc._id.endsWith('@uploaded')) {
            // passageVideo.uploaded = true
            // // Force any view looking at this passage or passageVideo to redraw
            // passageVideo._rev += 1
            // passage._rev += 1            
            
            return
        }

        if (doc.isPatch !== undefined && passageVideo.isPatch !== doc.isPatch) {
            passageVideo.isPatch = doc.isPatch
        }

        if (doc.version !== undefined && passageVideo.version !== doc.version) {
            passageVideo.version = doc.version
        }

        if (doc.duration !== undefined && passageVideo.duration !== doc.duration) {
            passageVideo.duration = doc.duration

            // This next line should not be necessary.
            // It may however prevent a major crash if we forget to compute the duration.
            passageVideo.computedDuration = doc.duration
        }

        if (doc.status !== undefined && passageVideo.status !== doc.status) {
            if (typeof doc.status !== 'string') doc.status = ''

            passageVideo.status = doc.status
            passageVideo.statusModDate = doc._xModDate || doc.modDate
        }

        if (doc.url !== undefined && passageVideo.url !== doc.url) {
            passageVideo.url = doc.url
        }

        accept(passageVideo, doc, 'label')

        this.acceptCreationInfo(passageVideo, doc)

        if (doc.rank !== undefined && passageVideo.rank !== doc.rank) {
            passageVideo.rank = doc.rank
        }

        if (doc.ffmpegParametersUsed !== undefined) {
            let ffmpegParametersUsed = JSON.parse(doc.ffmpegParametersUsed)
            let { inputOptions, audioFilters, videoFilters, complexFilter, complexFilterOutputMapping, outputOptions } = ffmpegParametersUsed
            if (inputOptions !== undefined && inputOptions.length !== undefined
                && audioFilters !== undefined && audioFilters.length !== undefined
                && videoFilters !== undefined && videoFilters.length !== undefined
                && complexFilter !== undefined && complexFilter.length !== undefined
                && complexFilterOutputMapping !== undefined && complexFilterOutputMapping.length !== undefined
                && outputOptions !== undefined && outputOptions.length !== undefined
            ) {
                let parameters = new FfmpegParameters()
                parameters.inputOptions = inputOptions
                parameters.audioFilters = audioFilters
                parameters.videoFilters = videoFilters
                parameters.complexFilter = complexFilter
                parameters.complexFilterOutputMapping = complexFilterOutputMapping
                parameters.outputOptions = outputOptions
                passageVideo.ffmpegParametersUsed = parameters
            }
        }

        if (doc.mimeType !== undefined && passageVideo.mimeType !== doc.mimeType) {
            passageVideo.mimeType = doc.mimeType
        }

        // If a segment has an end position of 0, assume it hasn't been set yet.
        // The reason we do this here and not when the segments are created is we
        // need access to the full list of segments in the video.
        passageVideo.segments.forEach(s => {
            if (s.endPosition === 0) {
                s.setDefaultEndPosition(passageVideo)
            }
        })

        env[2].videos = env[2].videos.slice().sort(this.sortByRank)

        // Validate sorting of items
        // if (env[2].videos.some((v:any, i:number, vs:any[]) => (i>0 && vs[i].rank < vs[i-1].rank))) {
        //     debugger
        // }

        // Force any view looking at this passage or passageVideo to redraw.
        // This should be done list to make sure all other updates have been completed.
        passageVideo._rev += 1
        passage._rev += 1

        // Normally every passageVideo should have a url.
        // There was a bug at one point that created some without.
        // If there is a url, notify the cache so that it (potentially) be downloaded.
        // This call triggers an upload if the video has been created locally
        // but not yet uploaded.
        if (passageVideo.url) {
            this.videoCacheAcceptor(passageVideo.url, passageVideo.creationDate)
                .catch(systemError)
        }
    }

    acceptPassageSegment(passageSegment: PassageSegment, env: any[], doc: any) {
        let passage: Passage = env[2]
        let passageVideo: PassageVideo = env[3]

        let position = passageSegment.position
        if (doc.position !== undefined) {
            // Segments with identical positions mess up rendering,
            // bump position up until it is unique
            position = doc.position
            passageSegment.position = position
            while (passageVideo.segments.filter(pvs => pvs.position === position).length > 1) {
                position = position + .001
                passageSegment.position = position
            }
        }
        
        // A default value for end position will be provided when all fields in the passage
        // video have been filled in, so we don't need to provide it here.
        if (doc.endPosition !== undefined) {
            passageSegment.endPosition = doc.endPosition
        }

        if (doc.videoPatchHistory !== undefined && JSON.stringify(passageSegment.videoPatchHistory) !== JSON.stringify(doc.videoPatchHistory)) {
            passageSegment.videoPatchHistory = doc.videoPatchHistory
        }

        if (doc.approved !== undefined && passageSegment.approved !== doc.approved) {
            if (doc.approved === true) {    // Handle docs created before multi-state approval
                passageSegment.approved = PassageSegmentApproval.State1
            } else if (doc.approved === false) {
                passageSegment.approved = PassageSegmentApproval.State0
            } else {
                passageSegment.approved = doc.approved
            }
        }

        if (doc.approvalDate !== undefined && passageSegment.approvalDate !== doc.approvalDate) {
            passageSegment.approvalDate = doc.approvalDate
        }

        if (doc.approvedBy !== undefined && passageSegment.approvedBy !== doc.approvedBy) {
            passageSegment.approvedBy = doc.approvedBy
        }

        if (doc.references !== undefined) {
            // Convert old-style string references to RefRange[]
            let refRanges: RefRange[] = []
            if (doc.references === '' || doc.references[0] !== '[') {
                refRanges = RefRange.parseReferences(doc.references, 'en', getBookNames('en'))
            } else {
                let unserializedReferences = JSON.parse(doc.references)
                if (JSON.stringify(passageSegment.references) !== unserializedReferences) {
                    refRanges = unserializedReferences.map((ref: any) => new RefRange(ref.startRef, ref.endRef))
                }
            }
            passageSegment.references = refRanges
        }

        if (doc.labels !== undefined) {
            passageSegment.labels = doc.labels.map((lb: any) => new PassageSegmentLabel(lb.x, lb.y, lb.xText, lb.yText, lb.text))
        }

        if (doc.cc !== undefined && passageSegment.cc !== doc.cc) {
            passageSegment.cc = doc.cc
        }

        this.acceptCreationInfo(passageSegment, doc)

        if (doc.glosses !== undefined) {
            passageSegment.glosses = doc.glosses.map((psg: any) => new PassageSegmentGloss(psg.identity, psg.gloss))
        }

        if (doc.ignoreWhenPlayingVideo !== undefined) {
            passageSegment.ignoreWhenPlayingVideo = doc.ignoreWhenPlayingVideo
        }

        if (doc.sketchPaths !== undefined) {
            passageSegment.sketchPaths = JSON.parse(doc.sketchPaths)
        }

        passageSegment.rank = DBObject.numberToRank(position)
        
        passageVideo.segments = passageVideo.segments.slice().sort(this.sortByRank)

        // There is a bug we have not found yet.
        // It appears that sometimes when we create a new segment, the endPosition of the previous
        // segment is not updated. This causes the segments to overlap and the video to appear
        // to be duplicated. This code is a temporary fix for that bug.
        // When the endPosition of a segment is greater than the start position of the next segment,
        // adjust the endPosition of the first segment to be the start position of the next segment.
        if (this.applyEndPositionFix) {
            for (let i = 1; i < passageVideo.segments.length; ++i) {
                const seg1 = passageVideo.segments[i - 1]
                const seg2 = passageVideo.segments[i]
                if (seg1.endPosition > seg2.position) {
                    seg1.endPosition = seg2.position
                }
            }
        }

        passageSegment._rev += 1
        this.updateBaseVideoRev(passage, passageVideo)
        passage._rev += 1   // cause passage views to re-render
    }

    acceptPassageGloss(passageGloss: PassageGloss, env: any[], doc: any) {
        let passage: Passage = env[2]
        let passageVideo: PassageVideo = env[3]

        // glosses with identical positions mess up rendering,
        // bump position up until it is unique
        
        let position = doc.position
        if (position === undefined) {
            // glosses without positions crash our display process
            // If a bug creates one, ignore it
            log('###gloss without position ignored', s(doc))
            return
        }
        passageGloss.position = position

        while (passageVideo.glosses.filter(pvs => pvs.position === position).length > 1) {
            position = position + .1
            passageGloss.position = position
        }

        passageGloss.text = doc.text
        
        this.acceptCreationInfo(passageGloss, doc)

        passageGloss.rank = DBObject.numberToRank(position)
        
        passageVideo.glosses = passageVideo.glosses.slice().sort(this.sortByRank)

        let i = passageVideo.glosses.findIndex(pg => pg.position === passageGloss.position)
        if (i < 0) {
            systemError('Cant find PassageGloss we just inserted')
            return
        }

        passageGloss._rev += 1
        this.updateBaseVideoRev(passage, passageVideo)
        passage._rev += 1   // cause passage views to re-render
    }

    acceptPassageVideoReference(passageVideoReference: PassageVideoReference, env: any[], doc: any) {
        let passage: Passage = env[2]
        let passageVideo: PassageVideo = env[3]

        this.acceptCreationInfo(passageVideoReference, doc)

        if (doc.position !== undefined && passageVideoReference.position !== doc.position) {
            passageVideoReference.position = doc.position
        }

        if (doc.references !== undefined && JSON.stringify(passageVideoReference.references) !== doc.references) {
            let unserializedReferences = JSON.parse(doc.references)
            let refRanges = unserializedReferences.map((ref: any) => new RefRange(ref.startRef, ref.endRef))
            passageVideoReference.references = refRanges
        }
        
        if (doc.rank !== undefined && passageVideoReference.rank !== doc.rank) {
            passageVideoReference.rank = doc.rank
        }

        passageVideo.references = passageVideo.references.slice().sort(this.sortByRank)

        passageVideo._rev += 1
        passage._rev += 1
    }

    acceptPassageHighlight(passageHighlight: PassageHighlight, env: any[], doc: any) {
        //let passage: Passage = env[2]
        let passageVideo: PassageVideo = env[3]

        passageHighlight.color = doc.color
        passageHighlight.firstId = doc.firstId
        passageHighlight.lastId = doc.lastId
        passageHighlight.resourceName = doc.resourceName
        passageHighlight.rank = doc._id

        this.acceptCreationInfo(passageHighlight, doc)

        passageVideo.highlights = passageVideo.highlights.slice().sort(this.sortByRank)
    }

    acceptPassageNote(passageNote: PassageNote, env: any[], doc: any) {
        let passage: Passage = env[2]
        let passageVideo: PassageVideo = env[3]

        if (doc.type !== undefined && passageNote.type !== doc.type) {
            passageNote.type = doc.type
        }
        
        if (doc.description !== undefined && passageNote.description !== doc.description) {
            passageNote.description = doc.description
        }

        if (doc.canResolve !== undefined && passageNote.canResolve !== doc.canResolve) {
            passageNote.canResolve = doc.canResolve
        }
        
        if (doc.position !== undefined && passageNote.position !== doc.position) {
            passageNote.position = doc.position

            // If the endPosition is 0 we assume that the defult values for start/edn
            // have not been set yet and set them
            if (passageNote.endPosition === 0) {
                passageNote.setDefaultStartPosition(passageVideo.duration)
                passageNote.setDefaultEndPosition(passageVideo.duration)
            }
        }

        if (doc.startPosition !== undefined && passageNote.startPosition !== doc.startPosition) {
            passageNote.startPosition = doc.startPosition
        }

        if (doc.endPosition !== undefined && passageNote.endPosition !== doc.endPosition) {
            passageNote.endPosition = doc.endPosition
        }

        this.acceptCreationInfo(passageNote, doc)

        if (doc.rank !== undefined && passageNote.rank !== doc.rank) {
            passageNote.rank = doc.rank
        }
        
        passageVideo.notes = passageVideo.notes.slice().sort(this.sortByRank)

        passageNote._rev += 1
        this.updateBaseVideoRev(passage, passageVideo)
        passage._rev += 1   // cause passage views to re-render
    }

    // If this video is a patch update the rev of the patch video.
    // Otherwise update the rev of this video.
    updateBaseVideoRev(passage: Passage, passageVideo: PassageVideo) {
        // Don't complain about missing base videos here because
        // they seem to be caused by the base video being deleted
        let baseVideo = passageVideo.baseVideo(passage, true)

        if (baseVideo) {
            // This note occurs on a patch, reset times on the base video
            baseVideo.resetSegmentTimes()
            baseVideo._rev += 1
        } else {
            passageVideo.resetSegmentTimes()
            passageVideo._rev += 1
        }
    }

    acceptPassageNoteItem(passageNoteItem: PassageNoteItem, env: any[], doc: any) {
        let project: Project = env[0]
        let passage: Passage = env[2]
        let passageVideo: PassageVideo = env[3]
        let passageNote: PassageNote = env[4]

        // If this doc includes a viewedByUser field it is intended to
        // only update thie viewedBy attribute and no others
        if (doc.viewedByUser) {
            let viewedByUser = normalizeUsername(doc.viewedByUser)
            if (passageNoteItem.viewedBy.includes(viewedByUser)) return
            passageNoteItem.viewedBy.push(viewedByUser)
        }

        if (doc._id.endsWith('@uploaded')) {
            // if (passageNoteItem.url) {
            //     this.videoCacheAcceptor(passageNoteItem.url, [])
            //         .catch(systemError)
            // }
            // return
        }

        if (doc.position !== undefined && passageNoteItem.position !== doc.position) {
            passageNote.position = doc.position
            passageNoteItem.position = doc.position

            // If the endPosition is 0 we assume that the defult values for start/edn
            // have not been set yet and set them
            if (passageNote.endPosition === 0) {
                passageNote.setDefaultStartPosition(passageVideo.duration)
                passageNote.setDefaultEndPosition(passageVideo.duration)
            }
        }

        if (doc.duration !== undefined && passageNoteItem.duration !== doc.duration) {
            passageNoteItem.duration = doc.duration
        }

        if (doc.url !== undefined) {
            let url = doc.url

            /* Sigh, there was a bad error for 2 months starting mid June 2020
            * when creating url fields for PassaageNoteItem.
            * the project name, which is the first thing in the url, was left empty.
            * When we see a url w/o a project name we add the project name.
            */
            if (url.startsWith('/')) {
                // Disable fix until we are sure that no one is still running software that creates the bad urls.
                // url = project.name + url
            }

            if (passageNoteItem.url !== url) {
                passageNoteItem.url = url
            }
        }

        if (doc.fileType !== undefined && passageNoteItem.fileType !== doc.fileType) {
            passageNoteItem.fileType = doc.fileType
        }

        if (doc.text !== undefined && passageNoteItem.text !== doc.text) {
            passageNoteItem.text = doc.text
        }

        if (doc.resolved !== undefined && passageNoteItem.resolved !== doc.resolved) {
            passageNoteItem.resolved = doc.resolved
        }

        if (doc.unresolved !== undefined && passageNoteItem.unresolved !== doc.unresolved) {
            passageNoteItem.unresolved = doc.unresolved
        }

        this.acceptCreationInfo(passageNoteItem, doc)

        if (doc.rank !== undefined && passageNoteItem.rank !== doc.rank) {
            passageNoteItem.rank = doc.rank
        }

        if (doc.consultantOnly !== undefined && passageNoteItem.consultantOnly !== doc.consultantOnly) {
            passageNoteItem.consultantOnly = doc.consultantOnly
        }
        
        passageNote.items = passageNote.items.slice().sort(this.sortByRank)
        
        passageNoteItem._rev += 1
        this.updateBaseVideoRev(passage, passageVideo)
        passageNote._rev += 1   // cause passageNote and passage views to render
        passage._rev += 1

        if (passageNoteItem.url) {
            // This call triggers an uploaded if the video has been created locally
            // but not yet uploaded.
            this.videoCacheAcceptor(passageNoteItem.url, passageNoteItem.creationDate)
                .catch(systemError)
        }
    }

    cleanUp() {
        this.cleanUpOrphanVideos()

        // The acceptor will automatically create a no name Portion to hold passages.
        // Sometimes this might happen due bugs generating bogus passages that are later removed.
        // If the portion has no name and no passages, remove it.
        this.project.portions = this.project.portions.filter(portion => portion.name || portion.passages.length)
    }

    // The acceptor will automatically create a PassageVideo to hold a segment or a note or a gloss.
    // If some bug causes us to never get a url for that PassageVideo, remove the video.
    cleanUpOrphanVideos() {
        for (let portion of this.project.portions) {
            for (let passage of portion.passages) {
                if (passage.videos.some(video => !video.url)) {
                    passage.videos = passage.videos.filter(video => video.url)
                }
            }
        }
    }

    sortByRank = (a: any, b: any) => a.rank < b.rank ? -1 : (a.rank === b.rank ? 0 : 1)
}

type UpsertResponse = { item: any; items: any; idx: number; path: string; deletedFromParent?: DBObject }

/**
 * Create debugging info for queryLocalPathInfo function
 */
function createPathInfo(
    upsertResponse: UpsertResponse, path: string, itemParent: any, itemsProperty: string, doc: any, seq: number | string
) {
    const { deletedFromParent, items, idx } = upsertResponse
    const item = deletedFromParent ? deletedFromParent : items[idx]
    const pathInfo = {
        accepted: !!upsertResponse.item,
        itemPath: path,
        itemPathNoTag: removePathTag(path),
        item,
        deletedFromParent: deletedFromParent !== undefined,
        itemParent,
        itemsProperty,
        items,
        itemIndex: idx,
        doc,
        docRemoved: doc.removed,
        docSeq: seq
    }
    return pathInfo
}

// Paths may have a tag (@viewedby, @task) at the end.
// Remove it when looking for a matching object.
function removePathTag(path: string) {
    return splitPathTag(path)[0]
}

function splitPathTag(path: string) {
    const [basicPath, tag] = path.split('@')
    return [basicPath, tag]
}

function acceptAnyTitles(titlesObj: DBObject, doc: any, titles: MultilingualString[]) {
    checkAttributeInDbDoc(titlesObj, 'titles', doc)
    if (doc.titles !== undefined) {
        for (const multilingualTitle of doc.titles) {
            acceptTitle(titles, multilingualTitle as MultilingualString)
        }
    }
}

function acceptTitle(titles: MultilingualString[], multilingualstring: MultilingualString) {
    const { language, text } = multilingualstring

    const i = titles.findIndex(ms => ms.language === language)

    // Empty text means delete entry
    if (text.trim().length === 0) {
        if (i >= 0) {
            titles.splice(i, 1)
        }
        return
    }

    // Language not found, add it
    if (i < 0) {
        const ms = new MultilingualString(language, text)
        titles.push(ms)
        return
    }

    // If language already exists, update
    if (titles[i].text !== text) {
        titles[i].text = text
    }
}