// Display note selected by user OR create new note
/*
    NoteDialog
        NavigateAway
            Modal
                NoteHeader
                TextSearchParameters?
                NoteDropTarget
                    NoteMain
                        NoteTextEditor?
                        NoteVideoRecorder?
                        NoteMessage
                        NoteCitation
                        NoteDescription
                        NoteItem*
                            MemberDisplay
                            NoteItemBody
                                NoteTextItem?
                                NoteImageItem?
                                NoteVideoItem?
                        NoteAddItemButtons

                various buttons, i.e. Close

 */

import React, { Component, FunctionComponent, useEffect, useState, FC } from 'react'
import {observer} from 'mobx-react'
import { t } from 'ttag'
import Modal from 'react-bootstrap/lib/Modal'

import { Passage, PassageNote, PassageVideo, PassageSegment, Portion, Project, PassageNoteItem } from '../../models3/ProjectModels'
import { NoteMain } from './NoteMain'
import './Note.css'
import '../video/Video.css'
import { systemError, ErrorBoundary } from '../utils/Errors'
import { PreviousSegmentButton, NextSegmentButton, PaneCloseButton, LockButton, UnlockButton, SlttHelp, CircleNoteMarker, 
    SquareNoteMarker, TriangleNoteMarker, ColorSelectorNoteMarker, SearchButton, DeleteButton, enable } from '../utils/Buttons'
import NoteDropTarget from './NoteDropTarget'
import NavigateAway, { NavigateAwayChildrenProps } from './NavigateAway'
import { DropTargetViewLarge } from '../utils/DropTargetView'
import { IDateFormatter } from '../../models3/DateUtilities'
import { observable } from 'mobx'
import { Dropdown, MenuItem } from 'react-bootstrap'
import { fmt, s } from '../utils/Fmt'
import _ from 'underscore'
import { TextSearchParameters } from '../utils/TextSearchParameters'
import { noteTextSearch, noteTextSearchResult, setPortionPassageVideoById } from '../utils/TextSearch'
import { isReturnStatement } from 'typescript'
import { CheckedItem, GlossTextSearchParameters } from '../../models3/RootBase'
import { ViewableVideoCollection } from '../video/ViewableVideoCollection'
import { IRoot } from '../../models3/Root'
import { confirmAlert } from 'react-confirm-alert'
import { NoteSelector } from './NoteSelector'

const log = require('debug')('sltt:NoteDialog')

export const markerShapes: JSX.Element[] = [
    <CircleNoteMarker className='note-bar-marker' enabled={true} />,
    <SquareNoteMarker className='note-bar-marker' enabled={true} />,
    <TriangleNoteMarker className='note-bar-marker' enabled={true} />,
]

// future shapes: hexagon, octagon, star, heart, diamond


interface INoteMarker {
    noteSelector: NoteSelector,
    noteColors: string[],
}

export const NoteMarker: FC<INoteMarker> = ({ noteSelector, noteColors }) => {
    let { shapeIndex, colorIndex } = noteSelector

    if (shapeIndex == -1 && colorIndex == -1) {
        return (<span style={ {fontSize: '3.5em'}}>--</span>)
    }

    colorIndex = colorIndex >= noteColors.length ? 0 : colorIndex
    shapeIndex = shapeIndex >= markerShapes.length ? 0 : shapeIndex

    let shape = (shapeIndex === -1 
        ? (<ColorSelectorNoteMarker className='note-bar-marker' enabled={true} />)
        : markerShapes[shapeIndex])

    let color = (colorIndex === -1
        ? '#808080'
        : noteColors[colorIndex])
    
    return (
        <span style={{ color }}>
            {shape}
        </span>
    )
}

interface INoteMarkerDropdown {
    noteType: string,
    setNoteType: (noteType: string) => void,
    allowEditing: boolean,
    noteColors: string[],
    tooltip: string,
}

const NoteMarkerDropdown: FC<INoteMarkerDropdown> = (
    { noteType, setNoteType, allowEditing, noteColors, tooltip }) => {

    let noteSelector = NoteSelector.noteSelector(noteType)
        
    if (!allowEditing) {
        return (
            <span className='note-marker-placeholder'>
                <NoteMarker {...{ noteSelector, noteColors }} />
            </span>
            )
        }

    let id = 'notes.html#notecolor'
    const selectors = NoteSelector.validNoteSelectors(noteColors)

    return (
        <div className="note-marker-dropdown" data-tip data-for={id} data-place="right" >
            <SlttHelp id={id} tooltip={tooltip} place="right">
                <Dropdown id="note-marker-dropdown">
                    <Dropdown.Toggle className="note-marker-dropdown-item">
                        <NoteMarker {...{ noteSelector, noteColors }} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {selectors.map(selector => {
                            return (
                                <MenuItem
                                    key={selector.noteType}
                                    className='note-marker-dropdown-item'
                                    onClick={() => setNoteType(selector.noteType)}
                                >
                                    <NoteMarker {...{ noteSelector: selector, noteColors }} />
                                </MenuItem>
                            )
                        })}
                    </Dropdown.Menu>
                </Dropdown>
            </SlttHelp>
        </div>
    )
}

interface INoteHeaderRight {
    noteRoot: IRoot,
    closeDialog: () => Promise<void>,
    editing: boolean,
}

const NoteHeaderRight: FunctionComponent<INoteHeaderRight> = observer(({ noteRoot, closeDialog, editing }) => {
    const { iAmAdmin, note, username } = noteRoot
    const iCreatedThisNote = (note!.creator === username)
    const deleteable = (iCreatedThisNote || iAmAdmin)

    function deleteNoteWithConfirmation() {
        confirmAlert({
            title: /* translator: important */ t`Delete entire note?!`,
            confirmLabel: /* translator: important */ t`Delete note!`,
            cancelLabel: /* translator: important */ t`Keep note.`,
            message: '',
            onConfirm: async () => {
                log('remove note')
                await note?.remove()
                closeDialog()
            },
            onCancel: () => {
            },
        })
    }

    return (
        <div className='note-header-right'>
            { deleteable &&
                <DeleteButton
                    className='note-delete-button'
                    tooltip={/* translator: important */ t`Delete entire note.`}
                    enabled={true}
                    onClick={deleteNoteWithConfirmation} />
            }
            <PaneCloseButton
                enabled={!editing}
                onClick={closeDialog}
                tooltip={/* translator: important */ t`Close`}
                className='note-dialog-close-button' />
        </div>
    )
})


interface INoteHeader {
    noteRoot: IRoot,
    searchIsOpen: boolean,
    setSearchIsOpen: (searchIsOpen: boolean) => void,
    allowEditingNoteMarker: boolean,
    itemNumber: number,
    itemCount: number,
    goToPreviousItem: () => Promise<void>,
    goToNextItem: () => Promise<void>,
    closeDialog: () => Promise<void>,
    selectNoteType: (note: PassageNote, type: string) => void,
    editing: boolean,
}

let NoteHeader: FunctionComponent<INoteHeader> = observer((props) => {
    const [narrowWidth, setNarrowWidth] = useState(false)

    let { noteRoot, searchIsOpen, setSearchIsOpen, allowEditingNoteMarker, 
        itemNumber, itemCount, selectNoteType,
        goToPreviousItem, goToNextItem, closeDialog, editing } = props
    let { project, portion, passage, note } = noteRoot
    let { time } = note!

    const mediaQueryChangeEvent = (e: any) => {
        setNarrowWidth(e.matches)
    }

    useEffect(() => {
        let mediaQueryList = window.matchMedia('(max-width: 991px)')
        setNarrowWidth(mediaQueryList.matches)
        mediaQueryList.addEventListener('change', mediaQueryChangeEvent)
        return () => mediaQueryList.removeEventListener('change', mediaQueryChangeEvent)
    }, [])

    let portionName = portion?.name ?? ''
    let passageName = passage?.name ?? ''

    let { noteColors } = project

    let tooltip = /* translator: important */ t`Set shape and color of note marker.`
    let noteMarkerDropdown = (
        <NoteMarkerDropdown { ...{ 
            noteType: note!.type, 
            setNoteType: (noteType: string) => {selectNoteType(note!, noteType)},
            allowEditing: allowEditingNoteMarker, 
            noteColors,
            tooltip} } 
        />
    )

    let tooltipTab = t`Shortcut: <Tab>.`
    let tooltipShiftTab = t`Shortcut: <Shift><Tab>.`
    
    let noteSelectionArrows = (
        <>
            <div className={
                    'note-navigator' + (searchIsOpen ? ' note-navigator-search-is-open' : '')
                }>
                <PreviousSegmentButton
                    onClick={() => goToPreviousItem()}
                    tooltip={/* translator: important */ t`Go to previous note.` + ' ' + tooltipShiftTab}
                    enabled={itemNumber > 0} />
                {`${itemCount > 0 ? itemNumber+1 : 0} / ${itemCount}`}
                <NextSegmentButton
                    onClick={() => goToNextItem()}
                    tooltip={/* translator: important */ t`Go to next note.` + ' ' + tooltipTab}
                    enabled={itemNumber < itemCount-1} />
            </div>
        </>)  

    if (narrowWidth) {
        return (
            <span className='note-header'>
                <div className='note-header-left'>
                    {portionName} / {passageName}:
                    <div className='note-header-button-row'>
                        {noteMarkerDropdown}
                        {noteSelectionArrows}
                    </div>
                    <NoteHeaderRight {...{ noteRoot, closeDialog, editing }} />
                </div>
            </span>
        )
    }

    return (
        <div className='note-header'>
            <div className='note-header-left'>
                {noteMarkerDropdown}
                {portionName} / {passageName} ({time.toFixed(2)})
            </div>
            <div className='note-header-center'>
                {noteSelectionArrows}
                {!searchIsOpen && <SearchButton
                    enabled={true}
                    className="utils-search-button"
                    tooltip={/* translator: important */ t`Search for text in notes`}
                    onClick={() => setSearchIsOpen(true)} />}
            </div>
            <NoteHeaderRight {...{ noteRoot, closeDialog, editing }} />
        </div>
    )
})


interface INoteDialog {
    noteRoot: IRoot,
    adjustVideoTime: boolean,
    closeDialog: () => void,
}

class NoteDialog extends Component<INoteDialog>  {
    @observable editing = false

    @observable searchIsOpen = false
    @observable searchIndex = 0
    @observable note: PassageNote | undefined

    @observable searchText = ''
    @observable searchChecked: string[] = []
    @observable includeResolvedNotes = false
    @observable searchResults: string[] = []

    constructor(props: INoteDialog) {
        super(props)
        this.resolveNote = this.resolveNote.bind(this)
        this.unresolveNote = this.unresolveNote.bind(this)
        this.closeDialog = this.closeDialog.bind(this)
        this.userCanResolve = this.userCanResolve.bind(this)
        this.userCanUnresolve = this.userCanUnresolve.bind(this)
        this.canModifyResolveAccess = this.canModifyResolveAccess.bind(this)
        this.selectNoteType = this.selectNoteType.bind(this)
        this.goToPreviousItem = this.goToPreviousItem.bind(this)
        this.goToNextItem = this.goToNextItem.bind(this)
    }

    componentDidMount(): void {
        if (this.searchChecked.length === 0) {
            let { noteRoot } = this.props
            let { passage, passageVideo } = noteRoot
            if (passage) this.searchChecked.push(passage._id)
            if (passageVideo) this.searchChecked.push(passageVideo._id)
            
            // this.setSearchChecked([
            //     "210202_183235/220729_192753",
            //     "210202_183235/220729_192753/220831_153207",
            //     "210202_183235/220729_192753/220729_192927",
            //     "210202_183235/210702_144816"])
            // this.setSearchText('Hello')
        }
    }

    render() {
        let { editing, closeDialog } = this
        let { noteRoot } = this.props
        let { note, passage, passageVideo, currentTime, canViewConsultantOnlyFeatures, iAmInterpreter, noteSelector } = noteRoot
        if (!note) { return null } // should never happen
        if (!passage) { return null }

        let { searchIsOpen, setSearchIsOpen,
            searchIndex, searchResults, 
            searchText, setSearchText,
            searchChecked, setSearchChecked,
            includeResolvedNotes, setIncludeResolvedNotes } = this

        let video: PassageVideo | null = null
        if (note && passage && passageVideo) {
            video = note.toVideo(passage)
            
            // If no current video, assume the note has not been created but not added to the database yet
            // The video can be determined by looking at the current view time
            if (!video) {
                video = passageVideo.timeToVideo(passage, currentTime)
            }
        }

        let cns = "modal fade note-modal-dialog in show"

        let {notes, noteIndex} = this.getNotesForCurrentVideo()

        let { userCanResolve, userCanUnresolve, canModifyResolveAccess, selectNoteType } = this

        let message = <div>{t`Drop image or video file here to add to note.`}</div>
        let dropTargetView = <DropTargetViewLarge message={message} />

        let tooltip = /* translator: important */ t`Resolve note to show that the discussion is finished.`
        let id = 'notes.html#noteresolve'

        let itemNumber = noteIndex
        let itemCount = notes.length

        let searchResult = ''
        if (searchIsOpen) {
            itemNumber = searchIndex
            itemCount = searchResults.length
            if (searchResults.length > 0) {
                searchResult = searchResults[searchIndex]
            }
        }

        async function _closeDialog(confirm: () => Promise<boolean>) {
            let confirmed = await confirm()
            log('_closeDialog', fmt({confirmed}))
            if (!confirmed) return
            closeDialog()
        }

        // Dummy confirm function.
        // We used to ask people if they want to navigate away when text changes have been made.
        // Now we just automatically save them.
        async function confirm() { return true }

        return (
            <Modal keyboard={!editing} 
                    dialogClassName={cns} 
                    show={true} 
                    onKeyDown={event => this.onKeyDown(event, confirm)}
                    onHide={() => _closeDialog(confirm)} 
                    bsSize="large">
                <ErrorBoundary fallbackUI={(
                    <>
                        <Modal.Header>
                            <Modal.Title>
                                <NoteHeader {...{
                                    noteRoot, searchIsOpen, setSearchIsOpen,
                                    allowEditingNoteMarker: !note!.resolved && iAmInterpreter,
                                    itemNumber, itemCount,
                                    goToPreviousItem: () => this.goToPreviousItem(confirm), 
                                    goToNextItem: () => this.goToNextItem(confirm), 
                                    closeDialog: () => this.closeDialog(confirm),
                                    selectNoteType,
                                    editing,
                                }} />
                                {searchIsOpen && <TextSearchParameters {...{
                                    rt: noteRoot,
                                    searchIsOpen, setSearchIsOpen,
                                    searchText, setSearchText,
                                    searchChecked, setSearchChecked,
                                    includeResolvedNotes, setIncludeResolvedNotes,
                                }} />}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <h4>{t`Something went wrong...`}</h4>
                        </Modal.Body>
                    </>
                )}>
                    <Modal.Header>
                        {!editing && <Modal.Title>
                            <NoteHeader {...{ 
                                    noteRoot, searchIsOpen, setSearchIsOpen,
                                    allowEditingNoteMarker: !note!.resolved && iAmInterpreter,
                                    itemNumber, itemCount,
                                    goToPreviousItem: () => this.goToPreviousItem(confirm),
                                    goToNextItem: () => this.goToNextItem(confirm),
                                    closeDialog: () => this.closeDialog(confirm),
                                    selectNoteType,
                                    editing,
                                }} />
                            {searchIsOpen && <TextSearchParameters {...{
                                rt: noteRoot,
                                searchIsOpen, setSearchIsOpen, 
                                searchText, setSearchText,
                                searchChecked, setSearchChecked,
                                includeResolvedNotes, setIncludeResolvedNotes,
                            }} />}
                        </Modal.Title>}
                    </Modal.Header>
                    <Modal.Body>
                        <NoteDropTarget noteRoot={noteRoot} dropTargetView={dropTargetView}>
                            <div className="note-container">
                                <NoteMain {...{ noteRoot, editing, 
                                    setEditing: (_editing: boolean) => {
                                        this.editing = _editing
                                        // props.setUnsavedChanges(_editing)
                                    },
                                    searchResult, 
                                    searchText,
                                    closeNoteDialog: () => closeDialog(confirm) 
                                }} />
                            </div>
                        </NoteDropTarget>
                    </Modal.Body>
                    <Modal.Footer>
                        {!editing && <div className='note-dialog-footer'>
                            {!note!.resolved && userCanResolve() && (
                                <span className="pull-left">
                                    <SlttHelp id={id} tooltip={tooltip} place="right">
                                        <button
                                                type="button" 
                                                className={"resolve-note btn btn-primary"} 
                                                onClick={async () => {await this.resolveNote(note!, confirm)}} >
                                            {/* translator: important */ t`Resolve`}
                                        </button>
                                    </SlttHelp>
                                </span>
                            )}
                            {!note!.resolved && !userCanResolve() && (
                                <span className="pull-left">
                                    <SlttHelp id={id} tooltip={tooltip} place="right">
                                        <button
                                                type="button"
                                                disabled
                                                title={/* translator: important */ t`An admin or the creator of this note has locked the resolve button`}
                                                className={"resolve-note btn btn-primary"} >
                                            {/* translator: important */ t`Resolve`}
                                        </button>
                                    </SlttHelp>
                                </span>
                            )}
                            {note!.resolved && userCanUnresolve() &&
                                <button
                                    type="button"
                                    className="unresolve-note btn pull-left btn-primary"
                                    onClick={async () => { await this.unresolveNote(note!)}} >
                                    {/* translator: important */ t`Unresolve`}
                            </button>
                            }
                            {note!.canResolve && canModifyResolveAccess() && (
                                <UnlockButton
                                    enabled={true}
                                    onClick={() => this.modifyResolveAccess(note!, false)}
                                    className='lock-resolve-button pull-left'
                                    tooltip={t`Allow only admin or note creator to resolve`} />
                            )}
                            {!note!.canResolve && canModifyResolveAccess() && (
                                <LockButton
                                    enabled={true}
                                    onClick={() => this.modifyResolveAccess(note!, true)}
                                    className='lock-resolve-button pull-left'
                                    tooltip={t`Allow anyone to resolve`} />
                            )}
                            <button type="button" 
                                className={enable("close-note-dialog btn btn-default btn-primary", !editing)}
                                onClick={() => !editing && closeDialog(confirm)} >
                                    {t`Close`}
                            </button>
                        </div>}
                    </Modal.Footer>
                </ErrorBoundary>
            </Modal>
        )
    }

    setSearchText = (searchText: string) => {
        log('searchText', fmt({ searchText }))
        this.searchText = searchText
        this.updateSearchResults()
    }

    setSearchIsOpen = (searchIsOpen: boolean) => {
        log('searchIsOpen', fmt({ searchIsOpen }))
        this.searchIsOpen = searchIsOpen
        this.updateSearchResults()
    }

    setSearchChecked = (searchChecked: string[]) => {
        log('searchChecked', fmt({ searchChecked }))
        this.searchChecked = searchChecked
        this.updateSearchResults()
    }

    setIncludeResolvedNotes = (includeResolvedNotes: boolean) => {
        log('includeResolvedNotes', fmt({ includeResolvedNotes }))
        this.includeResolvedNotes = includeResolvedNotes
        this.updateSearchResults()
    }

    updateSearchResults = () => {
        let { noteRoot } = this.props
        let { searchText, searchChecked, includeResolvedNotes } = this

        let _ids: string[] = []
        if (searchText) {
            _ids = noteTextSearch(noteRoot, searchText, searchChecked, includeResolvedNotes)
        }

        this.searchResults = _ids
        this.searchIndex = -1

        log('updateSearchResults', fmt({_ids}))
    }

    onKeyDown(e: any, confirm: () => Promise<boolean>) {
        log(`onKeyDown ${[e.key, e.metaKey, e.altKey]}`)

        // Not very useful because Escape also closes the whole dialog
        // and I am afraid to mess with this behavior.
        // Info on how to do this here https://solidlystated.com/design/bootstrap-modal-disable-closing-with-esc-key-or-mouse/.
        // Not sure if it would be better to disallow closing dialog with Escape.
        if (e.key === 'Escape' && this.searchIsOpen) {
            e.stopPropagation()
            this.searchIsOpen = false
            return
        }
        
        if (e.key === 'Tab') {
            e.stopPropagation()
            if (e.shiftKey) {
                this.goToPreviousItem(confirm)
                return
            } else {
                this.goToNextItem(confirm)
                return
            }
        }
    }

    userCanResolve() {
        let { note } = this.props.noteRoot
        return this.canModifyResolveAccess() || note?.canResolve
    }

    userCanUnresolve() {
        return this.props.noteRoot.iAmInterpreter
    }

    canModifyResolveAccess() {
        let { iAmAdmin, username, note } = this.props.noteRoot
        return iAmAdmin || note?.creator === username
    }

    selectNote = async (note: PassageNote | undefined, setUserHasViewed: boolean) => {
        if (!note) return

        let { noteRoot, adjustVideoTime } = this.props
        let { username, setNote } = noteRoot

        if (this.note?._id === note._id) return
        log('selectNote', s(note.dbg()))

        if (setUserHasViewed) {
            await this.setUserHasViewed(note, username)
        }
        setNote(note)
    }

    getNotesForCurrentVideo = () => {
        let { noteRoot } = this.props
        let { passage, passageVideo, iAmConsultant, noteSelector, note } = noteRoot
        let notes: PassageNote[] = []
        let noteIndex = 0

        if (!passage || !passageVideo) return {notes, noteIndex}

        notes = passageVideo.getVisibleNotes(passage, iAmConsultant)

        // If the user is currently viewing an unresolved not, we only show unresolved notes.
        // If they clicked directly on a resolve note, show only resolved notes.
        // Too clever? Probably.
        let currentNoteResolution = note?.resolved ?? false
        notes = notes.filter(_note => currentNoteResolution === _note.resolved) || []

        if (noteSelector.active) {
            notes = notes.filter(_note => noteSelector.matches(_note.type) && _note.resolved === currentNoteResolution)
        }
        log('getNotesForCurrentVideo', fmt({ note: note?._id, resolved: note?.resolved, 
            notes: notes.map(_note => _note._id), currentNoteResolution, noteSelectorActive: noteSelector.active }))

        noteIndex = notes.findIndex(_note => _note._id === note?._id)
        if (noteIndex === -1) {
            log('### could not find noteIndex')
            noteIndex = 0
        }

        return { notes, noteIndex }
    }

    selectNoteById = async (_id: CheckedItem) => {
        let { noteRoot } = this.props
        let { passage, portion, passageVideo, project } = noteRoot
        log('selectNoteById', fmt({
            _id,
            portion: portion?._id,
            passage: passage?._id,
            passageVideo: passageVideo?._id,
        }))

        let { note: _note } = noteTextSearchResult(noteRoot, _id)
        !_note && log('### no note')
        if (!_note) return
        
        await setPortionPassageVideoById(noteRoot, _id)

        await this.selectNote(_note, false)
    }

    async goToPreviousItem(confirm: () => Promise<boolean>) {
        let canContinue = await confirm()
        if (!canContinue) return

        let { searchIsOpen, searchIndex, searchResults } = this

        let {notes, noteIndex} = this.getNotesForCurrentVideo()

        if (searchIsOpen) {
            // Go to previous item in searchItems
            if (searchIndex <= 0) return
            this.searchIndex = searchIndex - 1
            await this.selectNoteById(searchResults[this.searchIndex])
            return
        }
        
        // Not searching for text, go to previous note in current video
        if (noteIndex <= 0) return
        await this.selectNote(notes[noteIndex-1], true)
    }

    async goToNextItem(confirm: () => Promise<boolean>) {
        let canContinue = await confirm()
        if (!canContinue) return

        let {notes, noteIndex} = this.getNotesForCurrentVideo()

        let { searchIsOpen, searchIndex, searchResults } = this

        if (searchIsOpen) {
            // Go to previous item in searchItems
            if (searchIndex >= searchResults.length - 1) return
            this.searchIndex = searchIndex + 1
            await this.selectNoteById(searchResults[this.searchIndex])
            return
        }

        // Not searching for text, go to previous note in current video
        if (noteIndex >= notes.length  - 1) return
        await this.selectNote(notes[noteIndex+1], true)
    }

    async modifyResolveAccess(note: PassageNote, newValue: boolean) {
        if (!this.canModifyResolveAccess()) {
            return
        }

        let { createNoteIfNonexistent } = this.props.noteRoot
        try {
            await createNoteIfNonexistent()
            await note.setCanResolve(newValue)
        } catch(err) {
            systemError(err)
        }
    }

    async selectNoteType(note: PassageNote, type: string) {
        let { createNoteIfNonexistent } = this.props.noteRoot
        
        try {
            await createNoteIfNonexistent()
            await note.setType(type)
        } catch(err) {
            systemError(err)
        }
    }
    
    async resolveNote(note: PassageNote, confirm: () => Promise<boolean>) {
        let canContinue = await confirm()
        if (!canContinue) return

        let { passage, createNoteIfNonexistent } = this.props.noteRoot
        try {
            await createNoteIfNonexistent()
            await note.resolve(passage!)
            this.closeDialog()
        } catch(err) {
            systemError(err)
        }
    }

    async unresolveNote(note: PassageNote) {
        let { createNoteIfNonexistent, passage } = this.props.noteRoot
        try {
            await createNoteIfNonexistent()
            await note.unresolve(passage!)  
        } catch(err) {
            systemError(err)
        }
    }

    async closeDialog(confirm?: () => Promise<boolean>) {
        if (confirm) {
            let canContinue = await confirm()
            if (!canContinue) return
        }

        let { closeDialog, noteRoot } = this.props
        let { username, note } = this.props.noteRoot
        if (!note) return

        // If the user has added a description to a note but the note has no items
        // that could identify the notes original creator, then add the description
        // as a text item so that the original creator can be known.
        // Restrict this to notes that the user has created so that someone else
        // does not accidentally trigger this and make it look like they created the note.
        if (note.description && note.items.length === 0 && note.creator === username) {
            const item = note!.createItem()
            item.text = note.description
            await note.addItem(item, noteRoot.passage)
            // We do NOT create a notification (with prepNotification) because that was 
            // already done when the description was saved
        }

        await this.setUserHasViewed(note, username)
        closeDialog()
    }

    
    async setUserHasViewed(note: PassageNote, username: string) {
        let { items } = note

        // Treat text items or images as viewed as soon as the user has opened
        // the note. Videos must be played before we mark them as viewed.
        let viewedItems = items.filter(
            item => item.isTextItem() || item.isImageItem() || item.resolved || item.unresolved)
        for (let item of viewedItems) {
            await item.addViewedBy(username)
        }
    }
}

export default observer(NoteDialog)
