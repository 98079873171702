import { observer } from "mobx-react";
import React, { FC, useEffect, useState, useRef } from "react";
import { confirmAlert } from "react-confirm-alert";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { t } from 'ttag'
import _ from 'underscore'
import { Passage, PassageDocument, Project } from "../../models3/ProjectModels"
import { Root } from "../../models3/Root";
import { VideoCache } from "../../models3/VideoCache";
import { AddButton, GoBackButton, GoForwardButton, LockButton, PencilButton, PinButton, TrashButton, UnlockButton } from "../utils/Buttons";
import { sendBeacon, displayError } from "../utils/Errors"
import { fmt } from "../utils/Fmt"
import { EditableRichText } from "../utils/RichTextEditor";
import './Passage.css'
import { doOnceWhenBackOnline } from '../utils/ServiceStatus'
import { stringifyCreationHistoryForBeacon } from '../utils/PassageDocument'

const log = require('debug')('sltt:PassageDocuments')

function documentTitle(document: PassageDocument, index: number) {
    /**
     * If a document was created with 1.5.2 or earlier there was no 'title' attribute.
     * Previously we extracted the title from the first line of the document.
     * This was confusing for users and unreliable when they edited the document to include
     * something complicated in the first line.
     *
     * If the document was created under this system we default the title to the first line
     * until the user edits the document (making the title something other than undefined)
     */
    if (document.title === undefined && document.getTitle()) {
        return document.getTitle()
    }

    if (document.title) return document.title

    return `#${index + 1}`
}


type PassageDocumentsProps = {
    rt: Root,
    passage: Passage,
}


const PassageDocuments: FC<PassageDocumentsProps> = observer(({ rt, passage }) => {
    const [index, setIndex] = useState(0)
    const [newDocument, setNewDocument] = useState<PassageDocument|null>(null)

    let documents: PassageDocument[] = [...passage.documents]
    if (newDocument) documents.push(newDocument)

    function addDocument() {
        if (rt.editingPassageDocument) return // should not happen

        let document = passage.createPassageDocument()
        document.text = ''
        setNewDocument(document)
        setIndex(documents.length)
    }

    // If a newly created document is open, it is always the one we want to be selected.
    let _index = newDocument ? documents.length - 1 : index
    _index = Math.min(_index, documents.length-1)
    _index = Math.max(_index, 0)

    return (
        <div>
            <Tabs selectedIndex={_index}
                    onSelect={(_index) => !rt.editingPassageDocument && setIndex(_index)}>
                <TabList>
                    {documents.map((document, i) => (
                        <Tab key={document._id}>
                            {documentTitle(document, i)}
                        </Tab>
                    ))}
                    {rt.iAmInterpreter && (
                        <Tab key='add-document' disabled>
                            <AddButton
                                className='passage-document-add-button small-passage-document-button'
                                enabled={!rt.editingPassageDocument}
                                onClick={addDocument}
                                tooltip={/* translator: important */ t`Add passage document`}
                            />
                        </Tab>
                    )}
                </TabList>
                {documents.map(document => (
                    <TabPanel key={document._id}>
                        <PassageDocumentEditor
                            document={document}
                            passage={passage}
                            pinDocument={async () => {
                                await passage.pinDocument(document._id)
                                setIndex(0)
                            }}
                            closeEditor={() => {
                                log('closeEditor')
                                setNewDocument(null)
                            }}
                            rt={rt}
                        />
                    </TabPanel>
                ))}
                {rt.iAmInterpreter && (
                    <TabPanel key='add-document'>
                        <div className="passage-document__editing-area" />
                    </TabPanel>
                )}
            </Tabs>
        </div>
    )
})

type PassageDocumentHistorySelectorProps = {
    document: PassageDocument,
    passage: Passage,
    rt: Root,
    historyIndex: number,
    setHistoryIndex: (index: number) => void,
}

const PassageDocumentHistorySelector: FC<PassageDocumentHistorySelectorProps> = ({ document, passage, rt, historyIndex, setHistoryIndex }) => {
    let creations = [...document.creationHistory]
        .reverse()
        .map(creation => {
            let parts = creation.split('|')
            let pieces = parts[0].split('@')
            let pieces2 = rt.dateFormatter.format(new Date(parts[1])).split(':')
            return pieces[0] + ' ' + pieces2.slice(0, -1).join(':')
        })

    return (
        <select className='document-history-selector'
            value={historyIndex}
            onChange={e => setHistoryIndex(parseInt(e.target.value)) }>
            {creations.map((creation, index) => (<option key={`${index}-${creation}`} value={index.toString()}> {creation} </option>) )}
        </select>
    )
}

type PassageDocumentEditorProps = {
    document: PassageDocument,
    passage: Passage,
    closeEditor: () => void,
    pinDocument: () => void,
    rt: Root,
}

// If this is a long document retrieve its text from S3

const PassageDocumentEditor: FC<PassageDocumentEditorProps> = observer(
    ({ document, passage, closeEditor, pinDocument, rt }
) => {
    let { project, username, iAmAdmin, iAmInterpreter, memberRole, setEditingPassageDocument } = rt

    const editingNewDocument = !passage.documents.find(doc => doc._id === document._id)

    const [editing, setEditing] = useState(editingNewDocument)
    let [savedText, setSavedText] = useState(editingNewDocument ? '' : '...')

    let title = document.title === undefined ? document.getTitle() : document.title
    log('PassageDocumentEditor render', fmt({ title, documentTitle: document.title, getTitle: document.getTitle() }))

    // 0 means most recent version of document, 1 next most recent, ...
    const [historyIndex, setHistoryIndex] = useState(0)

    const titleRef = useRef<HTMLInputElement|null>(null)
    const indexInDocuments = passage.documents.indexOf(document)

    // On initial load, get most recent text for document
    useEffect(() => {
        async function _loadText() {
            // We don't want to load an updated text when editing because the user
            // would lose their edits.
            if (editing) return

            log('PassageDocumentEditor _loadText')
            // getText deals with the fact that large documents are stored in S3 since
            // they are too big to store in DynamoDB.
            setSavedText(await document.getText(0, VideoCache))

            // When a new document edit arrives, make it the one in view
            // by resetting history location.
            // The user needs to realize that there is new document.
            setHistoryIndex(0)
        }

        _loadText()
    }, [document.text])

    function deleteDocument() {
        const beaconId = 'delete passage document button'
        const timestampOpened = new Date().toISOString()
        const creationHistory = stringifyCreationHistoryForBeacon(document.creationHistory)
        const s3Url = document.getS3Url()
        const getPayload = (timestamp: string, action: 'opened'|'confirmed') => ({
            timestamp, action, username, memberRole,
            _title: document.title, pasDocId: document._id, s3Url, textLength: document.text.length,
            creationDate: document.creationDate, creationHistory,
        })
        doOnceWhenBackOnline(`send beacon for delete document button OPENED: ${document._id}`,
            () => sendBeacon(beaconId, getPayload(timestampOpened, 'opened'))
        )
        confirmAlert({
            title: /* translator: important */ t`Delete document?`,
            message: /* translator: important */ t`Are you sure you want to delete this document?`,
            confirmLabel: /* translator: important */ t`Delete`,
            cancelLabel: /* translator: important */ t`Cancel`,
            onConfirm: async () => {
                const timestampConfirmed = new Date().toISOString()
                doOnceWhenBackOnline(`send beacon for delete document button CONFIRMED: ${document._id}`,
                    () => sendBeacon(
                        beaconId, getPayload(timestampConfirmed, 'confirmed')
                    )
                )
                await passage.removePassageDocument(document._id)
                closeEditor()
            },
            onCancel: () => {},
        })
    }

    let canModifyEditingStatus = iAmAdmin || document.creator === username
    let editButtonsEnabled = document.editable || canModifyEditingStatus

    async function _setHistoryIndex(index: number) {
        setHistoryIndex(index)
        let text = await document.getText(index, VideoCache)
        setSavedText(text)
    }

    return (
        <>
            {iAmInterpreter && !editing && (
                <div className='passage-document-toolbar'>
                    <div className='passage-document-toolbar-first-group'>
                        <PencilButton
                            enabled={editButtonsEnabled}
                            onClick={() => setEditing(true)}
                            className='passage-document-button passage-document-edit-button'
                            tooltip={/* translator: important */ t`Edit`}
                        />
                    </div>
                    <div className='passage-document-toolbar-middle-group'>
                        {/* <GoBackButton
                            enabled={!editing && historyIndex < document.textHistory.length-1}
                            onClick={() => _setHistoryIndex(historyIndex+1).catch(displayError)}
                            className='passage-document-button'
                            tooltip={t`View earlier document.`}
                        /> */}
                        <PassageDocumentHistorySelector {...{ document, passage, rt, historyIndex, setHistoryIndex: _setHistoryIndex }} />
                        {/* <GoForwardButton
                            enabled={!editing && historyIndex > 0}
                            onClick={() => _setHistoryIndex(historyIndex - 1).catch(displayError)}
                            className='passage-document-button'
                            tooltip={t`View later document`}
                        /> */}
                    </div>
                    <div className='passage-document-toolbar-last-group'>
                        <TrashButton
                            onClick={deleteDocument}
                            enabled={editButtonsEnabled}
                            buttonClassName='button-right-align'
                            className='passage-document-button'
                            tooltip={/* translator: important */ t`Delete`}
                        />
                        {document.editable && canModifyEditingStatus && (
                            <UnlockButton
                                enabled={true}
                                onClick={() => document.setEditable(false)}
                                className='passage-document-button'
                                tooltip={t`Allow only admin or document creator to edit`}
                            />
                        )}
                        {!document.editable && canModifyEditingStatus && (
                            <LockButton
                                enabled={true}
                                onClick={() => document.setEditable(true)}
                                className='passage-document-button'
                                tooltip={t`Allow anyone to edit`}
                            />
                        )}
                        {iAmInterpreter && indexInDocuments > 0 && <PinButton
                            enabled={true}
                            onClick={pinDocument}
                            className='passage-document-button'
                            tooltip={t`Move document to first tab`}
                        />}
                    </div>
                </div>
            )}
            { editing &&
                <div className="passage-document-title-input">
                    <input type="text"
                           ref={titleRef}
                           defaultValue={title ?? ''}
                           placeholder={t`Title...`} />
                </div>
            }
            <EditableRichText
                savedText={savedText}
                save={(_text?: string, shouldBeacon = false) => {
                    async function _save() {
                        log('PassageDocumentEditor save', fmt({ title: titleRef.current?.value, text: _text?.slice(0, 50) }))

                        let title = trimmedTitle(titleRef)
                        log('setTitle', fmt({ title }))

                        // in case of refresh, try to save the text BEFORE title
                        if (_text !== undefined) {
                            await document.setText(_text, rt.project.name, title, shouldBeacon)
                            setSavedText(_text)
                        }

                        if (title !== '') {
                            await document.setTitle(title)
                        }
                        
                        setHistoryIndex(0)
                        setEditing(false)
                        closeEditor()
                    }
                    _save().catch(displayError)
                }}
                cancel={() => {
                    log(`PassageDocumentEditor cancel, editingNewDocument=${editingNewDocument}`)
                    setEditing(false)
                    closeEditor()
                }}
                editorOpen={editing}
                setEditorOpen={setEditing}
                prefix={'passage-document'}
            />
        </>
    )
})

export default PassageDocuments

function trimmedTitle(titleRef: React.MutableRefObject<HTMLInputElement | null>) {
    let title = titleRef.current?.value ?? ''
    title = title.trim()
    return title
}
