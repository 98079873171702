

// eslint-disable-next-line @typescript-eslint/no-var-requires
const log = require('debug')('sltt:LocationHistory') 

export function clearHistorySearchParameters(logContext: string, state = {}, title = document.title) {
    const targetHref = window.location.href.replace(/\?.*$/, '')
    log(`${logContext} - location - resetting history`, { from: window.location.href, to: targetHref })
    window.history.replaceState(state, title, targetHref)
}
