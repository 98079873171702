import { t } from 'ttag'
import _ from 'underscore'
import { delay } from 'q'
import { fetchBlob2 } from "../../models3/API2"
import { FfmpegParameters, Passage, PassageVideo, VideoSlice } from '../../models3/ProjectModels'
import { ViewableVideoCollection } from '../video/ViewableVideoCollection'
import { fmt, s } from './Fmt'
import { displayInfo } from './Errors'
import { getClientId, getHasElectronContext } from '../../models3/SlttAppStorage'
import { generate4DigitHex } from '../../models3/utils/hashUtils'

const log = require('debug')('sltt:VideoCompressor')

const COMPRESSION_SERVER_ORIGIN = 'http://localhost:29678'

let firstFileNameLog = true
const pageInstanceId = generate4DigitHex() // good until user refreshes the page

function fileNameLog(name: string) {
    let parts = name.split('/')

    if (firstFileNameLog) {
        firstFileNameLog = false
        log('temp directory', parts.slice(0,-1).join('/'))
    }

    return '...' + name.slice(name.endsWith('.mp4') ? -8 : -4)
}

function sizeInMb(_size: number) { return (_size/(1024*1024)).toFixed(1) + 'mb'}

class VideoCompressor {
    private ffmpegParameters = new FfmpegParameters()

    constructor(
        public crf: number,
        public resolution: number,
        public maxFileSizeMB: number,
        private setProgressMessage: (message: string) => void,
    ) {
    }

    setFfmpegParameters = (resolution: number, crf: number, fileName: string) => {
        const ffmpegParameters = new FfmpegParameters()
        const isAvi = fileName.toLowerCase().endsWith('.avi')

        // outputOptions()

        if (isAvi) {
            ffmpegParameters.outputOptions = [
                // ffmpeg parameters for AVI conversion from Caio
                `-crf ${crf}`,
                '-c:v libx264',
                '-preset medium',
                // The -preset option in ffmpeg is used to set the encoding speed to compression ratio.
                // '-c:a aac', // not using audio channel
                // '-strict experimental', // enables non-standard codecs, needed?
                // '-b:a 128k', // audio bitrate
                '-an', // disable audio
            ]
        } else {
            ffmpegParameters.outputOptions = [
                `-crf ${crf}`,
                '-pix_fmt yuv420p',
                '-r 30',
                '-c:v libx264',
                '-an', // disable audio
            ]
        }

        let width = 854
        let height = 480
        
        if (resolution > 480) {
            height = 720
            width = 1280
        }
        
        // https://ffmpeg.org/ffmpeg-filters.html#pad
        
        ffmpegParameters.videoFilters = [
            `scale='iw*min(${width}/iw,${height}/ih)':'ih*min(${width}/iw,${height}/ih)'`,
            `pad='${width}:${height}:(${width}-iw)/2':'(${height}-ih)/2'`,
        ]

        log(`setFfmpegParameters`, fmt({ffmpegParameters}))
        this.ffmpegParameters = ffmpegParameters
    }

    compressVideo = async (file: File) => {
        let uploadedFilePath = ''
        let compressedFilePath = ''

        try {
            this.setProgressMessage(t`Starting compression...`)

            this.checkFreeSpace(file.size) // throws if not enuf free space

            uploadedFilePath = await this.uploadFileToServer(file)

            this.setFfmpegParameters(this.resolution, this.crf, file.name)
            compressedFilePath = await this.compressVideoWithId(uploadedFilePath)

            log('waitForFileToGenerate', fileNameLog(compressedFilePath))
            await this.waitForFileToGenerate(compressedFilePath, 0, 1, this.setProgressMessage)

            let compressedFile = await this.downloadFile(compressedFilePath)
            log('compressedFile size', sizeInMb(compressedFile.size))
            
            return { compressedFile, ffmpegParameters: this.ffmpegParameters }
        } finally {
            await this.deleteFile(uploadedFilePath)
            await this.deleteFile(compressedFilePath)
        }
    }

    // In order to download a video we first concatenate all its parts.
    concatenateVideos = async (vvc: ViewableVideoCollection, passage: Passage,
            selectionStartTime: number, selectionEndTime: number) => {
        let uploadedSrcs = []
        let srcsToConcatenate: string[] = []
        let concatenatedFilePath = ''

        try {
            await vvc.waitUntilDownloaded()
            let video = vvc.viewableVideos[0].video
            let slices = video.createSlicesWithNoGaps(passage, selectionStartTime, selectionEndTime)

            let uploadedSrcsMap = new Map<string, string>()

            // Upload all the pieces of this video to the compression server
            for (let slice of slices) {
                slice.src = uploadedSrcsMap.get(slice.video._id) || ''
                
                if (!slice.src) {
                    let blob = await vvc.getBlob(slice.video)
                    slice.src = await this.uploadFileToServer(blob as File)
                    uploadedSrcsMap.set(slice.video._id, slice.src)
                }
                
                uploadedSrcs.push(slice.src)
            }

            srcsToConcatenate = await this.compressAndSlice(video, passage, slices, this.setProgressMessage)
            if (srcsToConcatenate.length === 0) { throw new Error('No files to concatenate') }

            this.setProgressMessage(t`Working...`)
            concatenatedFilePath = srcsToConcatenate[0]
            
            if (srcsToConcatenate.length > 1) {
                concatenatedFilePath = await this.concatenateWithIds(srcsToConcatenate)
                await this.waitForFileToGenerate(concatenatedFilePath, 0, 1, () => {})
            }

            return await this.downloadConcatenatedFile(concatenatedFilePath)
        } finally {
            log('concatenateVideos delete temporary files')
            const filesToDelete = _.uniq([...uploadedSrcs, ...srcsToConcatenate, concatenatedFilePath])
            for (let src of filesToDelete) {
                await this.deleteFile(src)
            }
        }
    }

    // Return a list of files which we will concatenate to form the final video.
    // All these files will be compressed with the same parameters so joining them will be fast.
    //
    // If base video has not been compressed, compress all videos using team preferences
    // If base video has been compressed, all videos should have same resolution as base video
    // Also need to compress videos that are being sliced
    private compressAndSlice = async (video: PassageVideo, passage: Passage, slices: VideoSlice[], setProgressMessage: (message: string) => void) => {
        let srcsToConcatenate = []

        try {
            let baseVideo = video.baseVideo(passage) || video

            // Setup desired resolution and crf for resulting files
            let resolution = baseVideo.isCompressed ? baseVideo.resolution : this.resolution
            let crf = baseVideo.isCompressed ? baseVideo.crf : this.crf

            for (let [i, slice] of slices.entries()) {
                log(`compressAndSlice slice=${i}`)
                let { position, endPosition, video, src } = slice

                if (position + .1 > endPosition) {
                    log(`compressAndSlice - skip tiny slice [${i}, ${position}..${endPosition}]`)
                    continue
                }

                let trimmed = position > 0 || endPosition < video.duration

                // In theory we could skip compression of some segments.
                // However, if something caused the video dimensions of one segment to be in any way
                // different from another sections, it looks to me like playback become very erratic
                // in some players. For now just compress everything.

                // If this video is not trimmed and already has the desired resolution, no further compression is needed
                // if (!trimmed && video.resolution === resolution && video.crf === crf) {
                //     srcsToConcatenate.push(src)
                //     log(`compressAndSlice - no compression needed [${i}, ${fileNameLog(src)}]`)
                //     debugger
                //     continue
                // }

                // We don't know the extension of the files being download so just pass an empty string
                // in order to get the default parameters.
                this.setFfmpegParameters(resolution, crf, '')
                if (trimmed) {
                    log(`startPosition=${position.toFixed(1)}, endPosition=${endPosition.toFixed(1)}`)
                    this.ffmpegParameters.outputOptions.push(`-ss ${position}`)
                    this.ffmpegParameters.outputOptions.push(`-to ${endPosition}`)
                }

                let compressedFilePath = await this.compressVideoWithId(src)

                await this.waitForFileToGenerate(compressedFilePath, i, slices.length, setProgressMessage)

                srcsToConcatenate.push(compressedFilePath)
            }
            
            return srcsToConcatenate
        } catch(error) {
            for (let src of srcsToConcatenate) {
                await this.deleteFile(src)
            }
            throw error
        }
    }

    private waitForFileToGenerate = async (filePath: string, index: number, total: number, setProgressMessage: (message: string) => void) => {
        let fiveSecondsPassed = false
        setTimeout(() => { fiveSecondsPassed = true }, 5000)

        while (true) {
            let { error, finished, percent } = await this.getProgress(filePath)
            
            if (error) {
                log('waitForFileToGenerate ERROR', s(error))
                throw new Error(error)
            }

            if (finished) { break }
            
            if (fiveSecondsPassed) {
                let message = t`Compressing video`
                if (total > 1) {
                    message += ` ${index+1}/${total}`
                }
                message += '.'

                if (percent) {
                    message += ` ${percent.toFixed(0)}%`
                }

                setProgressMessage(message)
            }
            
            await delay(1000)
        }
    }

    private deleteFile = async (filePath: string) => {
        filePath = filePath.trim()
        if (filePath === '') return
        log('deleteFile', filePath)

        let response = await fetch(`${COMPRESSION_SERVER_ORIGIN}/?filePath=${filePath}`, {
            method: 'DELETE'
        })

        if (!response.ok) {
            throw new Error(`${response.status} - ${response.statusText}`)
        }
    }

    private compressVideoWithId = async (filePath: string) => {
        let { ffmpegParameters } = this
        let body = JSON.stringify({
            filePath,
            ffmpegParameters
        })

        log('compressVideoWithId input=', fileNameLog(filePath), s(ffmpegParameters))

        let response = await fetch(`${COMPRESSION_SERVER_ORIGIN}/compress`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body,
        })

        // log('compressVideoWithId', response)
        if (!response.ok) {
            throw new Error(`${response.status} - ${response.statusText}`)
        }

        let { filePath: _filePath } = await response.json()
        log('compressVideoWithId result=', fileNameLog(_filePath))
        if (!_filePath) throw Error('Compress, no filePath returned')
        return _filePath as string
    }

    // private getFileMetadata = async (filePath: string) => {
    //     let response = await fetch(`${COMPRESSION_SERVER_ORIGIN}/metadata/?filePath=${filePath}`)
    //     log('getFileMetadata', s(response))

    //     if (!response.ok) {
    //         throw new Error(`${response.status} - ${response.statusText}`)
    //     }
    //     return response.json()
    // }

    private checkFreeSpace = async (_size: number) => {
        let response = await fetch(`${COMPRESSION_SERVER_ORIGIN}/freeSpace`)
        if (!response.ok) {
            throw new Error(`${response.status} - ${response.statusText}`)
        }
        let json = await response.json()
        let { freeSpace } = json

        if (freeSpace < 1.5 * _size) {
            let error = new Error('Not enough free space available')
            error.name = 'NotEnoughFreeSpace'
            throw error
        }
    }

    // Concatenates all the files and returns a path to the concatenated file.
    private concatenateWithIds = async (ids: string[]) => {
        log('concatenateWithIds input=', ids.map(id => fileNameLog(id)))

        let body = JSON.stringify({ filePaths: ids })
        let response = await fetch(`${COMPRESSION_SERVER_ORIGIN}/concatenate`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body,
        })

        if (!response.ok) {
            throw new Error(`${response.status} - ${response.statusText}`)
        }

        let { filePath } = await response.json()
        log('concatenateWithIds result=', fileNameLog(filePath))
        if (!filePath) throw Error('concatenateWithIds, no filePath returned')
        return filePath as string
    }

    // Upload a file to the server and return a path to the uploaded version of the file.
    private uploadFileToServer = async (file: File) => {
        log(`uploadFileToServer size=${sizeInMb(file.size)}, type=${file.type}`)

        let formData = new FormData()
        formData.append('file', file, file.name)
        // the clientId is used in the sltt-app (standalone) to create a
        // separate directory for each browser client. 
        // It will be ignored by the old compressor server.
        const clientId  = getHasElectronContext() ?
            `sltt-app-${getClientId()}-` + pageInstanceId : 'sltt-pwa-' + pageInstanceId
        let response = await fetch(COMPRESSION_SERVER_ORIGIN, {
            method: 'PUT',
            headers: {
               'client-id': clientId 
            },
            body: formData
        })

        if (!response.ok) {
            let error = new Error(response.statusText)
            if (response.status === 413) {
                error.name = 'PayloadTooLarge'
            }
            throw error
        }

        let { filePath } = await response.json()
        log('uploadFileToServer done', fileNameLog(filePath))
        if (!filePath) throw Error('Upload, no filePath returned')
        return filePath as string
    }

    private async getProgress(filePath: string) {
        let response = await fetch(`${COMPRESSION_SERVER_ORIGIN}/progress/?filePath=${filePath}`)
        if (!response.ok) {
            throw new Error(`${response.status} - ${response.statusText}`)
        }

        let { error, finished, percent } = await response.json()
        return { error, finished, percent}
    }

    private async downloadFile(filePath: string) {
        let data = await fetchBlob2(`${COMPRESSION_SERVER_ORIGIN}/?filePath=${filePath}`, () => {})
        log('downloadFile', sizeInMb(data.size))

        this.setProgressMessage('')
        let file = new File([data], 'compressed-video.mp4', { type: 'video/mp4' })
        return file
    }

    private async downloadConcatenatedFile(filePath: string) {
        let data = await fetchBlob2(`${COMPRESSION_SERVER_ORIGIN}/?filePath=${filePath}`, () => {})
        log('downloadConcatenatedFile size=', sizeInMb(data.size))
        
        this.setProgressMessage('')
        let file = new File([data], 'concatenated-video.mp4', { type: 'video/mp4' })
        return file
    }

    static async getVersion() {
        try {
            let version = await fetch(`${COMPRESSION_SERVER_ORIGIN}/version`)
            let data = await version.json()
            return data.version as string
        } catch (err) {
            return ''
        }
    }

    static async checkIfServerRunning() {
        let isServerRunning = await VideoCompressor.isServerRunning()
        if (isServerRunning) return true

        displayInfo(t`Before doing this, start sltt_video_compressor. For more information click here: `, 'details/compressor.html')
        return false
    }
    
    static async isServerRunning() {
        try {
            let response = await fetch(`${COMPRESSION_SERVER_ORIGIN}/version`)
            if (!response.ok) {
                return false
            }
            return true
        } catch (err) {
            return false
        }
    }
}

export default VideoCompressor