// Singleton AppRoot object. 
// When initializingStarted contains all the projects for current user.

import { observable, autorun } from 'mobx'
//import _ from 'underscore'
import jwt from 'jsonwebtoken'

import { Root } from './Root'
import { Project, Portion, Passage, PassageThumbnailVideo, createThumbnailVideo, PassageVideo, DB_ACCEPTOR_VERSION, DBObject, IProjectEntity } from './ProjectModels'
import { displayError, displaySuccess, systemError } from '../components/utils/Errors'
import { clearHistorySearchParameters } from '../components/utils/LocationHistory'
import API, { IAuthorizedProjects, delayUntilOnline } from './API'
import { _LevelupDB, dbPutWithOnlyAcceptParam, acceptLocalThruKeyString as localStorageAcceptLocalThruKey } from '../models3/_LevelupDB'
import { t } from 'ttag'
import PassageEditor from '../components/passages/PassageEditor'
import { VideoCache } from '../models3/VideoCache'
import { fmt, s } from '../components/utils/Fmt'
import { DBAcceptor, PathInfo, normalizeUsername } from './DBAcceptor'
import { TRLResources } from './TRLModel'
import _, { sortBy } from 'underscore'
import { getVideoDuration } from './VideoDuration'
import { rollbar } from '../index'
import { IndexedDB } from './IndexedDB'
import { IDBObject, IDBSyncApi } from './DBTypes'
import { MemoryDB } from './_MemoryDB'
import { featureFlag, featureFlagKey } from '../components/utils/LocalStorage'
import { isSlttAppStorageEnabled, registerClientUser } from './SlttAppStorage'

// Get url search parameters
export function getSearchParams() {
    let { origin, pathname, hash } = window.location
    let nonHashURL = origin.concat(pathname).concat(hash.slice(2))
    let url = new URL(nonHashURL)
    let searchParams = new URLSearchParams(url.search)
    return searchParams
}

const log = require('debug')('sltt:AppRoot') 

function setRollbarUsername(username: string) {
    log('setRollbarUsername', username)

    rollbar.configure({
        payload: {
            person: {
                id: username,
                username: username,
                email: username,
            }
        }
    })
}

class DBSyncApi implements IDBSyncApi {
    loggedIn() { return API.loggedIn()}

    async sync(name: string, docs: IDBObject[], remoteSeq: number, dbId = 'xxxx') {
        return await API.sync(name, docs, remoteSeq, dbId)
    }

    blockServerUpdates() { return API.blockServerUpdates() }

    async delayUntilOnline(logContext: string) { await delayUntilOnline(logContext) }
}

type EntryMatch = {
    key: number | string,
    modDate: string,
    modBy: string,
    removed: boolean,
    _id: string,
    match: string,
    item: DBObject,
    doc: any,
    hardDelete: boolean, // false for video deletions (which can be undeleted by the user)
    pathInfo: PathInfo,
}

export class AppRoot {
    @observable rts: Root[] = []
    @observable initializingStarted = false
    @observable projectsInitialized = false
    @observable initialized = false
    
    @observable id_token = ''

    @observable username = ''
    @observable iAmRoot = false
    @observable iAmDeveloper = false
    iAmRootObserver = false

    @observable currentProjectName = ''

    @observable resourcesFetchDone = false

    @observable useMobileLayout = false
    @observable useNarrowWidthLayout = false

    selectPage: (selection: string) => void

    constructor(selectPage: (selection: string) => void) {
        this.id_token = localStorage.getItem('sltt_id_token') || ''
        API.id_token = this.id_token

        this.username = normalizeUsername(localStorage.getItem('sltt_username') || '')
        setRollbarUsername(this.username)

        this.currentProjectName = localStorage.getItem('sltt_currentProjectName') || ''
        this.selectPage = selectPage
    }

    async setCurrentProject(projectName: string) {
        let { rts } = this
        let index = rts.findIndex(_rt => _rt.name === projectName)
        if (index < 0 && rts.length) {
            log("### can't find project, default to 1st project", fmt({projectName}))
            index = 0
        }

        if (index < 0) return  // no project found, give up
        let rt = rts[index]

        this.currentProjectName = rt.name
        localStorage.setItem('sltt_currentProjectName', this.currentProjectName)
        console.log('setCurrentProject', this.currentProjectName) // helpful for rollbar debugging

        rollbar.configure({ payload: { custom: { project: rt.name } } })

        if (window.location.hash.endsWith(`resetdb=${rt.project.name}`)) {
            await rt.project.db.deleteDB()
            displaySuccess(t`Project database reset. Close and reopen browser to reload database.`)
            return
        }

        if (this.initialized) {
            // clear the search/query string (e.g. ?project=)
            // so it does not try to apply to the wrong project
            clearHistorySearchParameters('setCurrentProject')
        }

        await rt.initialize()  // Load Db entries, this is a NOP if it has already been done

        return rt
    }

     get rt(): Root | null {
        let { rts } = this
        let index = rts.findIndex(_rt => _rt.name === this.currentProjectName)
        if (index < 0) return null

        return rts[index]
    }

    // Usage:
    //    window.appRoot.dbg('')
    //    window.appRoot.dbg('n')
    //    window.appRoot.dbg('ns')

    dbg(details?: string) {
        let rt = this.rt

        let _dbg: any = {
            passageVideo: rt!.passageVideo?.dbg(rt!.passage, details),
            root: rt!.dbg(details), 
        }

        let passage = rt?.passage
        if (passage && details?.includes('v')) {
            _dbg.passageVideos = passage.videos.map(video => video.dbg(passage!, details))
        }

        log(`dbg`, JSON.stringify(_dbg, null, 3))
    }

    // getCurrentProject(): Root | null {
    //     let { currentProjectName } = this
    //     let rt = this.rts.find(_rt => _rt.name === currentProjectName)
    //     // log('getCurrentProject', rt)
    //     return rt || null
    // }

    checkForRegistration(search: string) {
        if (!search) return

        let searchParams = new URLSearchParams(search)

        if (searchParams.get('id') && searchParams.get('time')) {
            return // not a registration code, link to SLTT entity
        }

        if (searchParams.get('feature')) {
            return // not a registration code, feature flag
        }

        if (searchParams.get('code')) {
            return  // not a registration code, is Auth0 redirect
        }

        let id_token = search.slice(1)  // trim ? from start of query string
        log('checkForRegistration', id_token.slice(0, 30))

        try {
            let decoded: any = jwt.decode(id_token)
            if (!decoded) throw `could not decode: ${id_token}`
            this.setUser(decoded.email, id_token)
        } catch (error) {
            systemError(error)
        }
    }

    setUser(username: string, id_token: string) {
        log('setUser', fmt({username, id_token}))
        
        this.id_token = id_token
        API.id_token = this.id_token
        this.username = normalizeUsername(username)
        setRollbarUsername(this.username)

        log('setUser', username)

        localStorage.setItem('sltt_id_token', id_token)
        localStorage.setItem('sltt_username', username)
        isSlttAppStorageEnabled() && registerClientUser({ username }, 'AppRoot.setUser')

        this.reinitializeProjects().catch(displayError)
    }

    signOutUser() {
        this.id_token = ''
        API.id_token = ''
        this.username = ''

        localStorage.setItem('sltt_id_token', '')
        localStorage.setItem('sltt_username', '')
    }

    async reinitializeProjects() {
        log('reinitializeProjects')
        this.initializingStarted = false
        this.initialized = false

        // Shut down all existing db connections before creating new ones
        this.rts.forEach(rt => rt.project.db?.disconnect())

        this.rts = []

        try {
            await this.initializeProjects()
        } catch (error) {
           systemError(error)
        }
    }

    // Initialize projects for this user.
    // Automatically rerun by autorun whenever id_token changes.
    async initializeProjects() {
        const initializeStart = Date.now()
        // Prepare video cache to receive requests to upload/download videos
        await VideoCache.initialize()

        let { id_token, username } = this
        log(`(---start 1) username=${username} id_token=${id_token && id_token.slice(0,60)}`)

        if (!id_token || !username) {
            log(`((---start 1 ERR)) no id_token, done`)
            this.clear()   // If we no longer have a token or username, clear the projects
            this.projectsInitialized = true
            return
        }

        if (this.initializingStarted) return

        this.initializingStarted = true
        this.projectsInitialized = false

        let names: string[] = []
        try {
            log(`((---start 2)) get project names user can access`)
            names = await this.getProjectNamesAndSetRootRole()
            log(`((---start 2 done)) project names`, names)
        } catch (error) {
            log(`((---start 2 ERR))`, error)
            displayError(error)
        }

        try {
            log(`((---start 3)) getPublishedResourceNamesFromS3`)
            await TRLResources.getPublishedResourceNamesFromS3()
        } catch (error) {
            console.error(error)
        }
        log(`((---start 3 done)) getPublishedResourceNamesFromS3 (${TRLResources.publishedResourceNames})`)

        this.rts = names.map((name: string) => this.createRoot(name))

        log(`((---start done done)) rts (${this.rts.length}) created`)

        this.currentProjectName = localStorage.getItem('sltt_currentProjectName') || ''

        AppRoot.setFeatureFlag(getSearchParams().get('feature') ?? '')

        let projectNameFromUrl = getSearchParams().get('project')
        if (projectNameFromUrl) {
            log('projectNameFromUrl', fmt({ projectNameFromUrl }))
            this.currentProjectName = projectNameFromUrl
        }
        
        this.projectsInitialized = true
        let rt = await this.setCurrentProject(this.currentProjectName)

        this.initialized = true
        const initializeEnd = Date.now()
        log(`(---done) initializeProjects End=${initializeEnd} Start=${initializeStart} Duration=${initializeEnd - initializeStart}`)
        
        return rt
    }

    createRoot(name: string) {
        let { username, id_token, iAmRoot, iAmDeveloper, selectPage,
            useMobileLayout, useNarrowWidthLayout } = this
        log(`[${name}] createRoot (${username})`)

        let _db = new IndexedDB(name)
        let db = new _LevelupDB(name, username, _db, new DBSyncApi())

        // Inject VideoCache methods
        Project.copyFileToVideoCache = VideoCache.copyFileToVideoCache
        Project.videoCacheAcceptor = VideoCache.accept

        const authorizedProject = this.authorizedProjects.find(p => p.project === name)
        const lastSeq = authorizedProject?.seq || -1
        let project = new Project(name, db, lastSeq)
        const isRootUser = this.iAmRootObserver ? false : this.iAmRoot
        let rt = new Root(project, username, id_token, isRootUser, iAmDeveloper, selectPage)
        rt.useMobileLayout = useMobileLayout
        rt.useNarrowWidthLayout = useNarrowWidthLayout
        return rt
    }

    authorizedProjects: IProjectEntity[] = []

    async getProjectNamesAndSetRootRole() {
        const result = await API.getAuthorizedProjects()
        log('getProjectNames', result)
        
        const rootObservers = [
            'allisonp@doorinternational.org',
            'ellisbenus@gmail.com',
            'ebenus@biblesocieties.org',
            'weag11@gmail.com',
        ]
        this.iAmRootObserver = result.iAmRoot && (
            localStorage.xrayVision === 'true' || 
            rootObservers.includes(this.username.toLowerCase())
        )
        if (this.iAmRootObserver && localStorage.kryptonite === undefined) {
            // by default, enable kryptonite for root observers (only show their own projects)
            localStorage.kryptonite = 'true'
        }
        const hasKryptonite = localStorage.kryptonite === 'true'
        this.iAmRoot = hasKryptonite ? false : result.iAmRoot
        const devs = ['nmiles@biblesocieties.org', 'milesnlwork@gmail.com',
            'epyle@biblesocieties.org',
            'allisonp@doorinternational.org',
            'caioc@doorinternational.org',
            'stuartt@doorinternational.org',
            'ellisbenus@gmail.com',
            'weag11@gmail.com',
        ]
        this.iAmDeveloper = devs.includes(this.username.toLowerCase())
        // if they have kryptonite, super-user can only see their own projects
        this.authorizedProjects = result.projects.filter(p =>
            (result.iAmRoot && hasKryptonite) ?
                p.members?.some(m => m.email === this.username) : true
        )
        const projectNames = this.authorizedProjects.map(p => p.project)
            .filter(pn => !pn.startsWith('_test'))
        log('getProjectNames: (filtered out _test projects)')
        return projectNames
    }

    // Add new project to systemby invoking API on server
    // Can only be done by root user
    async createProject(name: string) {
        log(`[${name}] API.createProject started`)
        if (this.rts.some(rt => rt.name === name)) {
            throw Error(`Duplicate project name: ${ name }`)
        }

        await API.createProject(name)    // invoke API
        log(`[${name}] addProject done`)
                
        await this.reinitializeProjects()
    }

    async deleteProject(name: string) {
        log(`[${name}] API.deleteProject start`)

        await API.deleteProject(name)   // invoke api
        log(`[${name}] removeProject done`)

        await this.reinitializeProjects()
    }

    clear() {
        let { rts } = this
        log('clear', rts.length)
        
        // rts.forEach(rt => rt.project.db.cancel())  
        
        if (rts.length) 
            this.rts = []
            
        this.initializingStarted = false
        this.initialized = false
    }

    /**
     * Write entry to database indicating that file upload is complete.
     * This accepting this on other systems will trigger the download.
     * @param _id 
     */
    async uploadDone(url: string) {
        const match = /(.*?)\/(.*)-\d+$/.exec(url)
        if (!match) {
            systemError(`uploadDone[${url}]: bad url`)
            return

        }
        const [_unused, projectName, _id] = match

        log(`uploadDone ${projectName}, ${_id}`)

        // let rt = this.rts.find(rt => rt.name === projectName)
        // if (!rt) {
        //     systemError(`uploadDone[${_id}]: no matching project`)
        //     return
        // }

        // try {
        //     await rt.project.db.put({_id: `${_id}@uploaded`})
        // } catch (error) {
        //     systemError(`uploadDone[${_id}]: ${error.toString}`)
        // }
    }

    makeProgressSnapshot() {
        let progressSnapshot = async () => {
            let results: string[] = []

            for (let rt of this.rts) {
                let progress = await this.makeProjectProgressSnapshot(rt)
                log(`progressSnapshot ${rt.name}, ${progress.videos}, ${progress.notes}`)
                results.push(`${rt.name}\t${progress.videos}\t${progress.notes}`)
            }

            log('results', results.join('\n'))
        }

        progressSnapshot()
            .catch(error => log('FAILED!!!', error))
    }

    async makeProjectProgressSnapshot(rt: Root) {
        let { name } = rt.project
        // if (name !== 'TEST1') return

        log('makeProgressSnapshot initializing', name)
        await rt.initialize()

        log('makeS3ProjectSnapshot syncing')
        await rt.project.db.doSync()

        let videos = 0
        let notes = 0

        for (let portion of rt.project.portions) {
            for (let passage of portion.passages) {
                for (let video of passage.videos) {
                    ++videos
                    notes += video.notes.length
                }
            }
        }

        return {videos, notes}
    }


    // Usage:
    //    window.appRoot.makeS3Snapshot()

    makeS3Snapshot() {
        let _makeS3Snapshot = async () => {
            for (let rt of this.rts) {
                log('makeS3ProjectSnapshot initializing', name)
                await rt.initialize()
                await rt.project.db.doSync()
            }

            log('all rts initialized')

            for (let rt of this.rts) {
                await this.makeS3ProjectSnapshot(rt)
            }
        }

        _makeS3Snapshot().catch(error => log('FAILED!!!', error))
    }

    async makeS3ProjectSnapshot(rt: Root) {
        let { name } = rt.project
        log('makeS3ProjectSnapshot', name)

        this.makeProjectTopLevelSnapshot(rt)
        
        for (let portion of rt.project.portions) {
            log('makeS3ProjectSnapshot portion', portion.name)

            for (let passage of portion.passages) {
                await this.makeS3PassageSnapshot(rt, portion, passage)
            }
        }
    }

    async makeProjectTopLevelSnapshot(rt: Root) {
        let snapshot = rt.project.toSnapshot()
        let blob = new Blob([JSON.stringify(snapshot)], { type: 'application/json' })
        let path = `${rt.project.name}/snapshot.json`
        // log(`makeProjectTopLevelSnapshot`, JSON.stringify(snapshot, null, 4))
        log(`makeProjectTopLevelSnapshot`)

        await API.pushBlob(rt.project.name, path, 1, blob)
    }

    async makeS3PassageSnapshot(rt: Root, portion: Portion, passage: Passage) {
        let snapshot = passage.toSnapshot()
        let blob = new Blob([JSON.stringify(snapshot)], { type: 'application/json' })
        let path = `${rt.project.name}/${passage._id}/snapshot.json`
        log(`makeS3PassageSnapshot ${passage.name}[${JSON.stringify(snapshot).length}]`)

        await API.pushBlob(rt.project.name, path, 1, blob)
    }

    async initializeAll() {
        for (let rt of this.rts) {
            let { project } = rt
            if (project.name === '_log_') continue

            log('===initialize', project.name)
            await rt.initialize()
        }
    }

    async countAll() {
        await this.initializeAll()
        console.table(this.rts.map(rt => rt.project.countAll()))
    }

    async downloadTestVideos() {
        let {rt} = this
        let {project} = rt!
        let portion = project.portions.find(p => p.name === 'FGH 06B (A)')
        if (!portion) {
            log('cant find portion FGH_06B (A)')
            return
        }
        log('portion', portion)

        let passage = portion.passages.find(p => p.name === 'M2')
        if (!passage) {
            log('cant find passage M2')
            return
        }

        for (let video of passage.videos) {
            if (!video.url) continue
            await VideoCache.getVideoDownload(video.url)

            for (let note of video.notes) {
                for (let item of note.items) {
                    if (!item.url) continue
                    await VideoCache.getVideoDownload(item.url)
                }
            }
        }

        log('downloadTestVideos DONE')
    }

    // Copy passages from teacher portions to student portions for SLCnsTrn project
    async copyClass(student: number) {
        let { project } = this.rt!
        const teacherPortionsCount = 3

        if (project.name !== 'SLCnsTrn') throw Error('not SLCnsTrn')

        for (let i = 0; i < teacherPortionsCount; ++i) {
            let srcPortion = project.portions[i]

            for (let j = teacherPortionsCount + student; j < teacherPortionsCount + student +1; ++j) {
                let tgtPortion = project.portions[j]

                for (let k=0; k<srcPortion.passages.length; ++k) {
                    let srcPassage = srcPortion.passages[k]
                    await tgtPortion.copyPassage(srcPassage)
                }
            }
        }
    }

    // Copy portions, passages, latest video (and its segments) from src to tgt project
    // Usage: window.appRoot.copyProject('TrainDOOR', 'TrainDOOR2').catch(console.log)
    async copyProject(srcProjectName: string, tgtProjectName: string) {
        const srcProject = _.findWhere(this.rts, { name: srcProjectName })!.project
        const tgtProject = _.findWhere(this.rts, { name: tgtProjectName })!.project

        const videoUrls: string[] = []

        for (const srcPortion of srcProject.portions) {
            await this.copyProjectPortion(srcPortion, tgtProject, videoUrls)
        }

        // This list of urls must be manually pasted into serverless/utils/copyVideos.js.
        // Then: cd serverless/utils; node copyVideos.js
        // to copy the videos from the source to the target project.
        log(JSON.stringify(videoUrls.map(url => url.split('/').slice(1).join('/'))))
    }

    async copyProjectPortion(srcPortion: Portion, tgtProject: Project, videoUrls: string[]) {
        let tgtPortion = _.findWhere(tgtProject.portions, { name: srcPortion.name })
        if (!tgtPortion) {
            tgtPortion = await tgtProject.addPortion(srcPortion.name)
        }

        for (const srcPassage of srcPortion.passages) {
            await this.copyProjectPassage(srcPassage, tgtProject, tgtPortion, videoUrls)
        }
    }

    // Copy a passage and its latest video (if any) and video segments to tgtProject.
    // Don't copy: patches, passage documents, notes, glosses
    async copyProjectPassage(srcPassage: Passage, tgtProject: Project, tgtPortion: Portion, videoUrls: string[]) {
        const _tgtPassage = _.findWhere(tgtPortion.passages, { name: srcPassage.name })
        if (_tgtPassage) return

        // if (_tgtPassage) {
        //     const _video = _tgtPassage.getLatestVideo()
        //     await _video?.fixUrl()
        //     return
        // }

        const tgtPassage = await tgtPortion.addPassage(srcPassage.name)

        const srcVideo = srcPassage.getLatestVideo()
        if (!srcVideo) return

        const patches = srcVideo.segments.filter(segment => segment.patchVideo(srcPassage))
        if (patches.length) {
            log(`### ${tgtPortion.name}/${tgtPassage.name}: No support for copying patched videos`)
            debugger
        }

        videoUrls.push(srcVideo.url)

        const _video = tgtPassage.createVideo(srcVideo.url.split('/')[0])
        _video.duration = srcVideo.duration
        
        // Use src video url but using target project name
        const { url } = srcVideo
        const parts = url.split('/')
        parts[0] = tgtProject.name
        _video.url = parts.join('/')

        const tgtVideo = await tgtPassage.addVideo(_video)

        for (const srcSegment of srcVideo.segments) {
            if (srcSegment.removed) continue

            const { position, labels, references, cc } = srcSegment
            await tgtVideo.addSegment(position, labels, references, cc)
        }
    }

    /** 
     * Copy all passages in src portion to tgt portion.
     * window.appRoot.copyPortion('ASL_FGH', 'FHG 27', 'FGG 27 (COPY)').then()
     */
    async copyPortion(projectName: string, srcPortionName: string, tgtPortionName: string) {
        let { project } = this.rt!

        if (project.name !== projectName) throw Error(`not ${projectName}`)

        let srcPortion = project.portions.find(p => p.name === srcPortionName)
        if (!srcPortion) throw Error('could not find source portion')

        try {
            await project.addPortion(tgtPortionName) // will throw if portion already exists
        } catch (error) {
        }

        let tgtPortion = project.portions.find(p => p.name === tgtPortionName)
        if (!tgtPortion) throw Error('could not find target portion')

        for (let k = 0; k < srcPortion.passages.length; ++k) {
            let srcPassage = srcPortion.passages[k]
            await tgtPortion.copyPassage(srcPassage)
        }
    }

    traineeEmails = `trainee1@example.com, trainee2@example.com, trainee3@example.com`

    /**
     * Create project members based on traineeEmails.
     * Create a portion for each trainee using their individual name from the email, e.g. nmiles@biblesocities.org => nmiles
     * Copy all the passages from 'Main' portion to each indivual traineed portion.
     * 
     * To create class info in project.
     *    - edit projectName
     *    - edit traineeEmails to contain list of class member emails
     *    - window.appRoot.setupTrainingClass().catch(console.log)
     */
    async setupTrainingClass() {
        let { project } = this.rt!
        let members = this.traineeEmails.split(/,?\s+/)
        for (let member of members) {
            await project.addMember(member)
        }

        let portions = members.map(m => m.split('@')[0])
        let sourcePortion = 'Main'
        let projectName = 'TrainUBS'

        for (let portion of portions) {
            await this.copyPortion(projectName, sourcePortion, portion)
        }
    }

    /** 
     * Copy single passage to tgt portion.
     * window.appRoot.copyPassage('FHG 27', 'FGG 27 (COPY)', 'GEN 1').then()
     */
    // _.copyPassage('Main-Case Studies', 'alvingbail', 'Discourse BF 20').catch(console.log)
    async copyPassage(srcPortionName: string, tgtPortionName: string, srcPassageName: string) {
        let { project } = this.rt!

        let srcPortion = project.portions.find(p => p.name === srcPortionName)
        if (!srcPortion) throw Error('could not find source portion')

        let tgtPortion = project.portions.find(p => p.name === tgtPortionName)
        if (!tgtPortion) throw Error('could not find target portion')

        let srcPassage = srcPortion.passages.find(p => p.name === srcPassageName)
        if (!srcPassage) throw Error('could not find source passage')

        await tgtPortion.copyPassage(srcPassage)
    }

    // Copy srcPortionName/passageName to all portions from firstPortionName to lastPortionName.
    // Used to copy a video and associate information to all trainees in a class.
    // e.g. _.copyPassageToPortions('Master Videos', 'Training Video 1', 'Tom S', 'Sarah G').catch(console.log)
    async copyPassageToPortions(srcPortionName: string, passageName: string, firstPortionName: string, lastPortionName: string) {
        const { project } = this.rt!

        const firstPortion = project.portions.findIndex(p => p.name === firstPortionName)
        if (firstPortion === -1) throw Error(`could not find portion ${firstPortionName}`)

        const lastPortion = project.portions.findIndex(p => p.name === lastPortionName)
        if (lastPortion === -1) throw Error(`could not find portion ${lastPortionName}`)

        if (lastPortion < firstPortion) throw Error(`lastPortion < firstPortion`)

        for (let i=firstPortion; i<=lastPortion; ++i) {
            const tgtPortionName = this.rt?.project.portions[i].name
            await this.copyPassage(srcPortionName, tgtPortionName!, passageName)
        }
    }

   /**
     * This can be run at from the devtools console to provide programmatic mod doc history 
     * (from the local database) and the state of the object as evaluated by the acceptor code.  
     * The state of the dbObject is determined by how far you let the history run as currently 
     * controlled by the  acceptLocalThruKey parameter.   
     * So after a first pass to get the full history, you could go back and set the query to only 
     * evaluate up to a certain seq, and that should give you the state of the object through that seq.  
     * The query is regex based, and you can specify which property to query (which can be in the doc 
     * or in the object that is set by the acceptor).
     */
    // _.queryLocalPathInfo({projectName: _.rt.project.name, queryRegex: '220218_101904/220401_134025', propToMatch: '_id', acceptLocalThruKey: 53185})
    async queryLocalPathInfo(options: {
        projectName: string,
        queryRegex: string,
        propToMatch: string,
        acceptLocalThruKey?: number,
        applyEndPositionFix?: boolean
    }) : Promise<EntryMatch[]> {
        const { projectName, queryRegex, propToMatch, acceptLocalThruKey, applyEndPositionFix } = options
        const entryMatches: EntryMatch[] = []
        const { entries, inMemoryDbAcceptor } = await this.getEntriesAndInMemoryDbAcceptor(projectName, applyEndPositionFix)
        log('queryLocalPathInfo params', { projectName, queryRegex, propToMatch, acceptLocalThruKey })
        log(`queryLocalPathInfo entries.length (${projectName})`, entries.length)
        log(`queryLocalPathInfo size of entries (${projectName}): ${(JSON.stringify(entries).length/1024).toFixed(2)} KBs`)
        const getMatch = (s: string) => s.match(queryRegex)?.slice().find(() => true)

        for (let i = 0; i < entries.length; ++i) {
            let gotMatch: string | undefined = undefined
            const entry = entries[i]
            if (typeof entry.key === 'number' && entry.key <= 0 || !entry.doc || !entry.doc._id) {
                continue
            }
            if (acceptLocalThruKey && typeof entries[i].key === 'number' && (entries[i].key as number) > acceptLocalThruKey) {
                log('queryLocalPathInfo acceptLocalThruKey', { acceptLocalThruKey, key: entries[i].key })
                break
            }
            const { key, doc } = entry
            if (propToMatch === 'key') {
                const sKey = `${key}`
                gotMatch = getMatch(sKey)
                if (!gotMatch) {
                    continue
                }
            }
            else if (propToMatch in doc) {
                gotMatch = getMatch((doc as any)[propToMatch])
                if (!gotMatch) {
                    continue
                }
            } else {
                // assume prop is in item
            }
            try {
                inMemoryDbAcceptor!.accept(entry.doc, undefined, entry.key, (pathInfo: PathInfo) => {
                    const {
                        docRemoved,
                        deletedFromParent,
                        item,
                    } = pathInfo
                    if (!gotMatch && item && propToMatch in item) {
                        gotMatch = getMatch((item as any)[propToMatch])
                    }
                    if (!gotMatch) return
                    entryMatches.push({
                        key,
                        modDate: (doc as any).modDate,
                        modBy: (doc as any).modBy,
                        removed: !!docRemoved,
                        _id: doc._id,
                        match: gotMatch,
                        item,
                        doc,
                        hardDelete: !!deletedFromParent,
                        pathInfo,
                    })
                })
            } catch (error) {
                log(`### queryLocalPathInfo error entry key ${entry.key} doc ${s(doc)}`, error)
            }
        }
        log('queryLocalPathInfo entryMatches', entryMatches.map(entry => ({
            key: entry.key,
            modDate: `${entry.modDate.substring(0, 10)}...`, // truncate to 10 characters,
            _id: entry._id,
            match: entry.match,
            item: entry.item,
            doc: entry.doc,
            removed: entry.removed,
            hardDelete: entry.hardDelete,
        })))
        return entryMatches
    }

    async queryLocalDeletedPathItems(options: { queryRegex: string, propToMatch: string }) {
        const { queryRegex, propToMatch } = options
        const matchedPathInfo = await this.queryLocalPathInfo({
            projectName: this.rt!.project.name, queryRegex, propToMatch
        })
        const deletedPathItems = matchedPathInfo.filter(entry => entry.removed).map(entry => {
            return {
                key: entry.key,
                hardDelete: entry.hardDelete,
                modDate: entry.modDate,
                modBy: entry.modBy,
                _id: entry._id,
                match: entry.match,
                item: entry.item,
                doc: entry.doc,
                pathInfo: entry.pathInfo,
            }
        })
        log('queryDeletedPathItems deletedPathItems', deletedPathItems)
        return deletedPathItems
    }
    

    // defaults to this.rt!.project.name
    private async getEntriesAndInMemoryDbAcceptor(projectName?: string, applyEndPositionFix?: boolean) {
        if (!this.rt) {
            log('## getEntriesAndInMemoryDbAcceptor: rt not yet initialized')
            return { entries: [], tempDbAcceptor: undefined }   
        }
        if (projectName && this.authorizedProjects && !this.authorizedProjects.some(proj => proj.project === projectName)) {
            throw Error(`getEntriesAndInMemoryDbAcceptor: invalid project name: ${projectName}`)
        }
        const name = projectName || this.rt!.project.name
        const lastSeq = this.authorizedProjects.find(p => p.project === name)?.seq || -1
        const inMemoryProject = new Project(name, new MemoryDB(_LevelupDB.getDate), lastSeq)
        const inMemoryDbAcceptor = new DBAcceptor(inMemoryProject, Project.videoCacheAcceptor, DB_ACCEPTOR_VERSION, false, !!applyEndPositionFix)
        const entries = await IndexedDB.readDBRecords(name)
        return { entries, inMemoryDbAcceptor }
    }

    // NOTE: rewindToKey > -1 will set API.blockServerUpdates() to true
    rewindAndReload(rewindToKey: number = -1) {
        const regex = /\/rewindToKey\/\d+/
        let replacement: string | undefined = undefined
        if (rewindToKey > -1) {
            replacement = `\/rewindToKey\/${rewindToKey}`
            localStorage.acceptLocalThruKey = `${rewindToKey}`
        } else {
            replacement = ``
            localStorage.removeItem('acceptLocalThruKey')
        }

        if (window.location.hash.match(regex)) {
            window.location.hash = window.location.hash.replace(regex, replacement)
        } else {
            window.location.hash += `${replacement}`
        }
        window.location.reload()
    }

    private async queryForLatestItems({ projectName, queryRegex, propToMatch, parentDocIds, acceptLocalThruKey, applyEndPositionFix }:{
        projectName: string, queryRegex: string, propToMatch: string, parentDocIds: string[], acceptLocalThruKey?: number,
        applyEndPositionFix?: boolean 
    }) {
        const pathItems = await this.queryLocalPathInfo({ 
            projectName, queryRegex, 
            propToMatch, acceptLocalThruKey, applyEndPositionFix
        })
        const { latestItems, prunedItems, deletedParents } = this.getLatestItems(pathItems, parentDocIds)
        return { pathItems, latestItems, prunedItems, deletedParents }
    }

    private getLatestItems(pathItems: EntryMatch[], parentDocIds: string[]) {
        const latestItems: { parents: { [path: string]: PathInfo }, children: { [path: string]: PathInfo } } = { parents: {}, children: {} }
        const prunedItems: { parents: { [path: string]: PathInfo }, children: { [path: string]: PathInfo } } = { parents: {}, children: {} }
        const getIsParentOrChildToRestore = (path: string) => {
            const isParentToRestore = parentDocIds.includes(path)
            const isChildToRestore = !isParentToRestore && parentDocIds.some(p => path.startsWith(p))
            return { isParentToRestore, isChildToRestore }
        }
        const pruneChildren = (path: string) => {
            Object.keys(latestItems.children)
                .filter(childPath => childPath.startsWith(path))
                .forEach(childPath => {
                    prunedItems.children[childPath] = latestItems.children[childPath]
                    delete latestItems.children[childPath]}
                )
        }
        // first pass collect latest items (especially for gathering parent `rank`)
        const deletedParents: typeof pathItems = []
        for (const pathItem of pathItems) {
            const { pathInfo } = pathItem
            const { itemPathNoTag, deletedFromParent } = pathInfo
            const { isParentToRestore, isChildToRestore } = getIsParentOrChildToRestore(itemPathNoTag)
            if (isParentToRestore) {
                latestItems.parents[itemPathNoTag] = pathInfo
                // if removed, warn to make sure they intended that
                if (pathInfo.docRemoved) {
                    log(`## recreateDbDocs: WARNING: parent doc removed: ${s(pathInfo)}`)
                    deletedParents.push(pathItem)
                }
            } else if (isChildToRestore) {
                if (deletedFromParent) {
                    pruneChildren(itemPathNoTag)
                } else {
                    latestItems.children[itemPathNoTag] = pathInfo
                }
            } else {
                throw new Error(`recreateDbDocs unexpected pathItem ${s(pathItem)}`)
            }
        }
        return { latestItems, prunedItems, deletedParents }
    }

    // _.recreateDbDocs(parentDocIds) can be used to undelete changes from the devtools console
    //
    // For more details see following github issues 
    //  - chore(client): add util for restoring deleted data link to two relevant issues #762: https://github.com/ubsicap/sltt/issues/762 
    //  - chore(client): restore TBSR Marcu portions (7-16) #786L https://github.com/ubsicap/sltt/issues/786
    // ------------------------------------------------
    // CAVEATS using _.recreateDbDocs() to undelete changes:
    // ------------------------------------------------
    // 1. current sync() code seems to only batch 5 docs together (4Kb) which can take quite some time
    // to upload 21745 docs (10 MB)
    //
    // TODO: investigate if we can reduce sync intervals to speed up the process
    //
    // 2. There are some outstanding issues that need addressing to worry about:
    //    - #788 fix(client): first doc getting skipped for some reason (without error?) https://github.com/ubsicap/sltt/issues/788
    //    - #789 fix(serverless): sync api should be better at ignoring identical docs with the same modDate https://github.com/ubsicap/sltt/issues/789
    //  In summary, the first doc got left behind when I ran this and an Error 500 happened on last batch of 5 docs,
    //  it resulted in duplicate docs every time it resent the batch of 5 docs.
    //
    // TODO: write sync code that will 1) make sure to only send the next doc if the previous one was successful
    //    2) fail loudly if 500 error happens (don't repeat)
    //
    // 3. Typically you'll want to avoid writing to the database to avoid changing the remote central database,
    // however, before doing the final server changes its recommended to
    // set up a new SLTT_{projectName}_restore project, and copy to it first and run the experiment there
    // so that you'll be aware of any issues that you may encounter during the final run
    //
    // TODO: make this code take a `sourceProjectName`, `sourceSeqStart`, `sourceSeqEnd`
    // to allow copying from a specific range of seqs from any given project
    //
    // 4. The snapshot process could be improved to automatically capture
    //   1) the baseline state of the app with the source docs
    //   2) the final state of the app after putting/accepting the new docs.
    // Right now, the snapshots depend on the docs being written to the database,
    // otherwise the caller must pass in code to capture the snapshots manually via `customCaptureParentSnapshots`
    //
    // TODO: add `onGotPathInfo` callback to db.put() and IDBAcceptor.accept() to allow capturing snapshots
    //
    // Some SLTT code has side-effects that can affect the snapshots:
    //  - during app initialization setPassageVideo() will get called in the context of Root.restoreDefaults()
    //    causing fields like PassageVideo.segmentTimesSet to be set
    //  - during put/accept DbAcceptor.acceptPassageSegment() can fix Segment.endPosition
    //    for this reason localStorage.skipEndPositionFix === 'true' or localStorage.acceptLocalThruKey can be used to skip these changes
    // ------------------------------------------------
    // ALTERNATIVE IMPLEMENTATIONS:
    // ------------------------------------------------
    // As far as SLTT is concerned, (as suggested by Nathan) the easiest way to `undelete` changes would be to
    // modify DbAcceptor to keep all the deleted objects in the DBObject tree until all the db entries are accepted during initialization.
    // This approach would
    // 1) only require undeleting the parent doc to restore all the children,
    // 2) save the database from having to re-write deleted child docs from the past, and
    // 3) save each team member from needing to redownloading the restored docs.
    //
    // However, that approach would not help the reporting system which builds its events in such a way that
    // when parents are deleted, it reports that all the children are deleted as well.  Since these events
    // could be fed to external partners in other systems like DOMO, we need a way that makes it clear when those same children have been restored again.
    // The simplest and least technical-debt approach would be to restore the parent docs and the children docs
    //
    // Ideally we would do that in such a way that only restores docs needed to recreate the state of the app before they were deleted.
    // However, figuring out the order in which to write the docs to create that state is way too complicated, and
    // would create technical debt trying to do that.
    //
    // For one, doc changes can affect different properties of the object based on the same _id.
    // Furthermore, some children can modify their parent (e.g. PassageNoteItems docs can
    // mutate the `position` of the parent PassageNote doc.) Because of this, restoring all the relevant docs
    // in their original order will be the most reliable way to restore the app to its previous state.
    //
    // =========================================
    // UNDELETE Process with recreateDbDocs()
    // =========================================
    // 1. As a precaution, in devtools console set localStorage.blockServerUpdates = 'true'
    // to enable API.blockServerUpdates() and prevent any changes being made to the target project IndexedDb
    // (and thus being synced with the remote central database).
    // NOTE: this will also block up-syncs of any unsynced local database changes
    // however, it WILL ALLOW down-syncs to get the latest docs from the remote central database
    // 2. Switch to the project you wish to fix (e.g. TBSR)
    // 3. Then use _.queryLocalDeletedPathItems()` to find the
    // parentDocIds of the deleted objects and the db key associated with the doc right BEFORE the deletion
    //
    // For example, to find deleted items associated with the name `Marcu`:
    //  `_.queryLocalDeletedPathItems({ queryRegex: 'Marcu.*', propToMatch: 'name'}).then(console.log)`
    //   Record the _id of each remove doc and their db keys
    // 4. Next use _.rewindAndReload() to rewind the app to the state before the deletion
    // occurred so you can preview what it should look like after restoring at a specific db key
    // For instance, if you want to restore a portion that was deleted by the doc at db key 53186
    // then you would rewind to the doc right before that so
    //  _.rewindAndReload(53186-1)
    // 5. After confirming the app state is what you want to recover,
    // it's a good idea to capture a snapshot of the relevant objects to establish a baseline to compare
    // with the final state.
    //
    // For example, to get the snapshots of certain portions at the current app state:
    //  _.rt.project.portions.filter(p => [
    //   '220218_101904',
    //   '220218_101908',].includes(p._id)).map(p => p.toSnapshot())
    // Then you can right+click on the result and select "Copy object", paste into vscode and save as a file
    //
    // NOTE: Since setPassageVideo() will get called in the context of Root.restoreDefaults()
    // and thus mutate some video data (e.g. segmentTimesSet), it's best to make a baseline snapshot
    // from the video tab on a portion or passage that won't be affected by the restore.
    //
    // 6. Next, go back to the latest version of the application:
    //   _.rewindAndReload(-1)
    // 7. Next, you may use _.recreateDbDocs() to capture all the relevant doc changes up to that point
    // so they can be re-put on top of latest app state
    //   load app to latest db state
    //   localStorage.blockServerUpdates = 'true'
    // in dev console:
    // _.createRestoreDocuments(['220218_101904',], '', true).then(console.log)
    //
    // ==== Production Steps ====
    // 0. ideally the restoration steps would be done in a separate SLTT_ restore project first.
    //    See Caveat #3 above for more details
    //
    // After you've confirmed the final snapshot diffs are as expected with writeToDb = false,
    // then you can prepare to write the changes to the database:
    // 1. ideally you'd want to wait until team has finished their work for the day
    //    and are no longer making changes to the remote central database.
    //    Try to coordinate with the team about the best time to do this and to establish a
    //    handshaking plan, so they know when to resume work.
    //
    //    Doing the restoration while the team is resting will,
    //    1) allow you to make a final dry run to confirm changes are as expected
    //    2) ensure all the final doc changes get upsynced to the remote central database
    //    one after the other without new changes being made in between
    //    3) prevent users from changing the state of the data before its been fully restored,
    //    so snapshots won't show anything unexpected
    //
    // 2. verify that there are no outstanding local changes in the project's indexedDb
    // 3. set localStorage.blockServerUpdates = 'false' and refresh the browser to
    //    downsync final changes from the server/remote central database
    // 4. turn off your internet connection
    // 5. run reacreateDbDocs() with writeToDb = true
    // 6. verify snapshots are as expected
    // 7. verify indexedDb changes appear as expected (having the right amount of docs)
    // 8. turn on your internet connection and refresh your browser to begin upsyncing the changes
    // 9. make sure your browser stays open until all the changes have been upsynced (which can take several minutes)
    // 10. watch the indexed db by searching for 1000000001 and make sure each disappears in order
    //     and non get skipped.
    // 11. if anything goes wrong (or any error 500 occurs), turn off your internet connection and/or set localStorage.blockServerUpdates = 'true'
    // so you can figure out a plan forward. Certainly copy the payload state of the 500 error so you can
    // evaluate which doc caused an issue. If you can't fix the doc in-place in the indexedDb, you may need to
    // delete your indexeddb (and unsynced local docs) and begin the restore process again starting off from the xSeq of the last successful doc.
    // the xSeq should be the reference to the original doc you are trying to restore.
    // 12. once all the changes have been upsynced, you can do your final snapshots
    // (hopefully from a portion/passage/video that was not a part of the restoration process. See Caveat #4 above about side-effects)
    //
    // =========================================
    // recreateDbDocs() operation
    // =========================================
    /**
     * Recreates database documents for the specified parent docs and their children.
     * 
     * How it works: 
     *  1. pass `acceptLocalThruKey` (indexedDb seq/key) to queryForLatestItems() which queries the indexedDb (via queryLocalPathInfo())
     *   to match docs with _ids of the `parentDocIds` and their children until the `acceptLocalThruKey` key is finished.
     *   queryLocalPathInfo() also gets the DBObject item objects associated with those paths (via DbAcceptor onGotPathInfo callback) which
     *   will end up being in the state of those objects at acceptLocalThruKey.
     *   queryForLatestItems() indexes all the paths by parent and child _id paths, and prunes those which have been removed from object tree (hardDelete)
     *   We don't need to restore children which have been explicitly removed
     *   (exception: deletion of passage videos are not treated as a hardDelete because the user can undelete those)
     *   TODO: treat deletion of patches as hardDelete since user cannot undo those. This requires change in unit tests.
     *  2. Put docs back in the original order, but skip docs which have been pruned from the DBObject tree upto acceptLocalThruKey.
     *     - store `_xModDate` so DBAcceptor can use to restore original modDates when accepting those docs 
     *             for `PassageVideo.statusModDate` and `PassageDocument.creationHistory`
     *     - store `_xSeq` so we know which doc the recreated doc was restoring
     *     - store `_xAt` so we know when `recreateDbDocs()` was called to restore all the docs
     *     - store `_xxSeq`when restoring docs that were restored from the 
     *  3. Capture snapshot of parents before parent rank changes are put
     *  4. Put a doc for each parent that changes its rank to fit together into the latest state of the app
     *  5. Put a comment doc (in projectPreferences) that captures metadata from the restoration process
     *     - xComment - typically the title of the github issues associated with the need to restore
     *     - xScript: name - `reacreateDbDocs()` - params - without the comment 
     *     - xResult: summary of results
     *  6. Capture snapshot of parents after rank changes
     *  7. Use LogSummarizeResults() to output results in console
     * 
     * TODO: manage the sync() side of this so that docs can be uploaded (one by one?) as soon as possible and
     * any errors will (optionally?) stop the process to allow for investigation and debugging.
     * 
     * @param parentDocIds - The parent docs you'd like to restore (assuming you also want their children restored).
     *   NOTE: We've only tested this on sibling restoration, so it may not work if any children doc ids are included.
     * @param comment - A description of the restoration process (e.g. GitHub issue title and number)
     *   which will be added to the final `projectPreferences` doc.
     * @param options.acceptLocalThruKey - Used with an in-memory database to capture the state of the app up to 
     *   that point. It defaults to localStorage.acceptLocalThruKey. If undefined, assumes the latest state of the app.
     * @param options.rankStartingAfter - Used as the insertion point for the restored parent docs.
     * @param options.doPut - If true, accepts the recreated docs so you can see the effects in the app.
     * @param options.writeToDb - (Assuming doPut is true) If true, writes the recreated docs to the database.
     *   False by default means the changes will be accepted, but not stored in the indexedDb, so refreshing the browser
     *   will undo the effects of doPut.
     * @param options.applyEndPositionFix - Can be used to skip the endPosition fix for PassageSegments. False by default 
     *   allows snapshots to preserve the original PassageSegment docs state.
     * @param options.customCaptureParentSnapshots - A function that can be used to capture the state of the app
     *   without this, you must set doPut and writeToDb to true for the default snapshots to work.
     */
    async recreateDbDocs(parentDocIds: string[], comment: string, {
        acceptLocalThruKey = parseInt(localStorageAcceptLocalThruKey!),
        rankStartingAfter = 0,
        doPut = false,
        writeToDb = false,
        applyEndPositionFix = false /* temporary side-effect: localStorage.skipEndPositionFix = true */,
        customCaptureParentSnapshots
    }: {
        acceptLocalThruKey: number, rankStartingAfter: number, doPut: boolean, writeToDb: boolean,
        applyEndPositionFix: boolean,
        customCaptureParentSnapshots: (parentDocIds: string[]) => Promise<any[]>
    }) {
        const params = {
            parentDocIds, comment, options: {
                acceptLocalThruKey, rankStartingAfter, doPut, writeToDb
            }
        }
        log('recreateDbDocs params', params)
        if (!Array.isArray(parentDocIds) || parentDocIds.length === 0){
            log('recreateDbDocs requires parentDocIds', parentDocIds)
            return
        }
        const invalidParentDocIds = parentDocIds.filter(docId => !docId)
        if (invalidParentDocIds.length) {
            log(`recreateDbDocs invalid parentDocIds [${invalidParentDocIds.join(',')}] in ${parentDocIds}`)
            return
        }
        const remoteSeq = this.rt?.project?.db?.getRemoteSeq()!
        if (Number.isNaN(acceptLocalThruKey) && acceptLocalThruKey < 1 || acceptLocalThruKey > remoteSeq ) {
            log('recreateDbDocs requires valid acceptLocalThruKey key', { acceptLocalThruKey, localStorageAcceptLocalThruKey, remoteSeq })
            return
        }
        if (doPut && acceptLocalThruKey === remoteSeq) {
            log('recreateDbDocs should not re-put latest state of docs to latest state', { doPut, remoteSeq, acceptLocalThruKey })
            return
        }
        if (writeToDb && !doPut) {
            log('recreateDbDocs writeToDb true also requires doPut to be true', { doPut, writeToDb })
        }
        const originalSkipEndPositionFix = localStorage.skipEndPositionFix
        if (!applyEndPositionFix) {
            localStorage.skipEndPositionFix = 'true'
        }
        const captureParentSnapshots = async () => {
            // ideally we'd be able to capture the put state without writeToDb
            // but that would require further refactoring of the db.put method to pass
            // a callback for onGotPathInfo, and refactoring code in queryLocalPathInfo().
            // alternatively we could use the inMemoryDbAcceptor and accept() each doc
            // to mutate the in-memory db state. however, since the goal is the real
            // database and real project state, we'd prefer that as source of truth
            if (!doPut || !writeToDb) {
                log('recreateDbDocs: no snapshot -- requires doPut and writeToDb:', { writeToDb, doPut })
                return
            }
            const regexExcludingChildren = parentDocIds.join('|')
            const { latestItems } = await this.queryForLatestItems({ 
                projectName: this.rt!.project.name, queryRegex: regexExcludingChildren, 
                propToMatch: '_id', parentDocIds, applyEndPositionFix
            })
            return Object.values(latestItems.parents).map(p => p.item.toSnapshot())
        }
        const regexIncludeChildren = parentDocIds.map(docId => `${docId}.*`).join('|')
        const breakAfterKey = acceptLocalThruKey || localStorageAcceptLocalThruKey && parseInt(localStorageAcceptLocalThruKey) || undefined
        const { pathItems, latestItems: sourceItems, prunedItems, deletedParents } = await this.queryForLatestItems({ 
            projectName: this.rt!.project.name, queryRegex: regexIncludeChildren, 
            propToMatch: '_id', acceptLocalThruKey: breakAfterKey, parentDocIds, applyEndPositionFix
        })
        const missingItems = Object.values(sourceItems.children).filter(pathInfo => !pathInfo.item)
        console.log('recreateDbDocs missingItems', missingItems)
        if (missingItems.length) {
            return
        }

        // first pass is to re-put all the original docs
        const targetDocs: any[] = []
        const skippedDocs: any[] = []
        const { project, username } = this.rt!
        const { db: dbProj } = project
        const dbTarget = dbProj as dbPutWithOnlyAcceptParam
        const _xAt = new Date(Date.now()).toISOString()
        const _xBy = username

        doPut && log('createdDbDocs: put recreated docs...')
        for (const pathItem of pathItems) {
            const { doc, key: seq, pathInfo } = pathItem
            const { itemPathNoTag } = pathInfo
            if (!(itemPathNoTag in sourceItems.parents) &&
                !(itemPathNoTag in sourceItems.children)) {
                skippedDocs.push(doc)
                continue
            }
            const _xxSeq = doc._xSeq ? { _xxSeq: doc.seq  /* link to actual seq in x doc */ } : {}
            const recreatedDoc = {
                ...doc,
                modDate: dbProj.getDate(),
                _xModDate: doc._xModDate /* preserve original date */ || doc.modDate,
                _xSeq: doc._xSeq /* preserve original seq */ || seq,
                ..._xxSeq,
                _xAt,
                _xBy,
            }
            targetDocs.push(recreatedDoc)
            if (doPut) {
                await dbTarget.put(recreatedDoc, !writeToDb)
            }
        }
        const snapshotsBeforeRankChange = doPut && await (customCaptureParentSnapshots ? 
            customCaptureParentSnapshots(parentDocIds) : captureParentSnapshots()) || []
        const rankDocs: any[] = []
        // next write new rank docs for parent
        // try to fit parents between increments of 100 starting at first rank
        const sortedParentsByRank = sortBy(Object.values(sourceItems.parents), pathInfo => pathInfo.item.rank)
        const rankStart = (100 / (sortedParentsByRank.length + 1))
        doPut && log('createdDbDocs: put rank docs...')
        for (let i = 0; i < sortedParentsByRank.length; i++) {
            const pathInfo = sortedParentsByRank[i]
            const { item, itemsProperty } = pathInfo
            if (!itemsProperty) {
                // this is not part of a collection so doesn't have/need a rank
                continue
            }
            const rank = DBObject.numberToRank(rankStartingAfter + rankStart * (i + 1))
            const rankDoc = item._toDocument({
                rank,
                _xAt,
                _xBy,
            })
            rankDocs.push(rankDoc)
            if (doPut) {
                await dbTarget.put(rankDoc, !writeToDb)
            }
        }
        const { comment: commentParam, ...paramsWithoutComment } = params
        // add comment doc (shouldn't affect snapshot)
        const commentDoc = this.rt?.project._toDocument({
            _id: 'projectPreferences',
            model: 3,
            _xComment: comment,
            _xScript: {
                name: 'recreateDbDocs',
                params: paramsWithoutComment,
            },
            _xResult: `added ${targetDocs.length} docs ${(JSON.stringify(targetDocs).length / 1024).toFixed(2)} KBs`,
            _xAt,
            _xBy,
        })
        if (doPut) {
            await dbTarget.put(commentDoc, !writeToDb)
        }
        const snapshotsAfterRankChange = doPut &&  await (customCaptureParentSnapshots ? 
            customCaptureParentSnapshots(parentDocIds) : captureParentSnapshots()) || []
        localStorage.skipEndPositionFix = originalSkipEndPositionFix
        LogSummarizeResults()
        function LogSummarizeResults() {
            log('recreateDbDocs pathItems', pathItems)
            log('recreateDbDocs sourceItems', sourceItems)
            log('recreateDbDocs prunedItems', prunedItems)
            log('recreateDbDocs ## deletedParents ##', deletedParents)
            log(`recreateDbDocs skippedDocs (${(JSON.stringify(skippedDocs).length / 1024).toFixed(2)} KBs)`, skippedDocs)
            log(`recreateDbDocs targetDocs (${(JSON.stringify(targetDocs).length / 1024).toFixed(2)} KBs)`, targetDocs)
            log('recreateDbDocs rankDocs', rankDocs)
            log('recreateDbDocs commentDoc', commentDoc)
            log('recreateDbDocs snapshotsBeforeRankChange', snapshotsBeforeRankChange)
            log('recreateDbDocs snapshotsAfterRankChange', snapshotsAfterRankChange)
        }
    }

    async fixDupsAll() {
        let dryRun = false

        await this.initializeAll()

        for (let rt of this.rts) {
            if (rt.name === '_log_') continue

            log('===PROJECT', rt.name)
            let plan = rt.project.plans[0]
            if (plan.checkIds()) {
                // duplicates found
                await plan.fixIds(dryRun)
            }
        }
    }

    // Stub routine for code manipulating model
    // Invoke: window.appRoot.c()
    c() {
        async function _c(rt: Root) {
            let { project } = rt
            log(s(project.messages.map(m => m.dbg())))
        }

        _c(this.rt!).catch(log)
    }

    // Default passage video debugging output: window.appRoot.pv()
    pv(details?: string) {
        details = details === undefined ? 'ni' : details
        let { rt } = this
        let { passage, passageVideo } = rt!
        let result = passageVideo!.dbg(passage, details)
        log(s(result))
    }

    pn(details?: string) {
        details = details === undefined ? 'i' : details
        let { rt } = this
        let { passage, note } = rt!
        if (!note) { return 'no note selected'}

        let result = note.dbg(details)
        log(s(result))
    }

    
    static setFeatureFlag(flag: string) {
        if (!flag) return
        
        let flags = (localStorage.getItem(featureFlagKey) ?? '').split('/')
        if (flag.startsWith('-')) {
            flag = flag.slice(1)
            flags = flags.filter(f => f !== flag)
        } else {
            if (!flags.includes(flag)) { flags.push(flag) }
        }

        log('!!!setFeatureFlag', fmt({flags}))
        localStorage.setItem(featureFlagKey, flags.join('/'))
    }

    static featureFlag(flag: string) {
        return featureFlag(flag)
    }

    // call this from the console when you need to restore a thumbnail after browser refresh
    // Usage:
    //    window.appRoot.restoreThumbnailSavedInSandbox(_.rt.passage)
    async restoreThumbnailSavedInSandbox(passage: Passage) {
        if (!API.blockServerUpdates()) {
            return undefined
        }
        const sandboxThumbnails = JSON.parse(localStorage.getItem(`sandboxThumbnails`) || '{}')
        const savedThumbnail: PassageThumbnailVideo | undefined = sandboxThumbnails[passage._id]
        if (!savedThumbnail) {
            return undefined
        }
        const { _id: thumbnailId, url, srcVideoUrl, selectionStartTime, selectionEndTime, fileType, size } = savedThumbnail
        const thumbnail = createThumbnailVideo(
            passage.db, thumbnailId,
            { url, srcVideoUrl, selectionStartTime, selectionEndTime, fileType, size })
        return await passage.addThumbnailVideo(thumbnail)
    }

    // Pre #463 there was a bug which caused glossary videos to have a duration of 0. 
    // Fix duration of video.
    async fixVideoDuration(passageName: string, video: PassageVideo) {
        const { duration, url } = video
        if (duration > 0) return

        const blob = await VideoCache.getVideoDownload(url)
        const _duration = await getVideoDuration(blob)
        log(`===fixVideoDuration ${passageName} = ${_duration}`)
        await video.setDuration(_duration)
    }

    // _.fixGlossaryVideoDuration(_.rt.project).catch(console.log)
    async fixGlossaryVideoDuration(project: Project) {
        const glossary = project.portions.find(p => p.isGlossary)
        if (!glossary) return

        for (const video of project.passageVideos()) {
            if (!video._id.startsWith(glossary._id)) continue

            const passage = project.findPassage(video._id)
            await this.fixVideoDuration(passage?.name ?? '*unknown*', video)
        }
    }

    // _.fixGlossaryVideoDurationAllProjects().catch(console.log)
    async fixGlossaryVideoDurationAllProjects() {
        for (let rt of this.rts) {
            let { project } = rt
            if (project.name === '_log_') continue
            log(`===project ${project.name}`)

            await rt.initialize()

            await this.fixGlossaryVideoDuration(project)
        }
    }

    checkSegmentsEndPosition() {
        let { rt } = this
        let { project } =rt!
        let good = 0
        let bad = 0
        for (let portion of project.portions) {
            for (let passage of portion.passages) {
                for (let video of passage.videos) {
                    if (video.removed) continue
                    if (video.isPatch) continue

                    for (let i=0; i<video.segments.length-1; ++i) {
                        let segment = video.segments[i]
                        let nextSegment = video.segments[i+1]
                        if (segment.endPosition > nextSegment.position) {
                            log(`checkSegmentsEndPosition ${segment._id} ${segment.endPosition} > ${nextSegment.position}`)
                            log(segment.creationDate)
                            ++bad
                        } else {
                            ++good
                        }
                    }
                }
            }
        }

        log(`checkSegmentsEndPosition good=${good} bad=${bad}`)
    }
}

(window as any).AppRoot = AppRoot // access to setFeatureFlag() and featureFlag()
