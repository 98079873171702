import { t } from 'ttag'
import { throttle } from 'underscore'
import { fmt, s } from "../components/utils/Fmt"
import { ImageMetadata } from "../resources/ImageMetadata"
import { ProjectImages } from "../resources/ProjectImages"
import { IProjectEntity } from './ProjectModels'
import { logDocSyncError } from '../components/utils/Errors'
import { IRemoteDBItem, NonretriableSyncFailure } from './DBTypes'
import { getIsAppOnlineOrWait, delayUntilOnline as delayUntilOnlineAndLog } from '../components/utils/ServiceStatus'
import { delay } from '../components/utils/AsyncAwait'

// Library to perform non-PouchDB functions on the server
//     pushBlob - push a blob from a video being recorded in the browser
//     pushFile - push a file from the local machine to the server
//     concatBlobs - concatenate the pushed blobs, push this to S3
//     getUrl - get a signed url to access a S3 video

// The following URLs are generated when you run 'yarn deploy-dev' or
// 'yarn deploy-prd' in the sltt/serverless directory.

// OLD dev end point using full UBS account
//const DEVHOSTURL = 'https://o1xiq9zo17.execute-api.us-east-1.amazonaws.com/dev'
// const PRDHOSTURL = 'https://feobmstelh.execute-api.us-east-1.amazonaws.com/prd' // 1.5.x and before
const PRDHOSTURL = 'https://1n12wsmx6c.execute-api.us-east-1.amazonaws.com/prd' // 1.6.x and later

// NEW NEW dev endpoint using ubs AWS account
const DEVHOSTURL = 'https://s6j38el1bj.execute-api.us-east-1.amazonaws.com/dev'

// This accesses the version of the backend code that is deployed by
// yarn deploy-functions-tst.
// WARNING: This accesses the production dynamodb table and video hosting bucket.
const TSTHOSTURL = 'https://ac80fivcnd.execute-api.us-east-1.amazonaws.com/tst'

const TSTDBNOTIFICATIONURL = 'wss://8w27f9bow5.execute-api.us-east-1.amazonaws.com/tst'
// const DEVDBNOTIFICATIONURL = 'wss://0jimce41ic.execute-api.us-east-1.amazonaws.com/dev'
//const PRDDBNOTIFICATIONURL = 'wss://27ns3ht9lh.execute-api.us-east-1.amazonaws.com/prd' // 1.5.x and before
const PRDDBNOTIFICATIONURL = 'wss://68s98nnv37.execute-api.us-east-1.amazonaws.com/prd' // 1.6.x and later

let log = require('debug')('sltt:API')

const intest = (localStorage.getItem('intest') === 'true')

const _window = window as any
const apiCalls: any[] = []
_window._apiCalls_ = apiCalls
 
interface ILastEvaluatedKeyFromProjectsTable {
    project: string
}

// (adapted from avtt)
export interface IAuthorizedProjects {
    iAmRoot: boolean
    projects: IProjectEntity[],
    lastEvaluatedKey: ILastEvaluatedKeyFromProjectsTable | undefined
}

const getBlockServerUpdatesConfig = () => {
    // (REQUIRES REFRESH after change) When running from local host, must be set to access -prd server for reads.
    // Otherwise -dev server is accessed
    const usePrdServer = localStorage.getItem('usePrdServer')

    // (REQUIRES REFRESH after change) when set will allow updates to production server EVEN when
    // running from localhost
    const updatePrdServer = localStorage.getItem('updatePrdServer')

    // (REQUIRES REFRESH after change) When set non-empty, blocks any changes to databases.
    // Only the in-memory model is allowed to change.
    const useSandbox = localStorage.getItem('useSandbox')
    // make it simple to block or don't block server updates:
    // localStorage.blockServerUpdates = 'true' or 'false'
    // (undefined (or not 'true' or 'false') falls back to usePrdServer, updatePrdServer, useSandbox)
    // NOTE: truthy localStorage.acceptLocalThruKey sets this to true
    // DOES NOT REQUIRE REFRESH AFTER CHANGE
    const blockServerUpdates: 'true' | 'false' | undefined = localStorage.acceptLocalThruKey && 'true' || localStorage.blockServerUpdates || undefined
    return { blockServerUpdates, usePrdServer, updatePrdServer, useSandbox }
}

const { usePrdServer, updatePrdServer, useSandbox } = getBlockServerUpdatesConfig()

export const getBlockServerUpdatesInfo = (showLogs: boolean) => {
    const { blockServerUpdates } = getBlockServerUpdatesConfig()
    return ({
        willBlockServerUpdates: API.blockServerUpdates(showLogs),
        localStorage: { blockServerUpdates, usePrdServer, updatePrdServer, useSandbox }
    })
}

const throttledLog = throttle((message: string, args?: any) => { args ? log(message, args) : log(message) }, 2000)

/** Retry fetch call with an exponential backoff until it gets a response from server.
@param maxLengthMs Abort after specified ms
 * NOTE: the semantics of maxLengthMs is ambiguigous, since it's primary intention is
 * to abort a fetch that is taking too long. 
 * However, in the case that fetch encountered an error, we are also re-purposing it to be
 * the time we are allowing for the retries to succeed, but we still give fetch a chance
 * to have the full maxLengthMs in case it does connect without an error
*/
export async function retriableFetch(path: string, options: any, maxLengthMs?: number) {
    let gap = 1000
    log(`retriableFetch: path(${path}) maxLength(${maxLengthMs})`)
    let timeStarted = Date.now()
    while (true) {
        // NOTE: if `signal.aborted` happens outside of fetch (e.g. whenever fetch throws an error)
        // it not abort subsequent fetches unless we create AbortController again
        const abortController = new AbortController()
        const timer = maxLengthMs !== undefined && setTimeout(() => abortController.abort(), maxLengthMs)
        const { signal } = abortController
        try {
            if (API.blockServerUpdates()) {
                if (path.includes('/sync')) {
                    const body = JSON.parse(options.body)
                    const { docs } = body
                    if (docs.length > 0) {
                        throw new Error(`/sync not allowed with ${docs.length} doc(s) (e.g. _id: ${docs[0]?._id})...: API.blockServerUpdates() is enabled`)
                    } else {
                        throttledLog(`retriableFetch: API.blockServerUpdates() is enabled, but continuing (down) /sync...`)
                    }
                } else {
                    // we block requests for /sync, but hopefully safe for other fetches, like for s3urls
                    // which could allow uploading and downloading of files without affecting others
                    // this can be helpful when trying to reproduce upload/download bugs for projects 
                    // without affecting their database
                    throttledLog(`retriableFetch: API.blockServerUpdates() is enabled, but continuing /${path?.split('/').slice(-1)[0]}...`)
                }
            }
            const response = await fetch(path, { ...options, signal })

            if (response.status === 404) {
                log(`Not a member of project (404)`)
                // Remove id_token so that user will be prompted to login again.
                // This is monitored in Login.tsx.
                // This happens when the admin edits the user's email address to a new address.
                // The backend then reports that the current email address is not a member of the project.
                // We clear the id_token so that the user will be prompted to login again.
                // The code that logs out the user when this happens is in Login.tsx.
                API.id_token = ''
            }

            return response
        } catch (error) { 
            if ((error instanceof TypeError) && error.message === FAILED_TO_FETCH_MSG) {
                throw error // offline...no point in retrying
            }
            if ((error instanceof Error) && error.name === 'AbortError') {
                throwExpirationMsg()
            }
            log('Network Error', error)
            /* retriable network error */ 
        } finally {
            timer && clearTimeout(timer)
        }

        // add delay, but only if it will not use up the rest of the max time
        const timeElapsed = Date.now() - timeStarted
        const timeElapsedAfterNextDelay = (timeElapsed + gap)
        maxLengthMs && log(`retriableFetch: maxLengthMs(${maxLengthMs}) timeElapsed(${timeElapsed}) timeElapsedAfterNextDelay(${timeElapsedAfterNextDelay})`)
        if (maxLengthMs && timeElapsedAfterNextDelay >= maxLengthMs) {
            // anticipating abort, so throw early
            log(`timeElapsedAfterNextDelay(${timeElapsedAfterNextDelay}) will exceed maxLengthMs(${maxLengthMs}), so aborting retries...`)
            throwExpirationMsg()
        }
        log(`retriableFetch: delay ${gap}...`)
        await delay(gap)
        gap = gap <= 8000 ? 2*gap : gap
    }
}

const SIGN_OUT_USER = 'SIGN OUT USER'

export function isNeedingLogout(error: unknown) {
    return (error instanceof Error) && error.message === SIGN_OUT_USER
}

const FETCH_EXPIRATION_MSG = 'Fetch did not succeed after specified time'
const FAILED_TO_FETCH_MSG = 'Failed to fetch'

export function isHavingConnectionIssues(error: unknown) {
    return (error instanceof Error) && [FAILED_TO_FETCH_MSG, FETCH_EXPIRATION_MSG].includes(error.message)
}

function throwExpirationMsg() {
    throw new Error(FETCH_EXPIRATION_MSG)
}

async function putBlob(url: string, blob: Blob) {
    // if blob has been gotten from disk, it can have an empty blob.type ('')
    // so get the content type from the signed url instead.
    // failing to do this may result in an 403 error from s3:
    // "The request signature we calculated does not match the signature you provided. Check your key and signing method."
    const contentTypeFromUrl = decodeURIComponent(url).split('&').filter(p => p.startsWith('Content-Type=')).map(p => p.split('=')[1])[0]
    !blob.type && log(`putBlob: empty blob.type('${blob.type}') contentTypeFromUrl(${contentTypeFromUrl}) from url(${decodeURIComponent(url)})`)
    let options = {
        method: 'PUT',
        headers: { 'Content-Type': blob.type || contentTypeFromUrl },
        body: blob,
    }

    let response = await retriableFetch(url, options)

    if (response.status !== 200) throw Error(`${response.status} - ${response.statusText}`)
}

async function getBlob(url: string) {
    let options = { method: 'GET' }

    let response = await retriableFetch(url, options)
    if (response.status !== 200) throw Error(`${response.status} - ${response.statusText}`)

    return await response.blob()
}

async function getUrls(path: string, type: string, startIndex: number, endIndex: number, upload: boolean, maxLengthMs?: number) {
    // when testing return array of unsigned urls so that we don't have to go out to backend
    if (intest) {
        return [path]
    }

    let _path = `${API.getHostUrl()}/s3urls?info=${encodeURIComponent(path)},${upload}`
    let body = { _id: path, type, startIndex, endIndex, upload }

    let response = await retriableFetch(_path, API.getOptions('PUT', body), maxLengthMs)
    let json = await response.json()

    let urls: string[] = json.urls
    return urls
}

// Initiate multipart upload. Returns signed upload urls for each part and an upload id.
export async function createmulti(_id: string, type: string, endIndex: number, upload: boolean) {
    let _path = `${API.getHostUrl()}/createmulti`
    let body = { _id, type, endIndex, upload }

    let response = await retriableFetch(_path, API.getOptions('PUT', body))
    let json = await response.json()

    let urls: string[] = json.urls
    let uploadId = json.uploadId
    return { urls, uploadId}
}

interface MultipartPart {
    ETag: string,
    PartNumber: number,
}

// Complete multipart S3 upload and release temporary files.
export async function completemulti(_id: string, uploadId: string, parts: MultipartPart[], viewerUrl: string) {
    let _path = `${API.getHostUrl()}/completemulti`
    let body = { _id, uploadId, parts, viewerUrl }
    log('completemulti', fmt(body))

    let response = await retriableFetch(_path, API.getOptions('PUT', body))
    if (response.status !== 200) {
        throw Error(`${response.status} - ${response.statusText}`)
    }

    let json = await response.json()
    let shortUrl: string = json.shortUrl

    log(`completemulti DONE`, s(json))

    return shortUrl
}

function typeFromPath(path: string) {
    // can we treat everything as generic?
    return 'application/octet-stream'
    
    // path = path.toLocaleLowerCase()
    // if (path.endsWith('.mp4')) return 'video/mp4'
    // if (path.endsWith('.webm')) return 'video/webm'

    // throw Error(`Invalid type for path [${path}]`)
}

export default class API {
    static id_token: string

    // Allow forcing 500 status code errors for testing
    //    API.forceUnretriableErrorsCount = 3 // in console
    static forceUnretriableErrorsCount = 0

    /** 
     * blockServerUpdates() should (mostly) be called blockMyDbUpdates():
     * Main purpose of API.blockServerUpdates() is to prevent user actions
     * from writing to the project's indexed db. (IOW, if we prevent local database
     * changes, then /sync won't send changes to central remote database.)
     * EXCEPTION: we do allow /sync to be called with no docs, so that we can
     * receive latest changes from the central remote database. 
     * `true` if we do not want to allow app to update local (or server) db.
     * `true` means that 
     * 1) no other users will see our db changes
     * 2) all our db changes will be lost on refresh.
     * */ 
    static blockServerUpdates(showLog = false) {
        const { blockServerUpdates,
            usePrdServer: currentUsePrdServer,
            updatePrdServer: currentUpdatePrdServer,
            useSandbox: currentUseSandbox
        } = getBlockServerUpdatesConfig()
        showLog && throttledLog('blockServerUpdates...localStorage: ', {
            blockServerUpdates, usePrdServer, updatePrdServer, useSandbox
        })
        if (blockServerUpdates && ['true', 'false'].includes(blockServerUpdates)) {
            throttledLog(`blockServerUpdates (localStorage.blockServerUpdates '${localStorage.blockServerUpdates}' | acceptLocalThruKey '${localStorage.acceptLocalThruKey}'): ${blockServerUpdates}`)
            return blockServerUpdates === 'true' // otherwise 'false'
        }
        if (currentUsePrdServer !== usePrdServer ||
            currentUpdatePrdServer !== updatePrdServer ||
            currentUseSandbox !== useSandbox) {
            throttledLog('blockServerUpdates...localStorage settings changed: Refresh browser to take effect:', [
                [{ usePrdServer, currentUsePrdServer }],
                [{ updatePrdServer, currentUpdatePrdServer }], 
                [{ useSandbox, currentUseSandbox }],
            ])
        }
        // When usePrdServer is set we default to no updates.
        // You must explicitly set updatePrdServer to allow updates.
        if (usePrdServer) {
            if (updatePrdServer) {
                showLog && throttledLog('blockServerUpdates (usePrdServer && updatePrdServer): false')
                return false
            } else {
                throttledLog('blockServerUpdates (usePrdServer && !updatePrdServer): true ')
                return true
            }
        }
        
        // When usePrdServer not set, i.e. using -dev server,
        // we allow updates by default but this may be stopped by setting useSandbox
        (useSandbox || showLog) && throttledLog(`blockServerUpdates: ${!!useSandbox} (!usePrdServer && ${useSandbox ? '' : '!'}useSandbox)`)
        return useSandbox ? true : false
    }

    // Determine url to access backend server
    static getHostUrl() {
        let { PUBLIC_URL } = process.env 
        PUBLIC_URL = PUBLIC_URL || 'localhost'

        log(`PUBLIC_URL [${PUBLIC_URL}]`)
        const isTesting = PUBLIC_URL.includes('tst.') || PUBLIC_URL.includes('localhost')
        if (isTesting && process.env.REACT_APP_BACKEND_LOCALHOST_PORT) {
            return `http://localhost:${process.env.REACT_APP_BACKEND_LOCALHOST_PORT}`
        }
        return isTesting ? TSTHOSTURL : DEVHOSTURL
        
        // if (PUBLIC_URL) {
        //     // When hosting from S3 we always access production back end.
        //     return PRDHOSTURL
        // } else {
        //     // PUBLIC_URL not set, running the app from localhost.
        //     // By default we access the developement backend.
        //     // Override this via console: localStorage.usePrdServer = 'yes'
        //     // When accessing production server from localhost blockServerUpdates
        //     // will prevents us from writing any changes to the production server.
        //     return usePrdServer ? PRDHOSTURL : DEVHOSTURL
        // }
    }

    static getDBNotificationUrl() {
        // Always use PRD (otherwise people on the prd client will not see dev notifications
        // and vice versa)
        return PRDDBNOTIFICATIONURL
        
        // let { PUBLIC_URL } = process.env

        // if (PUBLIC_URL) {
        //     return PRDDBNOTIFICATIONURL
        // } else {
        //     return usePrdServer ? PRDDBNOTIFICATIONURL : DEVDBNOTIFICATIONURL
        // }
    }

    static authorization() {
        return 'Bearer ' + API.id_token
    }

    /**
     * Request a registration for the user with the named email.
     * 
     * @param email - user email
     *      *email - dont email result to users, root can see result in console
     *      -email - generate link for localhost:300
     *      -*email - both
     *          
     * @param includeAuth 
     *    root can use when invoke from console
     *      window.API.register('-*wtyas@hotmail.com', true).catch(console.log)
     */
    static async register(email: string, includeAuth?: boolean) {
        let body = { email }
        let _path = `${API.getHostUrl()}/register`

        let options: any = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        }

        if (includeAuth) {
            options.headers.Authorization = 'Bearer ' + API.id_token
        }

        let response = await retriableFetch(_path, options)
        let json = await response.json()
        log('register', json && json.url)
    }

    static async pushBlob(projectName: string, path: string, seqNum: number, blob: Blob) {
        if (intest) {
            apiCalls.push('/pushBlob')
            return
        }

        //! eventually we are probably going to want to retrieve multiple urls
        // with a single call and cache them to avoid upload delays

        let urls = await getUrls(path, blob.type, seqNum, seqNum, true)
        await putBlob(urls[0], blob)
    }

    // Pass a url for an S3 video file object to server.
    // Get back a singed url that allows downloading the object.
    // This url will expire in 6 days.
    // Path = s3-bucket-name/key, e.g. sltt-video/TESTnm/xyz.json
    // Upload = true, in order to generate a url that allows PUT

    static async getUrl(path: string, upload: boolean = false, maxLengthMs?: number) {
        // I am NOT supplying an 'intest' dummy version for this api.
        // In order to use these signed urls they must be dynamically generated because
        // they expire after a 24ish hour period.

        upload = upload ?? false // default upload to false if not specified

        let urls = await getUrls(path, typeFromPath(path), 0, 0, upload, maxLengthMs)

        return urls[0]
    }

    // getUrl above, but return [error, signedUrl] and never throw
    static async _getUrl(path: string, upload?: boolean, maxLengthMs?: number): Promise< [Error|null, string]> {
        try {
            return [null, await API.getUrl(path, upload, maxLengthMs)]
        } catch (error) {
            return [error as any, '']
        }
    }

    static async _getBlob(url: string): Promise<[Error | null, Blob]> {
        try {
            return [null, await getBlob(url)]
        } catch (error) {
            return [error as any, new Blob()]
        }
    }

    // static async getIotUrl(projectName: string) {
    //     let _path = `${API.getHostUrl()}/iotUrl`
    //     let body = {
    //         _id: projectName
    //     }

    //     let response = await retriableFetch(_path, API.getOptions('PUT', body))
    //     let { url } = await response.json()
    //     log('getIotUrl', url)

    //     return url
    // }   

    static async sync(project: string, docs: any[], maxseq: number, dbId = 'xxxx'): Promise<IRemoteDBItem[]> {
        if (docs.length) {
            log(`sync`, dbId, project, maxseq, JSON.stringify(docs, null, 4))
        }
        
        let _path = `${API.getHostUrl()}/sync?info=${dbId},${encodeURIComponent(project)},${maxseq},${docs.length}` // info is for logging
        let body = { project, docs, maxseq }

        // When debugging force 500 error when we try to sync any new docs
        if (this.forceUnretriableErrorsCount > 0 && docs.length) {
            log('!!! force unretriable error', this.forceUnretriableErrorsCount)
            this.forceUnretriableErrorsCount--
            (body as any).docs = undefined
        }

        let response = await retriableFetch(_path, API.getOptions('PUT', body), 30000)

        if (response.status >= 300) { // any non-success status
            log('sync failed status=', response.status)
            const message = await response.text()
            if (response.status === 404 || message.includes('TokenExpiredError: jwt expired')) {
                // if id_token expiration timer got delayed (due to system in sleep)
                // we can get this error "/sync ERROR: TokenExpiredError: jwt expired"
                // in that case clear the id_token so the user will be forced to login again
                // Technically we could/should handle this same error for all API.retriableFetch callers
                // but it involves reading the response.text() which can only be done once
                // so we just handle it here because /sync is called relatively frequently
                if (API.id_token) {
                    API.id_token = '' // sign-out user
                } else {
                    // race condition?
                }
                // the next time sync is called, sync will not process
                throw Error(SIGN_OUT_USER)
            } else {
                logDocSyncError(new Error(message), docs, project, dbId)
                // Retriable errors have already been retried by retriableFetch(...) above.
                // If we still have an error, throw an error that will be treated as "Wait a few minutes before trying again."
                // This will stop us from flooding the backend (and the log) with a zillion retries when there is a problem.
                throw Error(`${NonretriableSyncFailure}: ${message}`)
            }
        }

        let json = await response.json()

        // log(`sync success`, JSON.stringify(json, null, 4))

        return json.map((j: any) => ({
            project: j.project, 
            seq: j.seq, 
            doc: j.doc,
        }))
    }

    static async createProject(projectName: string) {
        log(`createProject ${projectName}`)

        let _path = `${API.getHostUrl()}/createproject`
        let body = { project: projectName }

        await retriableFetch(_path, API.getOptions('PUT', body))

        log(`createProject success`)
    }

    static async deleteProject(projectName: string) {
        log(`deleteProject ${projectName}`)

        let _path = `${API.getHostUrl()}/deleteproject`
        let body = { project: projectName }

        await retriableFetch(_path, API.getOptions('PUT', body))

        log(`deleteProject success`)
    }

    // Query to get a list of the projects this user is authorized to access.
    // Query also tells us if this is a root user.

    static async getAuthorizedProjects() {
        log(`getAuthorizedProjects`)

        const _path = `${API.getHostUrl()}/projects`
        let authorizedProjects: IAuthorizedProjects = { 
            iAmRoot: false, projects: [], lastEvaluatedKey: undefined
        }
        // fetch from /projects until its lastEvaluatedKey becomes falsy
        // NOTE: older versions of the server never includes lastEvaluatedKey
        // so we can only do one request
        let lastEvaluatedKey: ILastEvaluatedKeyFromProjectsTable | undefined = undefined
        try {
            if (!await getIsAppOnlineOrWait()) {
                throw Error('Offline')
            }
            let query = '' // schema: lastKey=_test2&pageSize=50&maxPages=1&
            do {
                const pagedResult = await retriableFetch(`${_path}?${query}`, API.getOptions('GET'), 30000)
                const pagedAuthorizedProjects = await pagedResult.json() as IAuthorizedProjects
                const { iAmRoot, projects: pagedProjects } = pagedAuthorizedProjects
                lastEvaluatedKey = pagedAuthorizedProjects.lastEvaluatedKey
                authorizedProjects.iAmRoot = iAmRoot || authorizedProjects.iAmRoot // robustness
                authorizedProjects.projects.push(...pagedProjects) // append
                // lastKey will be used for ExclusiveStartKey
                query = `lastKey=${lastEvaluatedKey?.project}`
            } while (lastEvaluatedKey)
            localStorage.setItem('projects', JSON.stringify(authorizedProjects))
            log(`getAuthorizedProjects success`)
        } catch (error) {
            log('could not fetch projects list from server', error)
            if (lastEvaluatedKey) {
                log('using partially gotten project list', authorizedProjects)
            } else {
                let p = localStorage.getItem('projects')
                if (p) {
                    authorizedProjects = JSON.parse(p)
                    log('fall back to previous project list', p)
                }
            }
        }

        return authorizedProjects
    }

    static loggedIn() {
        return API.id_token ? true : false
    }

    static getOptions(method: string, body?: any) {
        if (!API.id_token) {
            throw Error('API call failed, not logged in.')
        }

        let options: any = {
            method: method || 'GET',
            headers: { Authorization: 'Bearer ' + API.id_token },
        }

        if (body) {
            options.headers['Content-Type'] = 'application/json'
            options.body = JSON.stringify(body)
        }

        return options
    }

    static loginTestUser(testJWT: string) {
        API.id_token = testJWT
    }


    static async putProjectImageMetadata(info: any) {
        let url = `${API.getHostUrl()}/images`

        let body = {
            image: info
        }

        let options = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + API.id_token
            },
            body: JSON.stringify(body),
        }

        let response = await fetch(url, options)
        if (!response.ok) {
            let message = await response.text()
            throw Error(`${response.url}: ${message}`)
        }
    }

    static async serverIsReachable() {
        const url = `${API.getHostUrl()}/version`
        const options = { method: 'GET' }

        try {
            const response = await fetch(url, options)
            return response.ok
            
        } catch (error) {
            return false
        }
    }

    /** Delete an image.
    * @throws Throws if non-200 HTTP response
    */
    static async deleteProjectImageMetadata(image: ImageMetadata) {
        let url = `${API.getHostUrl()}/images`
        let { project, fileName } = image
        let body = { project, fileName }
        let options = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + API.id_token
            },
            body: JSON.stringify(body)
        }
        let response = await fetch(url, options)
        if (!response.ok) {
            let message = await response.text()
            throw Error(`${response.url}: ${message}`)
        }

        // We don't delete the image from S3, because it takes up very little space,
        // and is thus is not a pressing need.
    }

    /**
     * Queue a notification concerning a change in a project.
     * Examples: a new video is created, a new note item is created.
     * Backend will periodically bundle all the the notifications for a given email
     * address and send them.
     * Within an email the changes are grouped by project.
     * 
     *     project - name of the project which changed 
     *     emails - array of email addresses to receive this notification
     *     notificationDate - date/time at which notification should be sent
     *         Must be ISO format. Must be UTC (not local) time.
     *     message: Change message in html. 
     * 
     * To invoke from browser console for testing:
     *     API.addNotification("XYZ", ["nmiles@biblesocieties.org"], (new Date()).toISOString(), "<div>Hello!</div>").catch(console.log)
     */
    static async addNotification(project: string, emails: string[], notificationDate: string, message: string) {
        const creationDate = (new Date()).toISOString()

        if (creationDate.length !== notificationDate.length) throw Error(`${notificationDate} is not an ISO date`)

        const _path = `${API.getHostUrl()}/addNotification`
        const body = { project, emails: emails.join(' '), creationDate, notificationDate, message }

        await retriableFetch(_path, API.getOptions('PUT', body), 30000)
    }
    
    /**
     * Force backend to examine notifications queue and email any notifications
     * for which notification date and time <= current date and time.
     * This is not normally necessary in production code because the backend
     * does this automatically on an hourly basis.
     * It may be useful when debugging to allow you to send the emails immediately.
     * 
     * To invoke from browser console:
     *     API.sendNotifications().catch(console.log)
     */
    static async sendNotifications() {
        const _path = `${API.getHostUrl()}/sendNotifications`
        const body = { }

        await retriableFetch(_path, API.getOptions('PUT', body), 30000)
    }

}

export function getOrigin() {
    return process.env.PUBLIC_URL || 'http://localhost:3000'
}

/** 
 * Will not resolve until browser is online
 */
export async function delayUntilOnline(logContext: string) {
    await delayUntilOnlineAndLog(logContext)
}


// allow console.log access to API
_window.API = API
