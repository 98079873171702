import debug from 'debug'
import { observable } from 'mobx'
import { fmt } from './Fmt'
import { throttle } from 'underscore'

const log = debug('sltt:LocalStorage')


export const featureFlagKey = 'featureFlags'

const throttledLog = throttle((message: string, args?: any) => { args ? log(message, args) : log(message) }, 30000)

export function featureFlag(flag: string) {
    let flags = (localStorage.getItem(featureFlagKey) ?? '').split('/')
    throttledLog('featureFlag', fmt({ flags }))
    return flags.includes(flag)
}

if (!window.matchMedia) {
    (window as any).matchMedia = () => false
}

// TODO: create useTheme()
export class Theme {

    static defaultTheme = window.matchMedia("(prefers-color-scheme: dark)") ? 'dark' : 'light'
    @observable static theme = ''

    static setupTheme() {
        Theme.setTheme(localStorage.theme || Theme.defaultTheme)
        log('setupTheme', Theme.theme, { localStorageTheme: localStorage.theme, defaultTheme: Theme.defaultTheme })
    }

    static setTheme(theme: string) {
        Theme.theme = theme || Theme.defaultTheme
        localStorage.theme = theme
        document.documentElement.setAttribute("data-theme", theme)
        log('setTheme', Theme.theme, { theme, localStorageTheme: localStorage.theme })
    }

    static hasThemeSetting = () => 'theme' in localStorage

    static toggleTheme() {
        const currentTheme = localStorage.theme || Theme.theme
        const newTheme = currentTheme === 'light' ? 'dark' : 'light'
        Theme.setTheme(newTheme)
        log('toggleTheme', Theme.theme, { currentTheme, newTheme })
    }

    static getColor(colorWhenLight: string, colorWhenDark: string) {
        // log('getColor', Theme.theme, { colorWhenLight, colorWhenDark })
        return Theme.theme === 'light' ? colorWhenLight : colorWhenDark
    }

    static currentTheme() {
        return Theme.theme
    }
}

Theme.setupTheme() // do once
