import { stringify as safeStableStringify } from 'safe-stable-stringify'
import { VideoCacheRecord } from './VideoCacheRecord';
import { _LevelupDB } from './_LevelupDB'
import { IVideoDownloadQuery } from './VideoCacheDownloader';
import { observable, computed } from 'mobx'
import _ from 'underscore'
import Par from 'parsimmon'
import { t } from 'ttag'
import { CanvasPath } from "react-sketch-canvas"

import { DBAcceptor } from './DBAcceptor'
import { RefRange } from '../scrRefs/RefRange'
import { newerThanCutoffDate, IDateFormatter } from './DateUtilities'
import { getVideoDuration } from './VideoDuration'
import { englishBookNames } from '../scrRefs/bookNames'
import { allowedImageTypes, isAllowedImage } from '../components/images/AllowedImages'
import { fmt, s } from '../components/utils/Fmt'
import { LexMeaning, MarbleLemma, MarbleLemmas } from '../scrRefs/Lemmas'
import { ApiDotBible } from './ApiDotBible'
import { normalizeUsername } from './DBAcceptor'
import { VideoCache } from './VideoCache'
import { GlossTextSearchParameters } from './RootBase'
import { ViewableVideoCollection } from '../components/video/ViewableVideoCollection';
import { NoteSelector } from '../components/notes/NoteSelector'
import { IRoot } from './Root';
import { validateIso639dash1LanguageCode } from '../components/utils/Languages';
import { TRLLicense } from './TRLModel';
import { IDB, IDBAcceptor, IDBModDoc, IDBObject } from './DBTypes';
import { getTitleOrDefault } from '../components/TRL/trlUtils'
import { getIsAppOnlineOrWait, doOnceWhenBackOnline } from '../components/utils/ServiceStatus'
import { delay } from '../components/utils/AsyncAwait'
import { sendBeacon } from '../components/utils/Errors'
import { stringifyCreationHistoryForBeacon } from '../components/utils/PassageDocument'

const log = require('debug')('sltt:Models')

const intest = (localStorage.getItem('intest') === 'true')

/*
 * ** Important Note from Nathan Miles **
 * When you add a doc._id with a new form not previously accepted
 *  OR you set a value in a field that would break a previous version
 *     of the acceptor
 * THEN
 * You must increment this line ProjectModels.ts
 * export const DB_ACCEPTOR_VERSION = (NN + 1)
 * You must include 'model: {NN + 1}' when setting the id or value.
 * This will cause older versions of the acceptor to ignore this change.
 * This is probably a reason to add new _id formats very rarely.
 */
export const DB_ACCEPTOR_VERSION = 14

log(ApiDotBible.versions)

/*
Project
    Member
    Portion
        Passage
            PassageVideo
                PassageSegment
                    PassageSegmentLabel
                    PassageSegmentGloss
                PassageNote
                    PassageNoteItem
                PassageGloss

*/



// Stub DB which throws if accessed
export class StubDB implements IDB {
    // Not used by StubDB but required to implement IDB
    localSeqLast = 1000000000
    localSeqBase = 1000000000

    initialize = (acceptor: IDBAcceptor): Promise<number> => { throw Error('not implimented')}
    put = (obj: IDBObject): Promise<void> => { throw Error('not implemented') }
    get = (seqStart?: number | undefined, seqEnd?: number | undefined): Promise<IDBModDoc[]> => {
        throw new Error("Method not implemented.")
    }
    doSync = () => {}
    delete = (_id: string, _lastId?: string | undefined): Promise<void> => { throw Error('not implemented') }
    deleteDB = async () => { }
    getRemoteSeq = () => { return 0 }
    getNewId = (existing: any[], date: Date, tag?: string | undefined): string => { throw Error('not implemented') }
    username: string = ''
    getDate = (): string => '0001-01-01 01:01:01.001Z'
    slice = (): any[] => { throw Error('not implemented') }
    reset = (nextId: number): void => { throw Error('not implemented') }
    submitChange = (obj: IDBObject) => { throw Error('not implemented') }
    disconnect = () => {}
}

export const DEFAULT_RANK = 'xxxxxxxxxxxxxxxx'

export abstract class DBObject {
    _id: string
    db: IDB
    rank: string = DEFAULT_RANK
    @observable removed = false
    creator: string
    modBy: string = '' // set by doc.modBy in DBAcceptor
    creationDate: string // '0000-00-00 hh:mm'

    constructor(_id: string, db?: IDB) {
        // If not db supplied, create a stub. It will throw if accessed.
        db = db ?? new StubDB()

        this._id = _id
        this.db = db
        this.creator = normalizeUsername(db.username)

        this.creationDate = db.getDate()
    }

    toSnapshot = () => {
        return createSnapshot(this)
    }

    _toDocument(init: any) {
        let { _id, creator, creationDate } = this
        let doc: any = { _id, creator, creationDate }
        doc.modDate = this.db.getDate()
        doc.modBy = normalizeUsername(this.db.username)
        Object.assign(doc, init)
        return doc
    }

    setUMTRank() {
        // Create a rank based on UMT time
        let iso = (new Date(Date.now())).toISOString()
        iso = iso.replace('-', '/')
        iso = iso.replace('-', '/') // replace second occurence of -
        iso = iso.replace('T', ' ')
        this.rank = iso  // format: 2020/07/30 17:15
    }

    // Return the rank as a number.
    // If the rank is not a valid number default to 1 so that
    // later calculations return a valid value.
    get rankAsNumber() {
        let rank = parseFloat(this.rank)
        if (isNaN(rank)) return 1
        return rank
    }

    static numberToRank(rank: number): string {
        // Create string form of number that correctly sorts by numeric value
        return rank.toFixed(4).padStart(16, '0')
    }
}

function createSnapshot(obj: any) {
    const stringified = safeStableStringify(obj, (key, value) => {
        // db and dbAcceptor are not needed for persistance and can cause circular references
        // _rev is not needed for persistance. it's only for DbAcceptor to refresh react components
        if (['db', '_rev', 'dbAcceptor'].includes(key)) return undefined
        return value
    })
    return stringified && JSON.parse(stringified)
}

// Should only be called when the object containing the list is not being persisted!
function insertByRank(items: PassageNote[], item: PassageNote) {
    log(`insertByRank ${item._id}`)

    // Don't insert duplicates
    if (items.some(_item => _item._id === item._id)) {
        // This can happen if the roundtrip update from the DB put has already
        // inserted the item.
        log(`insertByRank item already present`)
        return
    }

    let i = items.length - 1
    while (i > 0 && items[i].rank > item.rank) { i = i - 1 }

    items.splice(i+1, 0, item)
}

// use the index position of each item to recreate ranks
async function resetItemRanksByPosition(items: IMoveableItem[]) {
    for (let i = 0; i < items.length; ++i) {
        await items[i].setRank(100 * (i + 1))
    }
}

// returns true if all the items are ordered by rank
// items should have unique ranks and no rank should be DEFAULT_RANK
function checkRanksAreAllInSequence(items: IMoveableItem[]) {
    const itemWithDefaultRank = items.find(item => item.rank === DEFAULT_RANK)
    if (itemWithDefaultRank) {
        log('checkRanksAreAllInSequence: found item with default rank', { itemWithDefaultRank })
        return false
    }

    if (items.length <= 1) return true // for robustness
    // next check to see if any rank is not greater than the previous one
    let prevRank = items[0].rankAsNumber
    for (let i = 1; i < items.length; ++i, prevRank = items[i - 1].rankAsNumber) {
        const currentRank = items[i].rankAsNumber
        if (currentRank <= prevRank) {
            log('checkRanksAreAllInSequence: found issue', { prevRank, currentRank, prevItem: items[i - 1], item: [i] })
            return false
        }
    }
    return true
}

export async function ensureRanksAreInOrder(items: IMoveableItem[]) {
    if (!checkRanksAreAllInSequence(items)) {
        // some ranks are not right, so correct them
        await resetItemRanksByPosition(items)
    }
}

interface IMoveableItem {
    rank: string
    rankAsNumber: number
    setRank: (rankAsNumber: number) => void
}

async function move(
    items: IMoveableItem[],
    oldIndex: number,
    newIndex: number
) {
    if (newIndex === oldIndex) return
    if (items.length <= 1) return

    await ensureRanksAreInOrder(items)

    let rank: number

    // Assign a rank to an item to cause it to order at the desired index
    if (newIndex === 0) {
        // Insert at beginning
        rank = items[0].rankAsNumber / 2
    } else if (newIndex === items.length-1) {
        // Insert at end
        rank = items[items.length-1].rankAsNumber + 100
    } else {
        let i
        if (newIndex > oldIndex) {
            i = newIndex
        } else {
            i = newIndex - 1
        }

        // Insert between two items
        let prevRank = items[i].rankAsNumber
        let nextRank = items[i+1].rankAsNumber
        rank = (prevRank + nextRank) / 2
    }

    let item = items[oldIndex]
    log('move', { oldIndex, newIndex, item, items })
    await item.setRank(rank)
}

// NOTE: MoveableDBObject subclasses need corresponding DBAcceptor code that looks like:
//         accept(portion, doc, 'rank')
//  OR
//         if (doc.rank !== undefined && task.rank !== doc.rank) {
//           task.rank = doc.rank
//         }
abstract class MoveableDBObject extends DBObject implements IMoveableItem {
    @observable rank = ''

    constructor(_id: string, db: IDB) {
        super(_id, db)
    }

    async setRank(rankNumber: number) {
        const _rank = DBObject.numberToRank(rankNumber)
        const doc = this._toDocument({ rank: _rank })
        log('setRank', { rankNumber, rank: _rank, doc, item: this })
        await this.db.put(doc)
    }
}

async function remove(items: any[], _id: string) {
    let idx = _.findIndex(items, { _id })
    if (idx < 0) return

    let item = items[idx]

    let doc = item._toDocument({})
    doc.removed = true
    await item.db.put(doc)
}

interface IMemberDoc {
    email: string,
    role: string,
}

interface IMembersDoc {
    members: IMemberDoc[]
}

export function findMember(members: any[], email: string)  {
    let index = findMemberIndex(members, email)
    return index >= 0 ? members[index] : undefined
}

// Make sure that changes in case or leading/trailing space does not cause email
// match to fail.
export function findMemberIndex(members: any[], email: string) {
    email = normalizeUsername(email) // should not be necessary
    return _.findIndex(members, (m: IMemberDoc) => m.email === email)
}

export function validateUsername(username: string) {
    // verify that username is a valid email address
    const regexEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (!regexEmail.test(username)) {
       return t`Invalid email address`
    }
    
    return ''
}

export interface IVideoCacheAcceptor {
    // Queue video for download to video cache. See VideoCache.accept
    (_id: string, creationDate: string): Promise<void>
}

export interface ICopyFileToVideoCache {
    // Copies the file to VideoCache and returns the _id of the resulting video blob
    (file: File, baseUrl: string, creationDate?: string, requestUpload?: boolean): Promise<string>
}

type BookNameMap = {[bbb: string] : string}

export enum DateFormat {
    mmddyyyy = 'MM/dd/yyyy',
    ddmmyyyy = 'dd/MM/yyyy',
    mmmmddyyyy = 'MMMM dd, yyyy',
    yyyymmdd = 'yyyy-MM-dd',
}

export enum VideoTimeCodeFormat {
    timeFormatWithPeriod = 'mm:ss.zzz',
    timeFormatWithColon = 'mm:ss:zzz',
}

export class ProjectImage extends MoveableDBObject {
    src = ''

    constructor(_id: string, db: IDB) {
        super(_id, db)
    }

    toDocument() {
        let { src, rank } = this
        return this._toDocument({ src, rank, model: 4 })
    }
}

export class ProjectMessage extends DBObject {
    // S3 url for video (videoMessage=true) or text document
    @observable videoUrl = ''
    @observable text = ''

    // True if this is a sltt project wide message
    @observable globalMessage = false

    // True if this is a response to a globalMessage.
    // The sync command on the backend duplicates this to the global project
    // so that the webmaster can see it there.
    isGlobalResponse = false

    // Subject line for message
    @observable subject = ''

    // If this message is a response to another message,
    // the _id of the parent message. Otherwise ''.
    @observable parent = ''

    @observable responses: ProjectMessage[] = []

    // True if the user has viewed the content of this message
    // Persisted to localDB, Not persisted to DynamoDB
    @observable viewed = false

    // Seq field of local database record that generated this entry
    // Not persisted to DynamoDB
    seq: number | string = 0

    model = 11

    constructor(_id: string, db: IDB) {
        super(_id, db)
    }

    toDocument() {
        let { videoUrl, text, rank, globalMessage, isGlobalResponse, subject, parent, viewed,
            seq, model } = this
        return this._toDocument({
            videoUrl, text, rank, globalMessage, isGlobalResponse, subject, parent, viewed,
            seq, model })
    }

    static isGlobalProject(name: string) {
        return name === 'SLTT' || name === 'AVTT'
    }

    async setGlobalMessage(globalMessage: boolean) {
        if (globalMessage === this.globalMessage) {
            return
        }

        let doc = this._toDocument({ model: this.model, globalMessage })
        await this.db.put(doc)
    }

    dbg() {
        let doc = this.toDocument()
        doc.responses = this.responses.map(r => r.dbg())

        return doc
    }

    dbg2(): any {
        let {_id, creationDate, parent, text, videoUrl, viewed, subject} = this
        return {
            _id,
            // creationDate: creationDate.slice(0,19),
            parent,
            subject,
            text: text?.slice(0,50),
            videoUrl,
            viewed,
            responses: this.responses.map(r => r.dbg2()),
        }
    }

    displayedCreationDate(dateFormatter: IDateFormatter) {
        let date = new Date(this.creationDate)
        return dateFormatter.format(date)
    }

    async setViewed(viewed: boolean) {
        for (let response of this.responses) {
            await response.setViewed(viewed)
        }

        if (this.viewed === viewed) return
        log('setViewed', this._id, viewed, this.seq)

        let _db = this.db as _LevelupDB
        let doc = this.toDocument()
        doc.viewed = viewed
        await _db.db.put(this.seq, doc)

        this.viewed = viewed
    }

    /**
     * True iff this message or any of its responses are unviewed
     */
    anyUnviewed(username: string) {
        if (!this.viewed && this.creator !== username) return true

        if (this.responses.some(r => !r.viewed &&  r.creator !== username)) {
            return true
        }

        return false
    }
}

export interface ITermAndMeaning { // abbrev: 'tam'
    term: ProjectTerm | null, // We may or may not yet have a ProjectTerm corresponding to a meaning
    meaning: LexMeaning,
}

// (adapted from avtt)
// for PROJECTS_TABLE
export interface IProjectEntity {
    project: string
    seq: number
    members?: IMember[]
    // displayName?: string
    // group?: string
    // groupProjects?: string[]
}

const DEFAULT_INPUT_LANGUAGE = 'en'  // ISO-639-1

export class Project extends DBObject {
    name: string
    /** 
     * The last 'seq' for the project's docs as returned from `/projects`
     * gotten via API.getAuthorizedProjects()
     * Example:
     * 	"projects": [
        {
            "seq": 66696,
            "project": "TESTnm",
            "members": [
     */
    lastSeq: number = -1
    @observable displayName = ''
    @observable description = ''
    initialized = false
    dbAcceptor?: DBAcceptor
    watcher: EventEmitter | null = null
    @observable bookNames: BookNameMap = {}
    @observable portions: Portion[]
    @observable members: Member[]
    @observable plans: ProjectPlan[] = []   // Currently there is only 1 plan

    @observable terms: ProjectTerm[] = []
    @observable terms_rev = 0
    @observable termsMap = new Map<string, ProjectTerm>() // lexicalLink => ProjectTerm

    @observable copyrightStatement = ''  // for images
    @observable noteColors: string[] = ['#418D2B', '#4873A6', '#1F2E69']    // green, light blue, dark blue
    @observable emojis = '👍🏼, ❤️, 👆🏼, 🎉, 🎥, ???'
    @observable dateFormat: DateFormat = DateFormat.mmddyyyy
    @observable videoTimeCodeFormat: VideoTimeCodeFormat = VideoTimeCodeFormat.timeFormatWithPeriod // 'mm:ss.zzz'
    @observable compressedVideoQuality = 20
    @observable compressedVideoResolution = 720
    @observable maxVideoSizeMB = 100
    @observable images: ProjectImage[] = []
    @observable versification = 'English'   // do not persist, since this is hardcoded
    @observable messages: ProjectMessage[] = []

    @observable projectType: 'translation' | 'additional' | 'test_training' | 'resource' | 'other' | '' = ''
    @observable region: 'africa' | 'americas' | 'asia' | 'europe' | 'oceania' | '' = ''
    @observable inputLanguagePrimary: string = DEFAULT_INPUT_LANGUAGE  // ISO-639-1 (e.g. 'en')
    @observable copyrightNotice: string = '' // for videos
    @observable license: TRLLicense = TRLLicense.ASK;
    @observable organizationLogoUrl: string = ''

    oldPlanDoc: any = undefined

    static copyFileToVideoCache: ICopyFileToVideoCache
    static videoCacheAcceptor: IVideoCacheAcceptor

    constructor(name: string, db: IDB, lastSeq: number = -1) {
        super('project', db)

        this.name = name
        this.lastSeq = lastSeq
        this.displayName = name
        this.db = db
        this.portions = []
        this.members = []

        this.setDisplayName = this.setDisplayName.bind(this)
        this.setDescription = this.setDescription.bind(this)
        this.canAddMember = this.canAddMember.bind(this)
        this.addImage = this.addImage.bind(this)
        this.moveImage = this.moveImage.bind(this)
        this.deleteImage = this.deleteImage.bind(this)
        this.setCompressedVideoQuality = this.setCompressedVideoQuality.bind(this)
        this.setCompressedVideoResolution = this.setCompressedVideoResolution.bind(this)
        this.setMaxVideoSizeMB = this.setMaxVideoSizeMB.bind(this)

        for (let i=0; i<66; ++i) {
            let bbb = `${i + 1}`.padStart(3, '0')
            this.bookNames[bbb] = englishBookNames[i]
        }
    }

    // True iff the compressor is currently compressing a video
    @computed get videoBeingCompressed() {
        for (let portion of this.portions) {
            if (portion.passages.some(passage => passage.videoBeingCompressed)) return true
        }

        return false
    }

    async initialize(progress: (message: string) => void, allowInvalidIds = false) {
        log('initialize')
        if (this.initialized) return 0

        log('startSync')
        this.dbAcceptor = new DBAcceptor(this, Project.videoCacheAcceptor, DB_ACCEPTOR_VERSION, allowInvalidIds)

        let dbRecordCount = await this.db.initialize(this.dbAcceptor, progress)
        this.dbAcceptor.cleanUp()
        let progressIndicator = '/'
        while (this.plans.length === 0 && this.db.getRemoteSeq() < this.lastSeq) {
            // we haven't yet received the project plan
            // try doSync() until we get it so we don't create a new default plan to replace any existing one
            // see https://github.com/ubsicap/sltt/issues/911
            // Hope it's okay to keep the user stuck here until we can doSync() successfully
            await delay(3000)
            const isOnline = await getIsAppOnlineOrWait()
            if (isOnline) {
                progress(t`Syncing... ` + progressIndicator)
            } else {
                progress(t`Waiting for internet connection... ` + progressIndicator)
            }
            await this.db.doSync() // even offline we could receive from LAN/proxy
            progressIndicator = progressIndicator === '/' ? '\\' : '/'
        }
        await this.setUpProjectPlan()
        this.initialized = true

        return dbRecordCount
    }

    async addPortion(name: string, isGlossary?: boolean) {
        name = name.trim()
        if (this.portions.find(p => p.name === name)) {
            throw Error(t`System Error` + ': Duplicate name')
        }

        let newId = this.db.getNewId(this.portions, new Date(Date.now()))
        let portion = new Portion(newId, this.db)

        portion.name = name

        let rank = 100
        if (this.portions.length > 0) {
            rank = this.portions.slice(-1)[0].rankAsNumber + 100
        }
        portion.rank = DBObject.numberToRank(rank)

        if (isGlossary) {
            portion.isGlossary = true
        }

        await this.db.put(portion.toDocument())

        let _portion = _.findWhere(this.portions, {_id: portion._id})!
        return _portion
    }

    async removePortion(_id: string) {
        await remove(this.portions, _id)
    }

    async movePortion(_id: string, i: number) {
        let idx = _.findIndex(this.portions, { _id })
        if (idx === -1) throw Error(`movePortion: _id not found [${_id}]`)
        await move(this.portions, idx, i)
    }

    async setBookName(bbbccc: string, projectBookName: string) {
        if (!Object.keys(this.bookNames).includes(bbbccc) || this.bookNames[bbbccc] === projectBookName) {
            return
        }
        let doc = this._toDocument({})
        doc._id = 'teamPreferences'
        doc.bbbccc = bbbccc
        doc.projectBookName = projectBookName
        await this.db.put(doc)
    }

    private async setUpProjectPlan() {
        if (this.plans.length === 0) {
            await this.addDefaultProjectPlan()
        }
    }

    async addDefaultProjectPlan() {
        let plan = this.createProjectPlan(new Date(Date.now()))
        await this.db.put(plan.toDocument())
        plan = this.plans[this.plans.length - 1]
        if (!plan) {
            log('Project plan was not successfully added')
            return
        }

        await plan.addDefaultPlan()
    }

    async addProjectPlan() {
        if (this.plans.length > 0) {
            throw Error('Plan already exists')
        }

        let plan = this.createProjectPlan(new Date(Date.now()))
        let doc = plan.toDocument()
        await this.db.put(doc)

        return this.plans[0]
    }

    createProjectPlan(creationDate: Date) {
        let newId = this.db.getNewId(this.plans, creationDate, 'plan_')
        let plan = new ProjectPlan(newId, this.db)

        let rank = 100
        plan.rank = DBObject.numberToRank(rank)

        return plan
    }

    canAddMember(email: string) {
        if (email.trim() === '') return t`Email address cannot be empty`
        email = normalizeUsername(email)

        if (findMember(this.members, email)) return t`Duplicate email address`

        const message = validateUsername(email)

        return message
    }

    /**
     * addMember, removeMember, and setMember must create user email/role data for all members
     * even though only one user is being changed.
     * This is because the sync code in the backend writes the entire members list to the projectsDB.
     * The imageUrl, notifications, and name fields are not included since they are generated
     * by individual 'member' documents.
     */

    // Get doc with info for all members
    toMembersDocument() {
        const members = this.members.map(member => ({ email: member.email, role: member.role }))
        return this._toDocument({ members })
    }

    async addMember(email: string) {
        email = normalizeUsername(email)
        if (findMember(this.members, email)) return

        if (validateUsername(email) !== '') {
            throw new Error(`Invalid email address: /${email}/`)  // should not happen, should have been prevented by UI
        }

        const doc = this.toMembersDocument()
        doc.members.push({ email, role: 'translator' })

        await this.db.put(doc)
    }

    async removeMember(email: string) {
        let doc = this.toMembersDocument()

        let index = findMemberIndex(doc.members, email)
        if (index < 0) return

        doc.members.splice(index, 1)

        await this.db.put(doc)
    }

    async setMemberRole(email: string, role: MemberRole) {
        log('setmemberRole', email, role)

        let doc = this.toMembersDocument()

        let member = findMember(doc.members, email)
        if (!member) return

        member.role = role

        await this.db.put(doc)
    }

    // Member image, notifications, and name are set for a single member.
    // This info is not persisted by the back end in the projects table 
    // (but it is persisted in the docs table)

    async setMemberImage(email: string, imageUrl: string) {
        let member = findMember(this.members, email)
        if (!member || member.imageUrl === imageUrl) {
            return
        }

        let doc = this._toDocument({ model: 2, email, imageUrl })
        doc._id = 'member'
        await this.db.put(doc)
    }

    async setMemberNotifications(email: string, notifications: MemberNotifications) {
        const member = findMember(this.members, email)
        if (!member || member.notifications === notifications) {
            return
        }

        let doc = this._toDocument({ model: 2, email, notifications })
        doc._id = 'member'
        await this.db.put(doc)
    }

    async setMemberName(email: string, name: string) {
        const member = findMember(this.members, email)
        if (!member || member.name === name) {
            return
        }

        let doc = this._toDocument({ model: 2, email, name })
        doc._id = 'member'
        await this.db.put(doc)
    }

    // Change member from an old email address to a new email address
    async setMemberEmail(oldEmail: string, newEmail: string) {
        oldEmail = normalizeUsername(oldEmail)
        newEmail = normalizeUsername(newEmail) // we always want to serialize a normalized email address

        log('setMemberEmail:', fmt({ oldEmail, newEmail }))
        if (oldEmail == newEmail) return

        if (validateUsername(newEmail) !== '') {
            throw new Error(`Invalid email address: /${newEmail}/`)  // should not happen, should have been prevented by UI
        }

        const member = findMember(this.members, oldEmail) as Member
        if (!member) {
            throw new Error(`Member not found: ${oldEmail}`)
        }

        // Create doc to update member email
        let doc = this._toDocument({ model: 2, email: oldEmail, newEmail })
        doc._id = 'member'

        // Create doc to update the project members table which is the used by the
        // serverless backend to authorize all operations.
        const membersDoc = this.toMembersDocument()
        membersDoc._id = 'members'
        let _member = findMember(membersDoc.members, oldEmail) as Member
        if (!member) {
            throw new Error(`Member not found: ${oldEmail}`)
        }

        _member.email = newEmail

        await this.db.put(doc)
        await this.db.put(membersDoc)
    }

    creatorName(email: string) {
        let member = findMember(this.members, email) as Member
        return member ? member.displayName : email
    }

    getDefaultPortion(_id: string | null) {
        let { portions } = this
        let portion = _id && _.findWhere(portions, { _id })

        if (!portion) {
            if (portions.length > 0)
                portion = portions[0]
        }

        return portion
    }

    async logPortions() {
        let { portions } = this
        for (let i = 0; i < portions.length; ++i) {
            let p = portions[i]
            console.log(`${p.name} = ${p.rank}`)
        }
    }

    acceptProjectTerm(projectTerm: ProjectTerm) {
        this.termsMap.set(projectTerm.lexicalLink, projectTerm)
        ++this.terms_rev
    }

    getProjectTerm(lexicalLink: string) {
        return this.termsMap.get(lexicalLink)
    }

    createProjectTerm(lexicalLink: string) {
        let newId = this.db.getNewId(this.terms, new Date(Date.now()), 'term_')
        let term = new ProjectTerm(newId, this.db)
        term.lexicalLink = lexicalLink

        let rank = 100
        if (this.terms.length > 0) {
            rank = this.terms[0].rankAsNumber / 2
        }
        term.rank = DBObject.numberToRank(rank)
        return term
    }

    async addProjectTerm(term: ProjectTerm) {
        log('addProjectTerm', s(term.toDocument()))
        await this.db.put(term.toDocument())
    }

    getTermsAndMeaningsByRef(refRanges: RefRange[], keyTermsOnly: boolean): ITermAndMeaning[] {
        let { termsMap } = this
        let meanings = MarbleLemmas.refRangesToMeanings.get(refRanges)

        // meanings = meanings.filter(meaning => meaning.references.length < 250)

        function mapMeaning(meaning: LexMeaning): ITermAndMeaning {
            return {
                meaning,
                term: termsMap.get(meaning.lexicalLink) ?? null
            }
        }

        let tams = meanings.map(mapMeaning)

        if (keyTermsOnly) {
            tams = tams.filter(tam => tam.term?.isKeyTerm)
        }

        return tams
    }

    getGlossesFromVerses(refRanges: RefRange[]) {
        let tams = this.getTermsAndMeaningsByRef(refRanges, true)
        let glossesSet = new Set<string>()
        for (let {term} of tams) {
            for (let gloss of (term?.getGlosses() ?? [])) {
                glossesSet.add(gloss)
            }
        }

        let glosses = [...glossesSet].sort((a, b) => a.localeCompare(b))

        return glosses
    }

    createProjectMessage() {
        let creationDate = this.db.getNewId(this.messages, new Date(Date.now()))
        let _id = `notify/${creationDate}`
        return new ProjectMessage(_id, this.db)
    }

    async addProjectMessage(notif: ProjectMessage) {
        let { name } = this
        let { subject, globalMessage, videoUrl, isGlobalResponse } = notif
        log('addProjectMessage', fmt({ project: name, subject, globalMessage, videoUrl, isGlobalResponse }))

        await this.db.put(notif.toDocument())
    }

    async removeProjectMessage(notif: ProjectMessage) {
        let doc = notif._toDocument({parent: notif.parent})
        doc.removed = true
        await notif.db.put(doc)
    }

    async setCopyrightStatement(copyrightStatement: string) {
        let trimmedCopyright = copyrightStatement.trim()
        if (this.copyrightStatement === trimmedCopyright) {
            return
        }

        let doc = this._toDocument({})
        doc._id = 'publicationPreferences'
        doc.copyrightStatement = trimmedCopyright
        await this.db.put(doc)
    }

    // _id can be for a portion or any of its subobjects (e.g. Passage)
    findPortion(_id: string) {
        return this.portions.find(p => _id.startsWith(p._id))
    }

    // _id can be for a passage or any of its subobjects (e.g. PassageVideo)
    findPassage(_id: string) {
        let portion = this.findPortion(_id)
        return portion?.passages.find(p => _id.startsWith(p._id))
    }

    async setNoteColors(noteColors: string[]) {
        if (JSON.stringify(noteColors) === JSON.stringify(this.noteColors)) {
            return
        }
        let doc = this._toDocument({ model: 1, noteColors })
        doc._id = 'teamPreferences'
        await this.db.put(doc)
    }

    async setDateFormat(dateFormat: DateFormat) {
        if (dateFormat === this.dateFormat) {
            return
        }
        let doc = this._toDocument({ model: 1, dateFormat })
        doc._id = 'teamPreferences'
        await this.db.put(doc)
    }

    setVideoTimeFormat = async (videoTimeCodeFormat: VideoTimeCodeFormat) => {
        if (videoTimeCodeFormat === this.videoTimeCodeFormat) {
            return
        }
        const doc = this._toDocument({ model: 1, videoTimeCodeFormat })
        doc._id = 'teamPreferences'
        await this.db.put(doc)
    }

    async setEmojis(emojis: string) {
        emojis = emojis.trim()
        
        if (emojis === this.emojis) {
            return
        }
        let doc = this._toDocument({ emojis })
        doc._id = 'teamPreferences'
        await this.db.put(doc)
    }


    async setCompressedVideoQuality(compressedVideoCRF: number) {
        if (compressedVideoCRF === this.compressedVideoQuality) {
            return
        }
        let doc = this._toDocument({})
        doc._id = 'teamPreferences'
        doc.model = 9
        doc.compressedVideoCRF = compressedVideoCRF
        await this.db.put(doc)
    }

    async setCompressedVideoResolution(compressedVideoResolution: number) {
        if (compressedVideoResolution === this.compressedVideoResolution) {
            return
        }
        let doc = this._toDocument({})
        doc._id = 'teamPreferences'
        doc.model = 9
        doc.compressedVideoResolution = compressedVideoResolution
        await this.db.put(doc)
    }

    async setMaxVideoSizeMB(maxVideoSizeMB: number) {
        if (maxVideoSizeMB === this.maxVideoSizeMB) {
            return
        }
        let doc = this._toDocument({})
        doc._id = 'teamPreferences'
        doc.model = 9
        doc.maxVideoSizeMB = maxVideoSizeMB
        await this.db.put(doc)
    }

    async setDisplayName(displayName: string) {
        if (displayName === this.displayName || displayName.trim() === '') {
            return
        }

        let doc = this._toDocument({ model: 3, displayName })
        doc._id = 'projectPreferences'
        await this.db.put(doc)
    }

    async setDescription(description: string) {
        if (description === this.description) {
            return
        }

        let doc = this._toDocument({ model: 3, description })
        doc._id = 'projectPreferences'
        await this.db.put(doc)
    }

    async setProjectType(projectType: string) {
        if (projectType === this.projectType) {
            return
        }

        let doc = this._toDocument({ model: 3, projectType })
        doc._id = 'projectPreferences'
        await this.db.put(doc)
    }

    async setRegion(region: string) {
        if (region === this.region) {
            return
        }

        let doc = this._toDocument({ model: 3, region })
        doc._id = 'projectPreferences'
        await this.db.put(doc)
    }

    getPrimaryInputLanguageOrDefault(defaultLanguageCode: string = DEFAULT_INPUT_LANGUAGE) {
        return this.inputLanguagePrimary || defaultLanguageCode
    }

    async setPrimaryInputLanguage(languageCode: string, skipLanguageValidation: boolean = false) {
        if (languageCode === this.inputLanguagePrimary)
            return
        if (!skipLanguageValidation)
            validateIso639dash1LanguageCode(languageCode)
        const doc = this._toDocument({ model: 13, inputLanguagePrimary: languageCode })
        doc._id = 'publicationPreferences'
        await this.db.put(doc)
    }

    async setCopyrightNotice(copyrightNotice: string) {
        const copyrightNoticeTrimmed = copyrightNotice.trim()
        if (copyrightNoticeTrimmed === this.copyrightNotice)
            return
        const doc = this._toDocument({ model: 13, copyrightNotice: copyrightNoticeTrimmed })
        doc._id = 'publicationPreferences'
        await this.db.put(doc)
    }

    async setLicense(license: TRLLicense) {
        if (license === this.license)
            return
        if (!Object.values(TRLLicense).includes(license))
            throw Error(`Invalid license '${license}'`)
        const doc = this._toDocument({ model: 13, license })
        doc._id = 'publicationPreferences'
        await this.db.put(doc)
    }

    async setOrganizationLogoUrl(organizationLogoUrl: string) {
        if (this.organizationLogoUrl === organizationLogoUrl) {
            return
        }
        const MAX_EXPRESS_SERVER_PAYLOAD = 100 * 1024 // 100Kb limit
        if (organizationLogoUrl.length >= MAX_EXPRESS_SERVER_PAYLOAD) {
            throw Error(`Image data size (${Math.max(organizationLogoUrl.length / 1024)}Kb) exceeds ${Math.max(MAX_EXPRESS_SERVER_PAYLOAD/1024)}Kb`)
        }
        const imageRegex = allowedImageTypes.join('|').replace(/\//g, '\\/') // escape "image/png" for regex
        const regexDataUrl = RegExp(`^data:(?:${imageRegex});`)
        if (organizationLogoUrl &&
            !regexDataUrl.test(organizationLogoUrl)) {
            throw Error(`Invalid image data Url: ${organizationLogoUrl}`)
        }
        const doc = this._toDocument({ model: 13, organizationLogoUrl })
        doc._id = 'publicationPreferences'
        await this.db.put(doc)
    }

    async addImage(src: string) {
        src = src.trim()
        if (src === '' || this.images.find(image => image.src === src)) {
            return
        }
        let newId = this.db.getNewId(this.images, new Date(Date.now()), 'prjImg_')
        let image = new ProjectImage(newId, this.db)
        image.src = src

        let rank = 100
        if (this.images.length > 0) {
            rank = this.images[0].rankAsNumber / 2
        }
        image.rank = DBObject.numberToRank(rank)
        await this.db.put(image.toDocument())
    }

    async deleteImage(_id: string) {
        await remove(this.images, _id)
    }

    async moveImage(_id: string, i: number) {
        let idx = _.findIndex(this.images, { _id })
        if (idx === i) {
            return
        }

        if (idx === -1) throw Error(`moveProjectIcon: _id not found [${_id}]`)
        await move(this.images, idx, i)
    }

    * passageVideos() {
        for (let portion of this.portions) {
            for (let passage of portion.passages) {
                for (let video of passage.videos) {
                    if (video.removed) continue
                    yield video
                }
            }
        }
    }

    countAll() {
        let [portions, passages, videos, noteItems, glosses] = [0,0,0,0,0]

        for (let portion of this.portions) {
            ++portions
            for (let passage of portion.passages) {
                ++passages
                for (let video of passage.videos) {
                    if (video.removed) continue
                    ++videos
                    for (let note of video.notes) {
                        for (let item of note.items) { ++noteItems }
                    }
                    for (let gloss of video.glosses) { ++glosses }
                }
            }
        }

        return ({ name: this.name, portions, passages, videos, noteItems, glosses})
    }

    hashtagsArray() {
        let passages = this.portions.flatMap(portion => portion.passages)
        let hashtags = passages.flatMap(passage => passage.hashtagsArray())
        let tags = _.uniq(hashtags, false, tag => tag.toLowerCase())
        tags = _.sortBy(tags, tag => tag.toLowerCase())

        return tags
    }

    passagesMatchingHashtag(hashtag: string) {
        let passages = this.portions.flatMap(portion => portion.passages)
        passages =  passages.filter(passage => passage.hasHashtag(hashtag))
        return passages
    }

    summary() {
        let { description, projectType, region, members } = this
        let admins = members.filter(member => member.role === 'admin').map(member => member.email)
        let consultants = members.filter(member => member.role === 'consultant').map(member => member.email)

        let staff = `admins: ${admins.join(', ')}`
        if (consultants.length) {
            staff += `, consultants: ${consultants.join(', ')}`
        }

        description = description.replace(/<\/?\w+>/g, ' ')
        description = description.replace(/\s+/g, ' ')
        description = description.trim()

        return [this.name, description, projectType, region, staff].join('\t')
    }

    async deleteColor(colorIndex: number) {
        const noteColors = [...this.noteColors]
        noteColors[colorIndex] = PassageNote.DeletedColor
        await this.setNoteColors(noteColors)
    }

    // Find member based on email including the possibility that this a
    // previously used email for this member
    findMember(email: string): Member | undefined {
        email = normalizeUsername(email)

        for (let member of this.members) {
            if (member.email === email) return member
            if (member.previousEmails.includes(email)) return member
        }

        return undefined
    }

    findMemberWithDefault(email: string): Member {
        let member = this.findMember(email)
        if (!member) {
            member = new Member(email)
        }

        return member
    }
    
    // Return portions that are progressible (ex: no glossaries)
    @computed get progressiblePortions() {
        return this.portions.filter((portion: any) => !portion.isGlossary);
    }
}


export type MemberRole = 'admin' | 'translator' | 'consultant' | 'interpreter' | 'observer' | 'translator'

/** WARNING
 * There is a bit of a mess with the email field in the Member class.
 * We should have forced this to be lowercase and never allowed anything else, but originally we didn't.
 * This means that there is a variety of persisted data that may have this value (and
 * the associated creator, username, and viewedBy fields) in some random upper/lower case.
 *
 * To repair this when we set or accept one of these values
 * we run it through normalizeUsername. This allows us to safely compare these values
 * anywhere without worrying about case.
 * This confines the problem to: AppRoot.ts, ProjectModel.ts, DBAcceptor.ts, Root.ts.
 */

export enum MemberNotifications {
    NONE = 'none',
    HOURLY = 'hourly'
}

// (adapted from avtt)
// for PROJECTS_TABLE
interface IMember {
    email: string
    role: MemberRole,
    imageUrl: string
    notifications?: MemberNotifications
    name: string // e.g. "Bob Smith"
}

export class Member {
    @observable email = ''
    @observable previousEmails: string[] = []

    @observable public role: MemberRole
    @observable imageUrl = ''
    @observable notifications = MemberNotifications.NONE
    @observable name = '' // e.g. "Bob Smith"

    constructor (email?: string, role: MemberRole = 'translator', imageUrl?: string )
    {
        this.email = normalizeUsername(email ?? '')
        this.role = role
        this.imageUrl = imageUrl || ''
    }

    get displayName() {
        return this.name || this.email
    }

    toSnapshot() {
        return createSnapshot(this)
    }
}

export class ProjectTask extends MoveableDBObject {
    @observable name = ''
    @observable details = ''
    @observable difficulty = 1.0

    // id is tricky.
    // It is set once and never changed.
    // Its name is very similar to _id (which is differnt unique identifier for this task)
    // It has the same format as number you see in UI for this task (i.e. stage#.task#) but will often be
    // a different value if tasks/stages have been added/deleted.
    // this is the value that is stored in status field of PassageVideo.
    @observable id = '' // short unique identifier, e.g., 1.2.

    @observable stagePosition = 0  // position of stage in list of stages. Non-persisted
    @observable taskPosition = 0   // position in containing list of tasks. Non-persisted

    @computed get displayedName() {
        let { name, stagePosition, taskPosition } = this
        if (name === 'Not started') {
            return t`Not started`
        }

        if (name === 'Finished') {
            return t`Finished`
        }

        return `(${stagePosition}.${taskPosition}) ${name}`
    }

    get firstTask() { return this.name === 'Not started' }
    get lastTask() { return this.name === 'Finished' }

    // We used to include that stage/task number in the name, remove this if still present
    static validName(name: string) {
        return name.replace(/^(\d+\.?\d*\s*)*/, '')
    }

    constructor(_id: string, db: IDB) {
        super(_id, db)
        this.setDetails = this.setDetails.bind(this)
        this.setDifficulty = this.setDifficulty.bind(this)
        this.setName = this.setName.bind(this)
    }

    dbg() {
        let { name, details, difficulty, rank, id, stagePosition, taskPosition } = this
        return { name, difficulty, rank, id, stagePosition, taskPosition }
    }

    toDocument() {
        let { name, rank, details, difficulty, id } = this
        return this._toDocument({ name, rank, details, difficulty, id, model: 8 })
    }

    async setName(stage: ProjectStage, name: string) {
        name = name.trim()

        name = ProjectTask.validName(name)
        // Don't allow setting a duplicate name
        if (stage.tasks.find(task => task.name === name)) {
            return
        }

        let doc = this._toDocument({ name, model: 8 })
        await this.db.put(doc)
    }

    async setDetails(details: string) {
        details = details.trim()
        if (details === this.details) {
            return
        }
        let doc = this._toDocument({ details, model: 8 })
        await this.db.put(doc)
    }

    async setId(id: string) {
        id = id.trim()
        if (id === this.id) {
            return
        }
        let doc = this._toDocument({ id, model: 8 })
        await this.db.put(doc)
    }

    async setDifficulty(difficulty: number) {
        if (difficulty < 0.0 || difficulty === this.difficulty) {
            return
        }
        let doc = this._toDocument({ difficulty, model: 8 })
        await this.db.put(doc)
    }
}

// Find rank of item item before index 'before'.
function calculateRankBefore(before: number, items: DBObject[]) {
    let ranks = items.map(item => parseFloat(item.rank))

    let rankBefore = (before - 1) in ranks ? ranks[before-1] : 0
    let rankAfter = before in ranks ? ranks[before] : rankBefore + 200

    let rank = DBObject.numberToRank((rankBefore + rankAfter)/2)

    // log('calculateRank', fmt({before, ranks, rank}))
    return rank
}


export class ProjectStage extends MoveableDBObject {
    @observable tasks: ProjectTask[] = []
    @observable name = ''
    @observable index = 0   // position in containing list of stages. Non-persisted


    @computed get displayedName() {
        let { name, index } = this
        return `${index} ${name}`
    }

    // Stage and task names used to begin with numeric indices. This strips them off if
    // they are still there.
    static validName(name: string) {
        return name.replace(/^(\d+\.?\d*\s*)*/, '')
    }

    constructor(_id: string, db: IDB) {
        super(_id, db)
    }

    dbg() {
        let { tasks, name, rank, index } = this
        return {
            name,
            rank,
            index,
            tasks: tasks.map(task => task.dbg())
        }
    }

    toDocument() {
        let { name, rank } = this
        return this._toDocument({ name, rank, model: 8 })
    }

    async setName(plan: ProjectPlan, name: string) {
        name = name.trim()

        // Remove index from beginning if it exists, and then
        // add the correct index
        name = ProjectStage.validName(name)
        if (plan.stages.find(stage => stage.name === name)) {
            return
        }

        let doc = this._toDocument({ name, model: 8 })
        await this.db.put(doc)
    }

    createTask(plan: ProjectPlan, id: string, rank: string, creationDate: Date, name: string, details: string, difficulty: number) {
        name = name.trim()
        if (this.tasks.find(t => t.name === name)) {
            throw Error(t`System Error` + ': Duplicate name')
        }

        let newId = this.db.getNewId(this.tasks, creationDate, 'tsk_')
        let task = new ProjectTask(this._id + '/' + newId, this.db)

        task.name = ProjectTask.validName(name)
        task.details = details

        if (difficulty < 0.0) {
            difficulty = 1.0
        }

        task.difficulty = difficulty

        if (id === '') {
            task.id = plan.getUniqueTaskId(this)
        } else {
            task.id = id
        }

        task.rank = rank
        // task.rank = DBObject.numberToRank(_rank)

        return task
    }

    // Add a task and persist it in the DB.
    async addTask(plan: ProjectPlan, addBeforeIndex: number, name: string, details?: string, difficulty?: number) {
        details = details || ''
        difficulty = difficulty || 0
        let creationDate = new Date(Date.now())

        let rank = calculateRankBefore(addBeforeIndex, this.tasks)

        let task = this.createTask(plan, '', rank, creationDate, name, details, difficulty)
        await this.db.put(task.toDocument())

        let _task = _.findWhere(this.tasks, {_id: task._id})
        if (!_task) throw Error('could not find newly added task')

        this.updateIndices()
        return _task
    }

    private async moveTask(_id: string, i: number) {
        let idx = _.findIndex(this.tasks, { _id})
        if (idx === i) {
            log('moveTask nothing to do')
            return /* nothing to do */
        }

        if (idx === -1) throw Error(`moveTask: _id not found [${_id}]`)

        await move(this.tasks, idx, i)
        this.updateIndices()
    }

    // Remove a task and update the status of passages and passage videos that may
    // be affected.
    async removeTask(_id: string, project: Project) {
        let plan = project.plans[0]
        if (!plan) {
            return
        }

        let { tasks } = plan
        let deletedPosition = tasks.findIndex(t => t._id === _id)
        if (deletedPosition < 0) {
            return
        }

        // If a video was assigned to the deleted task, assign it to the next task.
        let oldStatus = tasks[deletedPosition].id
        let newPosition = Math.min(deletedPosition + 1, tasks.length - 1)
        let newStatus = tasks[newPosition].id

        await plan.updateVideoStatus(project, [oldStatus], newStatus)

        await remove(this.tasks, _id)
        this.updateIndices()
    }

    updateIndices() {
        let { index } = this
        if (this.name !== 'Not started' && this.name !== 'Finished') {
            for (let [j, task] of this.tasks.entries()) {
                task.stagePosition = index
                task.taskPosition = j + 1
            }
        } else if (this.name == 'Finished' && this.tasks.length) {
            this.tasks[0].id = 'Finished'
        } else if (this.name == 'Not started' && this.tasks.length) {
            this.tasks[0].id = 'Not started'
        }
    }
}

// A project plan divides the work for each passage into stages and tasks.

// To display project plan from console
//     console.log(JSON.stringify(window.appRoot.rt.project.plans[0].dbg(), null, 4))
// List all task ids
//     window.appRoot.rt.project.plans[0].stages.flatMap(stage => stage.tasks).map(task=>task.id).sort()

export class ProjectPlan extends DBObject {
    @observable stages: ProjectStage[] = []
    @computed get viewableStages() {
        return this.stages.filter(stage => stage.name !== 'Not started' && stage.name !== 'Finished')
    }

    constructor(_id: string, db: IDB) {
        super(_id, db)
    }

    dbg() {
        let { stages } = this
        return {
            stages: stages.map(stage => stage.dbg())
        }
    }

    // Verify that the id field for all tasks is unique.
    // Return true if there is a problem.
    checkIds() {
        let tasks = this.stages.flatMap(stage => stage.tasks)
        let ids = _.pluck(tasks, 'id')
        let uniq = _.uniq(ids)
        let dups = ids.length !== uniq.length
        log('===checkIds', ids.sort().join(' '), dups ? '*Error*' : '*OK*')

        return dups
    }

    /**
     * In SLTT 1.10.1 and earlier there was a serious bug that caused us to generate
     * duplicate id's for tasks. The effect of this was that when you drag a passage
     * to a column with a duplicate id, the passage which display in the earlier
     * column with the same id.
     *
     * Find the duplicate ids in the later positions of the plan and replace them with
     * unique ids.
     *
     * @param dryRun - when true we only update the local data, this will be overwritten on refresh
     */
    async fixIds(dryRun: boolean) {
        let tasks = this.stages.flatMap(stage => stage.tasks)
        for (let i=1; i<tasks.length; ++i) {
            let task = tasks[i]
            let prevTasks = tasks.slice(0, i)
            if (prevTasks.find(_task => _task.id === task.id)) {
                let id = this.getUniqueTaskId(this.stages[task.stagePosition])
                if (dryRun === false) { // must explictly pass false to update non local data
                    await task.setId(id)
                } else {
                    task.id = id
                }
            }
        }

        if (this.checkIds()) {
            log('===FIX FAILED')
            debugger
        }
    }

    /**
     * Get a new unique id (not! _id) for a task in stage
     *
     * The id field for each task must be unique because it is used to record the status of a PassageVideo.
     * Note that the _id field is unique but we can't use it because it was not present in earlier versions
     * of the data.
     *
     * It is not enough to just look at the id's in the current stage to determine uniqueness.
     * Adding and deleting stages changes the numbering of stages and means that some other
     * stage may already be using the next sequential id from the current stage.
     */
    getUniqueTaskId(stage: ProjectStage) {
        let ids = this.stages.flatMap(stage => stage.tasks.map(task => task.id))

        // Grab the next taskIndex for this stage
        let taskIndex = stage.tasks.length + 1

        while (true) {
            let id = `${stage.index}.${taskIndex}`

            // If no stage already contains this id, return it
            if (!ids.includes(id)) return id

            ++taskIndex // Otherwise try the next task index
        }
    }

    // // Syntax of project plan
    // static Parser = Par.createLanguage({
    //     Name: () => Par.regexp(/[^\n]+/),
    //     Difficulty: () => Par.regexp(/(\d+\.?\d*)|(\.\d+)/),
    //     Details: () => Par.regexp(/[^#]*/),

    //     Task: r =>
    //         Par.seq(Par.regexp(/##/), r.Name, Par.whitespace, r.Difficulty, Par.optWhitespace, r.Details)
    //             .map(([_marker, name, _space1, difficulty, _space2, details]) => {
    //                 let task = new ProjectTask('')
    //                 task.name = name.trim()
    //                 task.details = details.trim()
    //                 task.difficulty = parseFloat(difficulty)
    //                 return task
    //             }),
    //                 // new ProjectTask({ name: name.trim(), details: details.trim(), difficulty: parseFloat(difficulty) })),

    //     Stage: r =>
    //         Par.seq(
    //             Par.optWhitespace,
    //             Par.regexp(/#/),
    //             r.Name,
    //             Par.whitespace,
    //             r.Task.sepBy(Par.optWhitespace),
    //         ).map(([_space1, _marker, name, _space2, tasks]) => {
    //             let stage = new ProjectStage('')
    //             stage.name = name.trim()

    //             // What to do about tasks?

    //             return stage
    //             // return new ProjectStage({ name: name.trim(), tasks: tasks })
    //         }),

    //     Plan: r =>
    //         Par.seq(
    //             r.Stage.sepBy(Par.optWhitespace),
    //         ).map(([stages]) => {
    //             let plan = new ProjectPlan()

    //             // what to do about stages?

    //             return plan
    //             // return new ProjectPlan({ stages: stages })

    //         }),
    // })

    // /**
    //  * Create a project plan from a text string. Will throw if the syntax of the string is
    //  * incorrect.
    //  *
    //  * @param planText
    //  */
    // static parsePlan(planText: string) {
    //     // return new ProjectPlan()
    //     // return ProjectPlan.Parser.Plan.tryParse(planText) as ProjectPlan
    // }

    toDocument() {
        return this._toDocument({ model: 8 })
    }

    createStage(name: string, rank: string) {
        name = name.trim()
        if (this.stages.find(s => s.name === name)) {
            throw Error(t`System Error` + ': Duplicate name')
        }

        let creationDate = new Date(Date.now())

        let newId = this.db.getNewId(this.stages, creationDate, 'stg_')
        let stage = new ProjectStage(this._id + '/' + newId, this.db)

        stage.name = ProjectStage.validName(name)
        stage.rank = rank

        return stage
    }

    async addStage(addBeforeIndex: number, name: string) {
        let rank = calculateRankBefore(addBeforeIndex, this.stages)

        let stage = this.createStage(name, rank)
        await this.db.put(stage.toDocument())
        this.updateIndices()

        let _stage = _.findWhere(this.stages, {_id: stage._id})
        if (!_stage) throw Error('could not find newly created stage')
        return _stage
    }

    // Remove a stage and update the status of any passages or passageVideos assigned
    // to any tasks in that stage.
    async removeStage(project: Project, _id: string) {
        let plan = project.plans[0]
        if (!plan) {
            return
        }
        let { stages } = plan

        let deletedStageIndex = stages.findIndex(s => s._id === _id)
        if (deletedStageIndex < 0) {
            return
        }

        // be careful! id != _id. id (e.g. '1.3') is what is stored in passageVideo

        let oldStatuses = stages[deletedStageIndex].tasks.map(task => task.id)

        /**
         * If there are any tasks following the stage that is being deleted,
         * find the first task and reset any videos that currently have a video
         * status that points to the current stage to instead point to the
         * first task in the next stage.
         */
        let newStatus = ''
        let followingTasks = stages.slice(deletedStageIndex + 1).flatMap(stage => stage.tasks)
        if (followingTasks.length) {
            newStatus = followingTasks[0].id
        }

        await this.updateVideoStatus(project, oldStatuses, newStatus)

        await remove(stages, _id)
        this.updateIndices()
    }

    async updateVideoStatus(project: Project, oldStatuses: string[], newStatus: string) {
        let passages = project.portions.flatMap(portion => portion.passages)
        //!!! do we need to do this on deleted videos also to update their status?
        let passageVideos = passages.flatMap(passage => passage.videosNotDeleted)

        log('updateVideoStatus', fmt({ oldStatuses, newStatus, currentStatuses: passageVideos.map(v => v?.status) }))

        for (let passageVideo of passageVideos) {
            if (oldStatuses.includes(passageVideo?.status || 'NOSTATUS')) {
                await passageVideo.setStatus(newStatus)
            }
        }

        log('updateVideoStatus updates', fmt({ updatedStatuses: passageVideos.map(v => v?.status) }))
    }

    updateIndices() {
        let { stages } = this
        for (let [i, stage] of stages.entries()) {
            stage.index = i // Not i + 1, b/c 1st editable stage is the 2nd stage
            stage.updateIndices()
        }
    }

    /**
     * If something happens to a project plan that causes it to become unrecoverable,
     * reset the plan to its initial state from the console like this:
     *
     *     _.rt.project.plans[0].resetProjectPlan(_.rt.project).catch(console.log)
     */
    async resetProjectPlan(project: Project) {
        while (this.stages.length > 0) {
            let stage = this.stages[0]
            await this.removeStage(project, stage._id)
        }

        await this.addDefaultPlan()
    }

    async addDefaultPlanNotStartedStage() {
        await this.addStage(0, 'Not started')
        let stage = this.stages[0]
        await stage.addTask(this, 0, 'Not started', '', 0.0)
    }

    async addDefaultPlanFinishedStage() {
        const count = this.stages.length

        await this.addStage(count, 'Finished')
        const stage = this.stages.slice(-1)[0]
        await stage.addTask(this, 0, 'Finished', '', 0.0)
    }

    async addDefaultPlan() {
        await this.addDefaultPlanNotStartedStage()

        await this.addStage(1, 'Team')
        let stage = this.stages[1]
        await stage.addTask(this, 0, 'First Draft', 'Record first video draft', 1.0)
        await stage.addTask(this, 1, 'Review First Draft', 'Record first video draft', 1.0)
        await stage.addTask(this, 2, 'Team Review', 'Team checks video for accuracy, ...', 1.0)
        await stage.addTask(this, 3, 'Second Draft', '', 1.0)

        await this.addStage(2, 'Consultant')
        stage = this.stages[2]
        await stage.addTask(this, 0, 'Consultant Review', '', 1.0)
        await stage.addTask(this, 1, 'Third Draft', '', 1.0)

        await this.addDefaultPlanFinishedStage()
    }

    get tasks() {
        return this.stages.flatMap(stage => stage.tasks)
    }

    // Get task by id or the 'Not Started' task if no match
    getTaskById(id: string) {
        const { tasks } = this
        const task = tasks.find(task => task.id === id)
        return task ?? tasks[0]
    }

}

export class Portion extends MoveableDBObject implements IMultilingualTitles, IDBObjectToDocument {
    @observable name: string = ''
    @observable _rev = 0
    @observable titles: MultilingualString[] = []

    @observable passages: Passage[]
    @observable firstPassageIsThumbnail: boolean = false // true if should hide from TRL passage browsing
    // @observable references: RefRange[] = [] // No longer used [references for a portion are not useful]

    @observable isGlossary = false
    @computed get isProgressible () {
        return !this.isGlossary
    }

    constructor(_id: string, db: IDB) {
        super(_id, db)
        this.passages = []
    }

    toDocument() {
        let { name, rank, isGlossary, firstPassageIsThumbnail } = this
        return this._toDocument({ name, rank, isGlossary,firstPassageIsThumbnail })
    }

    async setName(name: string) {
        if (name.trim() === this.name) {
            return
        }
        let doc = this.toDocument()
        doc.name = name
        await this.db.put(doc)
    }

    async addPassage(name: string) {
        name = name.trim()
        if (this.passages.find(p => p.name === name)) {
            throw Error(t`System Error` + ': Duplicate name')
        }

        let newId = this.db.getNewId(this.passages, new Date(Date.now()))
        let passage = new Passage(this._id + '/' + newId, this.db)

        passage.name = name

        let _rank = 100
        if (this.passages.length > 0) {
            _rank = this.passages.slice(-1)[0].rankAsNumber + 100
        }
        passage.rank = DBObject.numberToRank(_rank)

        await this.db.put(passage.toDocument())

        return _.findWhere(this.passages, {_id: passage._id})!
    }

    // "old passages" are those listed under an empty passage with a name that starts with "---"
    @computed get indexOfOldPassages() {
        return this.passages.findIndex(passage => passage.name.startsWith('---') && !passage.hasVideo())
    }

    // "final passages" are those listed before the --- old passages and have a video
    @computed get finalPassages() {
        const passagesWithVideos = this.passages.filter(passage => passage.hasVideo())
        const indexOfOldPassages = this.indexOfOldPassages
        if (indexOfOldPassages === -1) {
            return passagesWithVideos
        }
        return passagesWithVideos.filter((_, i) => i < indexOfOldPassages)
    }

    videod() {
        return this.passages.some(passage => passage.videod())
    }

    async removePassage(_id: string) {
        await remove(this.passages, _id)
    }

    async movePassage(_id: string, i: number) {
        let idx = _.findIndex(this.passages, { _id})
        if (idx === i) {
            log('movePassage nothing to do')
            return /* nothing to do */
        }

        if (idx === -1) throw Error(`movePassage: _id not found [${_id}]`)

        await move(this.passages, idx, i)
    }

    // Get a passage matching the _id or the first passage if no match or null
    getDefaultPassage(_id: string | null) {
        let { passages } = this
        let passage = _id && _.findWhere(passages, { _id })

        if (!passage) {
            if (passages.length > 0)
                passage = passages[0]
        }

        return passage || null
    }

    checkNewPassageName(name: string) {
        name = name.trim()
        if (_.findWhere(this.passages, {name})) {
            return 'Duplicate passage name.'
        }
        if (name === '') {
            return 'Empty passage name.'
        }

        return ''
    }

    unviewedVideoExists(username: string, cutoff: Date) {
        let unviewedVideos = this.passages.map(passage => passage.firstUnviewedVideo(username, cutoff))
            .filter(video => video !== null)
        return unviewedVideos.length > 0
    }

    unviewedNoteExists(username: string, cutoff: Date, includeConsultantOnlyNotes: boolean) {
        let unviewedNotes = this.passages.map(passage => passage.firstUnviewedNote(username, cutoff, includeConsultantOnlyNotes))
            .filter(note => note !== null)
        return unviewedNotes.length > 0
    }

    unresolvedNoteExists(cutoff: Date, includeConsultantOnlyNotes: boolean) {
        let unresolvedNotes = this.passages.map(passage => passage.firstUnresolvedNoteOnLatestVideo(cutoff, includeConsultantOnlyNotes))
            .filter(note => note !== null)
        return unresolvedNotes.length > 0
    }

    getTitleOrDefault(language: string, defaultTitle: string): string {
        return getTitleOrDefault(this.titles, language, defaultTitle)
    }

    async setTitle(language: string, text: string, skipLanguageValidation: boolean = false) {
        await setTitle(this, this, language, text, skipLanguageValidation)
    }

    hasMissingTitle(language: string) {
        return hasMissingTitle(this.titles, language) ||
            this.finalPassages.some(p => p.hasMissingTitle(language))
    }

    getThumbnailVideoUrl(defaultValue: string = '') {
        return this.passages.find(()=> true)?.getThumbnailVideoUrl(defaultValue) || defaultValue
    }

    async setFirstPassageIsThumbnail(firstPassageIsThumbnail: boolean) {
        if (this.firstPassageIsThumbnail === firstPassageIsThumbnail) {
            return
        }
        const doc = this._toDocument({ firstPassageIsThumbnail })
        await this.db.put(doc)
    }

    // Copy a passage from another portion.
    // Includes: videos
    // Excludes: documents
    async copyPassage(oldPassage: Passage) {
        let _name = oldPassage.name
        if (oldPassage._id.startsWith(this._id)) { // copying to same portion
            _name = _name + ' 2'
        }

        await this.addPassage(_name)

        let _passage = _.findWhere(this.passages, { name: _name })
        if (!_passage) throw Error('could not find newly created Passage')

        let patchMap = new Map<string,PassageVideo>()

        // copy patches
        for (let oldVideo of oldPassage.videos) {
            if (oldVideo.removed) continue
            if (!oldVideo.isPatch) continue
            await _passage.copyVideo(oldVideo, patchMap)
        }

        // copy non patches (and update them to point to new patch copies)
        for (let oldVideo of oldPassage.videos) {
            if (oldVideo.removed) continue
            if (oldVideo.isPatch) continue
            await _passage.copyVideo(oldVideo, patchMap)
        }

        for (let oldDocument of oldPassage.documents) {
            if (oldDocument.removed) continue

            let document = _passage.createPassageDocument()
            document.creator = normalizeUsername(oldDocument.creator)
            document.text = oldDocument.text

            await _passage.addPassageDocument(document)
        }
    }
}

async function setTitle(
    dbObj: IDBObjectToDocument,
    passageOrPortion: IMultilingualTitles,
    language: string,
    text: string,
    skipLanguageValidation: boolean = false
) {
    if (!skipLanguageValidation)
        validateIso639dash1LanguageCode(language)
    if (text.trim() === passageOrPortion.getTitleOrDefault(language, ''))
        return // already set
    // DEBUG: console.log({_id: dbObj._id, language, text })
    const doc = dbObj._toDocument({ titles: [{ language, text }] })
    await dbObj.db.put(doc)
}

function hasMissingTitle(
    titles: MultilingualString[],
    languageCode: string
) {
    return !titles.some(t => t.language === languageCode)
}

interface IVideoDownloader {
    queryVideoDownload: (_id: string) => Promise<IVideoDownloadQuery>
}

export class PassageDocument extends MoveableDBObject {
    // If text starts with 's3:' then it is the url of the document in S3
    @observable text = ''
    @observable title: string|undefined
    @observable editable = true

    // textHistory and creationHistory are parallel arrays.
    // The 0'th entry in each array corresponds to the oldest version of the document.
    textHistory: string[] = []
    creationHistory: string[] = []  // creator + '| + modDate
    private s3Url: string = '' // use getS3Url() to access

    constructor(_id: string, db: IDB) {
        super(_id, db)
    }

    toDocument() {
        let { title, text, editable, rank } = this
        return this._toDocument({ title, text, editable, rank, model: 5 })
    }

    getTitle() {
        if (this.text.startsWith('s3:')) return ''

        let element = document.createElement('div')
        element.innerHTML = this.text
        let firstChild = element.childNodes[0]
        let textContent = firstChild?.textContent
        let innerText = textContent?.slice(0, 8).trim().split(' ')[0] ?? ''
        element.remove()
        return innerText
    }

    // True if the text of this document is availabled
    loaded(_text: string) {
        return !_text.startsWith('s3:')
    }

    getS3Url() {
        if (this.s3Url) return this.s3Url
        if (this.text.startsWith('s3:')) return this.text
        return ''
    }

    async loadText(_text: string, videoDownloader: IVideoDownloader): Promise<string> {
        this.s3Url = _text
        const timeout = (ms: number) => new Promise(res => setTimeout(res, ms))

        while (true) {
            let response = await videoDownloader.queryVideoDownload(_text.slice(3))
            if (response.blob) {
                let downloadedText = await response.blob.text()
                return downloadedText
            }

            await timeout(250)
        }
    }

    async getText(historyIndex: number, videoDownloader: IVideoDownloader) {
        if (historyIndex === 0) {
            if (!this.loaded(this.text)) {
                this.text = await this.loadText(this.text, videoDownloader)
            }
            return this.text
        }

        let i = this.textHistory.length - historyIndex
        if (i < 0) {
            log('### Invalid index passed to getText')
            return ''
        }

        if (!this.loaded(this.textHistory[i])) {
            this.textHistory[i] = await this.loadText(this.textHistory[i], videoDownloader)
        }
        return this.textHistory[i]
    }

    // Set text. If long push to s3 and make text be a reference to that bucket.
    async setText(text: string, projectName: string, newTitle = '', shouldBeacon = false) {
        log('PassageDocument setText', fmt({_id: this._id, text, projectName}))

        text = text.trim()
        if (this.text === text) {
            return
        }
        const originalText = text

        const modDate = this.db.getDate()
        const { _id, text: oldText, modDate: prevModDate, modBy: prevModBy, title: originalTitle, textHistory, creationHistory, ...rest} = this.toSnapshot()

        const doBeacon = (phase: 'prepped' | 'putted', { s3Path = '', doc = '' }) => sendBeacon('PassageDocument', {
            __phase: phase,
            _id, _title: (newTitle || originalTitle), doc, modBy: this.db.username, textLength: originalText.length,
            s3Path, modDate, textHistoryLength: textHistory.length, text: originalText, pasDoc: rest,
            creationHistory: stringifyCreationHistoryForBeacon(creationHistory), textHistory: JSON.stringify(textHistory)
        })

        if (text.length > 50000) {
            let blob = new Blob([text], { type: 'text/plain' })
            let s3SafeModDate = (new Date(modDate)).toISOString()
            s3SafeModDate = s3SafeModDate.replace(/[\/:\-]/g, '_')

            let s3Path = `${projectName}/${this._id}/${s3SafeModDate}.txt`
            log('s3Path', s3Path)

            if (shouldBeacon) {
                doOnceWhenBackOnline(
                    `pasDoc setText s3Path: ${this._id}/${this.creationHistory.length}`,
                    () => doBeacon('prepped', { s3Path })
                )
            }

            let url = await Project.copyFileToVideoCache(blob as File, s3Path, undefined /* unused */, false /* wait until acceptPassageDocument() */)

            text = 's3:' + url // url will end with blob count like 's3:{s3Path}-{blobCount}'
        } else {
            if (shouldBeacon) {
                doOnceWhenBackOnline(
                    `pasDoc setText ${this._id}/${this.creationHistory.length}`,
                    () => doBeacon('prepped', {})
                )
            }
        }

        let doc = this._toDocument({ text, modDate, model: 5 })
        await this.db.put(doc) // should trigger pending uploads in acceptPassageDocument()
        doOnceWhenBackOnline(
            `pasDoc setText put ${this._id}/${this.creationHistory.length}`,
            () => doBeacon('putted', { doc: JSON.stringify(doc) }
        ))
        if (!!newTitle) {
            await this.setTitle(newTitle)
        }
    }

    async setTitle(title: string) {
        title = title.trim()
        if (this.title === title) { return }

        let doc = this._toDocument({ title, model: 5 })
        await this.db.put(doc)
    }

    async setEditable(editable: boolean) {
        if (this.editable === editable) {
            return
        }
        let doc = this._toDocument({ editable, model: 5 })
        await this.db.put(doc)
    }
}

export type PassageContentType = (
    'Introduction' |
    'Translation' |
    'Other' |
    'Nonpublishable' |
    'Introduction+Translation' |
    'Introduction+Translation+Other' )

export function getPassageContentTypes() {
    return [
        'Introduction',
        'Translation',
        'Other',
        'Nonpublishable',
        'Introduction+Translation',
        'Introduction+Translation+Other'
    ]
}

export class  MultilingualString {
    language: string = ''  // ISO 639-1
    @observable text: string = ''

    constructor(language: string, text: string) {
        this.language = language
        this.text = text
    }
}

interface IDBObjectToDocument extends IDBObject {
    _toDocument: (init: any) => any
    db: IDB
}

export interface IMultilingualTitles {
    titles: MultilingualString[]
    getTitleOrDefault: (language: string, defaultTitle: string) => string
    setTitle: (language: string, text: string) => void
    hasMissingTitle: (languageCode: string) => boolean
}

export class Passage extends MoveableDBObject implements IMultilingualTitles, IDBObjectToDocument {
    @observable name: string = ''
    @observable difficulty = 1.0
    @observable assignee = ''
    @observable documents: PassageDocument[] = []
    @observable videos: PassageVideo[] = []
    @observable _rev = 0
    @observable compressionProgressMessage = ''    // not persisted
    @observable contentType: PassageContentType = 'Translation'
    @observable hashtags = ''
    @observable thumbnailVideos: PassageThumbnailVideo[] = [] // currently expect one and only one (for TRL)

    @observable titles: MultilingualString[] = []

    @observable references: RefRange[] = []

    // When a video has been externally published using a service like Vimeo, 
    // this is the url. Currently we only support this for TRL videos.
    @observable publishedUrl = ''

    versification = 'English'   // do not persist, since this is currently hardcoded

    terms_rev = -1 // project.terms_rev at time termsByGloss set, -1 if stale
    // must -1 this when references changes
    termsByGloss = new Map<string, ProjectTerm[]>()

    @computed get videoBeingCompressed() {
        return this.compressionProgressMessage.trim() !== ''
    }

    // Get a map from gloss to terms which use that gloss for this passage.
    getTermsByGloss(project: Project, video: PassageVideo | null) {
        if (this.terms_rev === project.terms_rev) return this.termsByGloss

        let tams = project.getTermsAndMeaningsByRef(this.allReferences(video), false)
        let _terms = tams.map(tam => tam.term)
        let passageTerms = _.compact(_terms)

        let termsByGloss = new Map<string, ProjectTerm[]>()

        for (let term of passageTerms) {
            let glosses = term!.getGlosses()
            for (let gloss of glosses) {
                gloss = gloss.toLowerCase()
                let glossTerms = termsByGloss.get(gloss) ?? []
                glossTerms.push(term!)
                termsByGloss.set(gloss, glossTerms)
            }
        }

        this.termsByGloss = termsByGloss
        this.terms_rev = project.terms_rev

        log('getTermsByGloss recalc', s([...termsByGloss.keys()]))

        return termsByGloss
    }

    allReferences(video: PassageVideo | null) {
        let { references } = this
        if (references.length) return references

        let _references: RefRange[] = []

        if (!video) return _references

        for (let ref of video.references) {
            for (let rr of ref.references) {
                _references.push(rr)
            }
        }

        return _references
    }

    /**
     * The task (aka status) for this passage is the status of the most recent (undeleted) video
     * that has a status.
     */
    @computed get task() {
        let vnds = this.videosNotDeleted
        let i = _.findLastIndex(vnds, v => v.status)
        return i < 0 ? '' : vnds[i].status
    }

    constructor(_id: string, db: IDB) {
        super(_id, db)
    }

    toDocument() {
        let { name, rank, difficulty, assignee, contentType, references } = this
        let serializedReferences = JSON.stringify(references)
        return this._toDocument({ name, rank, difficulty, assignee, contentType, references: serializedReferences })
    }

    dbg() {
        return this.toDocument()
    }

    async setAssignee(assignee: string) {
        if (this.assignee === assignee) {
            return
        }
        let doc = this._toDocument({ assignee, model: 7 })
        await this.db.put(doc)
    }

    async setReferences(references: RefRange[]) {
        let serializedReferences = JSON.stringify(references)
        if (JSON.stringify(this.references) === serializedReferences) {
            log('setReferences no change')
            return
        }

        log('setReferences', serializedReferences)
        let doc = this.toDocument()
        doc.references = serializedReferences
        await this.db.put(doc)

        this.terms_rev = -1 // force recalc of termsByGloss based on new references
    }

    async setPublishedUrl(publishedUrl: string) {
        if (this.publishedUrl === publishedUrl) {
            return
        }
        const doc = this._toDocument({ publishedUrl })
        await this.db.put(doc)
    }

    // All unresolved notes for this passage ordered by increasing position
    get notes() {
        let notes: PassageNote[] = []

        this.videos.forEach(video => {
            video.notes
                .filter(note => !note.resolved)
                .forEach(note => notes.push(note))
        })

        return notes.sort((a, b) => a.position - b.position)
    }

    async setName(name: string) {
        if (name === this.name) return

        let doc = this.toDocument()
        doc.name = name
        await this.db.put(doc)
    }

    createPassageDocument() {
        let newId = this.db.getNewId(this.documents, new Date(Date.now()), 'pasDoc_')
        let passageDoc = new PassageDocument(this._id + '/' + newId, this.db)
        let rank = 100
        if (this.documents.length > 0) {
            rank = this.documents.slice(-1)[0].rankAsNumber + 100
        }
        passageDoc.rank = DBObject.numberToRank(rank)
        passageDoc.title = ''
        return passageDoc
    }

    async addPassageDocument(passageDocument: PassageDocument) {
        await this.db.put(passageDocument.toDocument())
        let _document = this.documents.find(d => d._id === passageDocument._id)
        if (!_document) {
            throw new Error('Could not find passage document we just added')
        }
        return _document
    }

    async removePassageDocument(_id: string) {
        await remove(this.documents, _id)
    }

    async pinDocument(_id: string) {
        // for now, use document rank for pin indicator
        const _document = this.documents.find(d => d._id === _id)
        if (!_document) {
            throw new Error('Could not find passage document to pin first')
        }
        // setRank to pin to top of list
        await this.moveDocument(_id, 0)
    }

    async moveDocument(_id: string, i: number) {
        const idx = _.findIndex(this.documents, { _id })
        if (idx === -1) throw Error(`moveDocument: _id not found [${_id}]`)
        await move(this.documents, idx, i)
    }

    createVideo(projectName: string) {
        let creationDate = this.db.getNewId(this.videos, new Date(Date.now()))
        let itemId = this._id + '/' + creationDate
        let video = new PassageVideo(itemId, this.db)

        video.url = `${projectName}/${itemId}`

        return video
    }

    async addVideoWithDefaultSegment(video: PassageVideo) {
        let _video = await this.addVideo(video)
        await _video.addSegment(0)
        let updatedVideo = this.videos.find(v => _video._id === v._id)
        if (!updatedVideo) {
            throw Error('Could not find video we just added segment to: ' + _video._id)
        }
        if (this.videosNotDeleted.length > 1) {
            let mostRecentDraft = this.videosNotDeleted.slice(-2)[0]
            await this.copyOverReferences(mostRecentDraft, updatedVideo)
        }
        return updatedVideo
    }

    // Copy over references from oldVideo to newVideo. If any references exist beyond the
    // length of newVideo, copy over the 1st one and give it a position of newVideo.duration.
    // Do not copy over other references that exist beyond the end of the video.
    private async copyOverReferences(oldVideo: PassageVideo, newVideo: PassageVideo) {
        let visibleReferences = oldVideo.getVisibleReferences(this)
        let sorted = _.sortBy(visibleReferences, 'time')
        for (let el of sorted) {
            let { references, time } = el
            let newRefs = references.map(ref => new RefRange(ref.startRef, ref.endRef))
            if (time > newVideo.duration) {
                await newVideo.addReference(newRefs, newVideo.duration)
                break
            } else {
                await newVideo.addReference(newRefs, time)
            }
        }
    }

    async addVideo(video: PassageVideo) {
        await this.db.put(video.toDocument())
        let _video = this.findVideo(video._id)
        if (!_video) { throw Error('could not find video we just added! ' + video._id) }
        return _video
    }

    async addPatchVideo(video: PassageVideo, patch: PassageVideo, segment: PassageSegment) {
        let _patch = this.findVideo(patch._id)
        if (_patch) throw Error('Patch already added')

        // Add patch to list of videos for this passage.
        // Persist it to DB.
        patch.isPatch = true
        _patch = await this.addVideo(patch)

        // Create a segment with labels and matching base segment
        let { labels, references, cc } = segment
        await _patch.addSegment(0, labels, references, cc)

        await segment.addVideoPatchToHistory(_patch)
        await video.updateVersion() // force display of video to redraw

        video.log(this, 'addPatchVideo DONE')

        return video
    }

    async deletePatchVideo(existingVideo: PassageVideo, patch: PassageVideo, segment: PassageSegment) {
        let exists = this.videos.find(v => v._id === patch._id)
        if (!exists || !patch.isPatch || !segment.videoPatchHistory.includes(patch._id)) {
            return
        }
        let { notes, glosses } = patch
        for (const note of notes) {
            for (const item of note.items) {
                await note.removeItem(item._id)
            }
            await patch.removeNote(note._id)
        }
        for (const gloss of glosses) {
            await patch.removeGloss(gloss._id)
        }
        await segment.removeVideoPatchFromHistory(patch)
        await this.removeVideo(patch._id)
        await existingVideo.updateVersion()
    }

    async removeVideo(_id: string) {
        await remove(this.videos, _id)
    }

    async undeleteVideo(video: PassageVideo) {
        if (video.removed) {
            let doc = video._toDocument({})
            doc.removed = false
            await this.db.put(doc)
        }
    }

    async uploadFile(file: File,
                    creationDate: Date,
                    projectName: string,
                    portionName: string,
                    onprogress: (event: any) => void)
            : Promise<PassageVideo>
    {
        let dateId = this.db.getNewId(this.videos, creationDate)
        let itemId = this._id + '/' + dateId

        let video = new PassageVideo(itemId, this.db, creationDate)

        let baseUrl = `${projectName}/${itemId}`

        if (!Project.copyFileToVideoCache) throw Error('Project.copyFileToVideoCache not set')

        video.mimeType = file.type
        video.url = await Project.copyFileToVideoCache(file, baseUrl, video.creationDate)
        video.duration = await getVideoDuration(file)

        return video
    }

    videod() {
        return this.videos.length > 0
    }

    get videosNotDeleted() {
        // Videos with _rev === 0 are still being updated by DBAcceptor and should be
        // ignored until that process is completed.
        return this.videos.filter(video => !video.removed && !video.isPatch && video._rev > 0)
    }

    hasVideo() {
        return this.videosNotDeleted.length > 0
    }

    getDefaultVideo(_id: string | null) {
        let { videosNotDeleted } = this
        let video = _.findWhere(videosNotDeleted, { _id })

        if (!video) {
            if (this.videosNotDeleted.length > 0) {
                video = this.videosNotDeleted.slice(-1)[0]
            }
        }

        return video || null  // ensure we return null instead of underfined
    }

    async setDifficulty(difficulty: number) {
        difficulty = difficulty || 0

        if (difficulty < 0 || this.difficulty === difficulty) {
            return
        }

        let doc = this._toDocument({})
        doc.difficulty = difficulty
        await this.db.put(doc)
    }

    async setContentType(contentType: PassageContentType) {
        if (contentType === this.contentType) {
            return
        }

        let doc = this._toDocument({})
        doc.contentType = contentType
        await this.db.put(doc)
    }

    async setHashtags(hashtags: string) {
        hashtags = hashtags.trim()

        if (hashtags === this.hashtags) {
            return
        }

        let doc = this._toDocument({})
        doc.hashtags = hashtags
        await this.db.put(doc)
    }

    hashtagsArray() {
        let parts = this.hashtags.split(' ').filter(tag => tag)
        return parts.map(part => '#' + part.replace(/^#+/, ''))
    }

    hasHashtag(tag: string) {
        let hashtags = this.hashtagsArray().map(_tag => _tag.toLowerCase())
        tag = tag.toLowerCase()
        return hashtags.includes(tag)
    }

    // Find video in this passage.
    // Returns undefined if not found.
    // _id can be for a video or any of its subobject (e.g. PassageNote)
    findVideo(_id: string) {
        let video = this.videos.find(v => _id.startsWith(v._id))
        if (!video) {
            // log(`###findVideo failed passage=${this._id}, video=${_id}`)
        }

        return video
    }

    findSegment(_id: string) {
        let video = this.findVideo(_id)
        let segment = video?.segments.find(s => _id.startsWith(s._id))

        return segment
    }

    // Find note in this passage.
    // Returns undefined if not found.
    // _id can be for a note or any of its subobject (e.g. PassageNoteItem)
    findNote(_id: string) {
        let video = this.findVideo(_id)
        let note = video?.notes.find(n => _id.startsWith(n._id))
        if (!note) {
            // log(`###findNote failed passage=${this._id}, note=${_id}`)
        }

        return note
    }

    firstUnviewedNote(username: string, cutoff: Date, includeConsultantOnlyNotes: boolean) {
        let videos = this.videosNotDeleted
        let mostRecentVideo = videos[videos.length - 1]
        return mostRecentVideo?.firstUnviewedNoteAfterDate(this, username, cutoff, includeConsultantOnlyNotes) || null
    }

    firstUnresolvedNoteOnLatestVideo(cutoff: Date, includeConsultantOnlyNotes: boolean) {
        let videos = this.videosNotDeleted
        let mostRecentVideo = videos[videos.length - 1]
        return mostRecentVideo?.mostRecentUnresolvedNote(this, cutoff, includeConsultantOnlyNotes) || null
    }

    getUnresolvedNote(cutoff: Date, includeConsultantOnlyNotes: boolean) {
        let newestToOldestVideos = [...this.videosNotDeleted].reverse()
        for (let video of newestToOldestVideos) {
            let note = video.mostRecentUnresolvedNote(this, cutoff, includeConsultantOnlyNotes)
            if (note) {
                return note
            }
        }
        return null
    }

    firstUnviewedVideo(username: string, cutoff: Date) {
        let videos = this.videosNotDeleted
        let mostRecentVideo = videos[videos.length - 1]
        return mostRecentVideo?.isUnviewedAfterDate(username, cutoff) ? mostRecentVideo : null
    }

    async copyVideo(oldVideo: PassageVideo,
        patchMap: Map<string, PassageVideo>  // key: old patch video _d, value: new patch video _id
        )
    {
        let video = this.createVideo(oldVideo.url.split('/')[0])

        video.url = oldVideo.url
        video.duration = oldVideo.duration

        let _video = await this.addVideo(video)
        patchMap.set(oldVideo._id, _video) // remember new _id for this video

        if (oldVideo.isPatch) {
            await _video.setIsPatch()
        }

        for (let oldSegment of oldVideo.segments) {
            if (oldSegment.removed) continue

            let { position, labels, references, cc, videoPatchHistory } = oldSegment
            let {segment: _segment} = await _video.addSegment(position, labels, references, cc )

            // Update to point to new patch video copies
            for (let patchId of oldSegment.videoPatchHistory) {
                let patch = patchMap.get(patchId)
                if (patch) {
                    await _segment.addVideoPatchToHistory(patch)
                } else {
                    // should never get here!
                    debugger
                }
            }

            for (let oldGloss of oldSegment.glosses) {
                await _segment.setGloss(oldGloss.identity, oldGloss.gloss)
            }
        }

        for (let oldNote of oldVideo.notes) {
            let _note = _video.createNote(oldNote.position)
            _note.description = oldNote.description
            _note.type = oldNote.type
            _note.canResolve = oldNote.canResolve
            _note.startPosition = oldNote.startPosition
            _note.endPosition = oldNote.endPosition

            await _video.addNote(_note)
            _note = this.findNote(_note._id)!

            for (let oldItem of oldNote.items) {
                if (oldItem.removed) continue

                let _item = _note.createItem()

                _item.duration = oldItem.duration
                _item.fileType = oldItem.fileType
                _item.url = oldItem.url
                _item.text = oldItem.text
                _item.resolved = oldItem.resolved
                _item.unresolved = oldItem.unresolved
                _item.consultantOnly = oldItem.consultantOnly
                _item.creator = normalizeUsername(oldItem.creator)
                _item.creationDate = oldItem.creationDate

                await _note.addItem(_item, this)
            }
        }

        await _video.copyGlosses(oldVideo.glosses)
    }

    getLatestVideo(): PassageVideo | null {
        let video = this.videosNotDeleted.slice(-1)[0]
        return video || null  // ensure we return null instead of underfined
    }

    displayName(_rt: IRoot) {
        let { project } = _rt

        let portion = project.findPortion(this._id)
        let name = `${portion?.name ?? '???'} / ${this.name}`

        return name
    }

    getTitleOrDefault(language: string, defaultTitle: string): string {
        return getTitleOrDefault(this.titles, language, defaultTitle)
    }

    async setTitle(language: string, text: string, skipLanguageValidation: boolean = false) {
        await setTitle(this, this, language, text, skipLanguageValidation)
    }

    hasMissingTitle(language: string) {
        return hasMissingTitle(this.titles, language)
    }

    getThumbnailVideoUrl(defaultUrl: string = '') {
        return this.thumbnailVideos.find(() => true)?.url || defaultUrl
    }

    private async _uploadThumbnailVideo(file: File, baseUrl: string, videoThumbnail: PassageThumbnailVideo) {
        if (!Project.copyFileToVideoCache) throw Error('Project.copyFileToVideoCache not set')
        videoThumbnail.fileType = file.type
        videoThumbnail.url = await Project.copyFileToVideoCache(file, baseUrl)
        videoThumbnail.size = file.size
        return videoThumbnail.url
    }

    async uploadThumbnailVideo(projectName: string, file: File, { srcVideoUrl, selectionStartTime, selectionEndTime }: IThumbnailVideoSrc) {
        const creationDate = new Date(Date.now())
        const taggedCreationDateId = this.db.getNewId(this.thumbnailVideos, creationDate, 'thumbVid_')
        const itemId = this._id + '/' + taggedCreationDateId
        const baseUrl = `${projectName}/${itemId}`
        const preUploadUrl = `${baseUrl}-1` // temporary for pre-upload validation
        const thumbnail = createThumbnailVideo(this.db,
            itemId, {
                url: preUploadUrl, fileType: file.type, size: file.size,
                srcVideoUrl, selectionStartTime, selectionEndTime
            }
        )
        thumbnail.validate(projectName, this._id)
        thumbnail.url = await this._uploadThumbnailVideo(file, baseUrl, thumbnail)
        return await this.addThumbnailVideo(thumbnail, projectName)
    }

    async addThumbnailVideo(videoThumbnail: PassageThumbnailVideo, projectName?: string) {
        if (projectName) {
            videoThumbnail.validate(projectName, this._id)
        }
        const doc = videoThumbnail.toDocument() // model 14
        await this.db.put(doc)
        const _videoThumbnail = this.thumbnailVideos.find(v => videoThumbnail._id.startsWith(v._id))
        if (!_videoThumbnail) { throw Error('could not find thumbnailVideo we just added!') }
        return _videoThumbnail
    }
}

export class PassageVideoReference extends DBObject {
    @observable references: RefRange[] = []
    @observable position = 0    // position after the start of the containing video
    time: number = 0   // time for this reference in main video timeline

    // We track this because we want to delete newly created references on ESC
    newlyCreated = false
    segmentIndex = 0    // which segment is this in on the base (non-patched) segment?

    constructor(_id: string, db?: IDB) {
        super(_id, db)
    }

    toDocument() {
        let { references, position } = this
        let serializedReferences = JSON.stringify(references)
        return this._toDocument({ references: serializedReferences, position, model: 6 })
    }

    dbg() {
        let doc = this.toDocument()
        return doc
    }

    async setReferences(references: RefRange[]) {
        let serializedReferences = JSON.stringify(references)

        log('setReferences', serializedReferences)
        if (JSON.stringify(this.references) === serializedReferences) {
            log('setReferences no change')
            return
        }

        this.references = references
        let doc = this._toDocument({
            references: JSON.stringify(this.references),
            model: 6
        })

        await this.db.put(doc)
    }

    async setPosition(position: number) {
        if (this.position === position) {
            log('setPosition ! position changed')
            return
        }

        let doc = this._toDocument({ position, model: 6 })
        log('setPosition', fmt(doc))
        this.db.submitChange(doc)
    }

    canChangePositionToTime(time: number, passageVideo: PassageVideo, passage: Passage) {
        let visibleReferences = passageVideo.getVisibleReferences(passage)
                                    .filter(ref => ref._id !== this._id)
        let tooCloseToReference = visibleReferences.find(ref => Math.abs(time - ref.time) < 0.2)
        return !tooCloseToReference && time >= 0 && time <= passageVideo.computedDuration
    }
}


interface IThumbnailVideoSrc {
    srcVideoUrl: string
    selectionStartTime: number
    selectionEndTime: number
}
interface IThumbnailVideo extends IThumbnailVideoSrc {
    url: string
    fileType: string
    size: number
}

/*
    _id: portion_time/passage_time/'thumbVid_' + thumbnail_time |
    url: projectName/{_id} 
*/
export class PassageThumbnailVideo extends DBObject implements IThumbnailVideo {
    url: string = ''
    srcVideoUrl: string = ''
    selectionStartTime: number = -1
    selectionEndTime: number = -1
    fileType: string = 'video/mp4'
    size: number = 0

    constructor(_id: string, db?: IDB) {
        super(_id, db)
    }

    validate(projectName: string, passageId: string) {
        if (!this._id || !this._id.startsWith(passageId)) {
            throw new Error(`Thumbnail video _id (${this._id}) must start with the passage id (${passageId})`)
        }
        const expectedUrl = `${projectName}/${this._id}-1`
        if (this.url !== expectedUrl) {
            throw new Error(`Thumbnail video url (${this.url}) must be: ${expectedUrl}`)
        }
        if (this.selectionStartTime < 0) {
            throw new Error(`Thumbnail video selectionStartTime (${this.selectionStartTime}) must be >= 0`)
        }
        const expectedSourceVideoUrlStart = `${projectName}/${passageId}`
        if (!this.srcVideoUrl || !this.srcVideoUrl.startsWith(expectedSourceVideoUrlStart)) {
            throw new Error(`Thumbnail video srcVideoUrl (${this.srcVideoUrl}) must start with: ${expectedSourceVideoUrlStart}`)
        }
        if (this.selectionEndTime <= this.selectionStartTime) {
            throw new Error(`Thumbnail video selectionEndTime (${this.selectionEndTime}) must be > selectionStartTime (${this.selectionStartTime})`)
        }
        const selectionDuration = this.selectionEndTime - this.selectionStartTime
        if (selectionDuration < 1) {
            throw new Error(`Thumbnail video selection duration (${selectionDuration}) must be >= 1`)
        }
        if (this.fileType !== 'video/mp4') {
            throw new Error(`Thumbnail video fileType (${this.fileType}) must be 'video/mp4'`)
        }
        if (Math.floor(selectionDuration) > 10) {
            throw new Error(`Thumbnail video selection duration (${selectionDuration}) must be <= 10`)
        }
        if (this.size <= 0 || this.size >= 2000000) {
            throw new Error(`Thumbnail video size (${this.size}) must be > 0 and < 2000000`)
        }
    }

    toDocument() {
        const {
            url, srcVideoUrl, selectionStartTime, selectionEndTime, fileType, size
        } = this
        return this._toDocument({ model: 14, url, srcVideoUrl, selectionStartTime, selectionEndTime, fileType, size })
    }
}

export class FfmpegParameters {
    @observable inputOptions: string[] = []
    @observable audioFilters: string[] = []
    @observable videoFilters: string[] = []
    @observable complexFilter: string[] = []
    @observable complexFilterOutputMapping: string[] = []
    @observable outputOptions: string[] = []
}

export class VideoSlice {
    constructor(public video: PassageVideo,
        public position: number,
        public endPosition: number,
        public src: string) {}
}

export interface IDrawableSegment {
    segment: PassageSegment,
    time: number,
}

export class PassageVideo extends DBObject {
    @observable label: string = '' // User can provide instead of creationDate
    url: string = ''
    duration: number = 0  // Duration as filmed.
    computedDuration: number = 0  // Duration taking into account adjustments to segment length.
    isPatch = false
    @observable ffmpegParametersUsed?: FfmpegParameters

    @observable version = 0

    // This field is taken from the 'id' (NOT! _id) field of ProjectTask.
    @observable status: string = ''
    // Date/time when passage video status changed
    @observable statusModDate = ''

    @observable _rev = 0

    @observable notes: PassageNote[] = []
    @observable segments: PassageSegment[] = []
    @observable glosses: PassageGloss[] = []
    @observable highlights: PassageHighlight[] = []
    @observable references: PassageVideoReference[] = []

    // email address of people who have viewed this
    @observable viewedBy: string[] = []
    @observable uploaded = false
    @observable mimeType = ''

    // These items are not persisted in online store
    @computed get isCompressed() {
        return !!this.ffmpegParametersUsed
    }

    @computed get resolution() {
        let resElement = this.ffmpegParametersUsed?.videoFilters.find(el => el.startsWith('scale'))
        if (resElement === undefined) {
            return -1
        }

        let num = parseInt(resElement.slice(resElement.search(':') + 1))
        if (isNaN(num) || !isFinite(num)) {
            return -1
        }

        return num
    }

    @computed get crf() {
        if (!this.ffmpegParametersUsed) {
            return -1
        }
        let searchString = '-crf '
        let crfElement = this.ffmpegParametersUsed.outputOptions.find(el => el.startsWith(searchString))
        if (crfElement === undefined) {
            return -1
        }

        let num = parseInt(crfElement.slice(searchString.length))
        if (isNaN(num) || !isFinite(num)) {
            return -1
        }

        return num
    }

    segmentTimesSet = false

    constructor(_id: string, db?: IDB, creationDate?: Date) {
        super(_id, db)
        let creationDateString = this.creationDate
        if (creationDate) {
            creationDateString = creationDate.toISOString() // This is probably a bug. Look at _LevelupDB.getDate
        }
        this.creationDate = creationDateString
        this.setUMTRank()
    }

    resetSegmentTimes() {
        this.segmentTimesSet = false
    }

    toDocument() {
        let { url, duration, status, isPatch, version, viewedBy, uploaded, rank, ffmpegParametersUsed, mimeType } = this
        return this._toDocument({ url, duration, status, isPatch, version, viewedBy,
            uploaded, rank, ffmpegParametersUsed: JSON.stringify(ffmpegParametersUsed), mimeType })
    }

    dbg(passage: Passage | null, details?: string) {
        let doc = this.toDocument()

        if (details?.includes('n')) {
            let notes = this.notes
            if (passage) {
                notes = this.getVisibleNotes(passage, true)
            }
            doc.notes = notes.map(note => note.dbg(details))
        }

        if (details?.includes('g')) {
            doc.glosses = this.glosses.map(gloss => gloss.dbg())
        }

        if (details?.includes('s')) {
            doc.segments = this.segments.map(segment => segment.dbg(passage, details))
        } else {
            doc.segments = this.dbgSegments()
        }

        return doc
    }

    dbgSegments() {
        return this.segments.map((s, i) => ({
                segment: `${i + 1} time=${s.time?.toFixed(2)} pos=[${s.position?.toFixed(2)}..${s.endPosition?.toFixed(2)}]`,
                videoPatchHistory: s.videoPatchHistory,
            }))
    }

    async setIsPatch() {
        if (this.isPatch) {
            return
        }
        let doc = this._toDocument({})
        doc.isPatch = true
        await this.db.put(doc)
    }

    async updateVersion() {
        let doc = this._toDocument({})
        doc.version = this.version + 1
        await this.db.put(doc)
    }

    async addViewedBy(email: string) {
        let { viewedBy } = this
        email = normalizeUsername(email)

        if (email === '' || this.creator === email || viewedBy.includes(email)) return

        let doc = this._toDocument({})
        // Force the _id for a task change to be differnt than the _id for the base passage
        doc._id += '@viewedby'

        doc.viewedByUser = email
        await this.db.put(doc)
    }

    // Only used when fixing incorrect duration
    async setDuration(duration: number) {
        if (duration === this.duration) {
            return
        }
        log('setDuration', fmt({duration}))

        let doc = this._toDocument({})
        doc.duration = duration
        await this.db.put(doc)

        let segment = this.segments[0]
        await segment?.setPositions(0, duration, this)
    }

    createNote(position: number, startPosition?: number, endPosition?: number) {
        log('createNote', fmt({position, startPosition, endPosition}))

        let creationDate = this.db.getNewId(this.notes, new Date(Date.now()))
        let _id = this._id + '/' + creationDate
        let note = new PassageNote(_id, this.db)
        if(intest) position = Math.round(position*1000) / 1000  // stop jitter from failing test

        note.position = position
        if (startPosition === undefined) {
            note.setDefaultStartPosition(this.computedDuration)
            note.setDefaultEndPosition(this.computedDuration)
        } else {
            note.startPosition = startPosition
            note.endPosition = endPosition!
        }

        return note
    }

    get resolvedNotes() {
        return this.notes.filter(note => note.resolved)
                        .sort((a, b) => a.position - b.position)
    }

    get unresolvedNotes() {
        return this.notes.filter(note => !note.resolved)
                        .sort((a, b) => a.position - b.position)
    }

    async setStatus(status: string) {
        log('setStatus', status)
        if (status === this.status) {
            return
        }

        let doc = this._toDocument({})
        doc.status = status
        await this.db.put(doc)
    }

    async setLabel(label: string) {
        if (label !== this.label) {
            let doc = this._toDocument({ label })
            await this.db.put(doc)
        }
    }

    // This is a bit tricky because when the user has not yet set a status for the current video
    // we want to get the status of the most recent video that has a status.
    getStatus(passage: Passage) {
        const videos = passage.videosNotDeleted // oldest to newest
        const i = videos.findIndex(v => v._id === this._id) // find this video in passage
        if (i < 0) return ''

        const _video = videos.slice(0, i+1).filter(v => v.status).slice(-1)[0]
        return _video?.status ?? ''
    }

    async addNote(note: PassageNote): Promise<PassageVideo> {
        let noteExists = this.notes.find(n => note._id === n._id)
        if (noteExists) {
            return this
        }

        note.rank = DBObject.numberToRank(note.position)
        await this.db.put(note.toDocument())

        let _note = this.notes.find(n => note._id === n._id)
        if (!_note) {
            debugger
            throw Error('Could not find note we just created')
        }
        return this
    }

    async removeNote(_id: string) {
        await remove(this.notes, _id)
    }

    async addSegment(position: number, labels: PassageSegmentLabel[] = [], references: RefRange[] = [], cc: string = '') {
        // If segment to be added is within .1 second of an existing segment, ignore add
        let closeToStart = (ps: PassageSegment) => ps.position + .1 >= position && ps.position - .1 <= position
        let closeToEnd = (ps: PassageSegment) => ps.endPosition + .1 >= position && ps.endPosition - .1 <= position
        let closeTo = (ps: PassageSegment) => closeToStart(ps) || closeToEnd(ps)

        // If the new segment is very close to an existing segment, just use existing segment
        let segmentIndex = this.segments.findIndex(closeTo)
        if (segmentIndex >= 0) {
            return { segmentIndex, segment: this.segments[segmentIndex]}
        }

        let newId = this.db.getNewId(this.segments, new Date(Date.now()), 'seg_')
        let pvsg = new PassageSegment(this._id + '/' + newId, this.db)

        pvsg.position = position
        pvsg.rank = DBObject.numberToRank(position)
        pvsg.labels = labels
        pvsg.references = references
        pvsg.cc = cc

        // If there are no segments yet, the new segment always goes to end of video
        if (this.segments.length === 0) {
            pvsg.endPosition = this.duration
            await this.db.put(pvsg.toDocument())
            return {segmentIndex: 0, segment: this.segments[0]}
        }

        let currentSegment = this.segments.slice().reverse().find(s => s.position < position)
        if (!currentSegment) {
            throw Error(`could not find segment to insert into, position=${position}`)
        }

        pvsg.endPosition = currentSegment.endPosition
        await this.db.put(pvsg.toDocument())

        await currentSegment.setEndPosition(position, this)

        segmentIndex = this.segments.findIndex(s => s._id === pvsg._id)
        if (segmentIndex === -1) throw Error('Could not find added segment')

        return { segmentIndex, segment: this.segments[segmentIndex] }
    }

    async removeSegment(_id: string) {
        let index = this.segments.findIndex(s => s._id === _id)
        if (index < 0 || this.isPatch) return
        let segment = this.segments[index]
        let previousIndex = index - 1
        await remove(this.segments, _id)
        if (previousIndex >= 0) {
            await this.segments[previousIndex].setEndPosition(segment.endPosition, this)
        }
    }

    // Adjust the position of the current segment and the previous one when there
    // isn't a gap between them.
    async saveSurroundingSegmentPositions(passage: Passage, segmentTime: number, segment: PassageSegment) {
        let exists = passage.findSegment(segment._id) !== undefined
        if (!exists) {
            return
        }

        let baseVideo = this.baseVideo(passage) || this
        let newPosition = baseVideo.timeToPosition(passage, segmentTime)
        let index = baseVideo.segments.findIndex(s => s._id === segment._id)
        let previousSegment = baseVideo.segments[index - 1]

        // Update segment position and endPosition in a way that will prevent the
        // updates from being rejected.
        if (newPosition > segment.position) {
            await segment.setPositions(newPosition, segment.endPosition, baseVideo)
            if (previousSegment) {
                await previousSegment.setPositions(previousSegment.position, newPosition, baseVideo)
            }
        } else {
            if (previousSegment) {
                await previousSegment.setPositions(previousSegment.position, newPosition, baseVideo)
            }
            await segment.setPositions(newPosition, segment.endPosition, baseVideo)
        }
    }

    // create the segments for this selection
    async createSelectionSegment(passage: Passage, selectionStartTime: number, selectionEndTime: number) {
        let startPosition = this!.timeToPosition(passage!, selectionStartTime)
        let endPosition = this!.timeToPosition(passage!, selectionEndTime)
        if (startPosition === undefined || endPosition === undefined) { throw Error('*Could not determine selection position') }

        await this!.addSegment(endPosition)
        let { segment, segmentIndex } = await this!.addSegment(startPosition)
        return { segment, segmentIndex }
    }

    // Return true if a patch could be created from startTime to endTime on passageVideo.
    patchable(startTime: number, endTime: number, _displayError?: (message: string) => void) {
        let startIndex = this.timeToSegmentIndex(startTime)
        let endIndex = this.timeToSegmentIndex(endTime)

        if (startIndex === -1 || endIndex === -1) {
            _displayError && _displayError(t`Something went wrong - could not find selection.`)
            return false
        }

        if (startIndex !== endIndex) {
            _displayError && _displayError(t`Selection must not include more than one segment.`)
            return false
        }

        if (this.segments[startIndex].isPatched) {
            _displayError && _displayError(t`Cannot patch a selection in a patched segment. Delete the existing patch(es) first.`)
            return false
        }

        return true
    }

    async addGloss(position: number, text: string) {
        // If segment to be added is within .15 second of an existing segment, ignore add
        let gap = (pg: PassageGloss) => Math.abs(pg.position - position) < .15
        let gloss = this.glosses.find(gap)
        if (gloss) {
            log(`addGloss - gloss already present at this position`)
            if (text) {
                await gloss.set(gloss.position, text)
            }
            return undefined
        }

        let newId = this.db.getNewId(this.glosses, new Date(Date.now()), 'gls_')
        let pg = new PassageGloss(this._id + '/' + newId, this.db)

        pg.position = position
        pg.rank = DBObject.numberToRank(position)
        pg.text = text

        log('addGloss', pg)
        await this.db.put(pg.toDocument())
        gloss = this.glosses.find(_gloss => _gloss._id === pg._id)
        return gloss
    }

    // Used from command line to copy glosses for testing
    async copyGlosses(glosses: PassageGloss[]) {
        for (let gloss of glosses) {
            let { position, text } = gloss
            if (position <= this.duration) {
                await this.addGloss(position, text)
            }
        }
    }

    async removeGloss(_id: string) {
        await remove(this.glosses, _id)
    }

    async addNextReferenceInSequence(passage: Passage, currentTime: number) {
        let video = this.timeToVideo(passage, currentTime)
        let position = video.timeToPosition(passage, currentTime)
        let baseVideo = this.baseVideo(passage) || this
        let refRanges = baseVideo.getRefRanges(passage, currentTime)
        let previousReference = refRanges[refRanges.length - 1]
        let nextVerse = '001001001'
        if (previousReference !== undefined) {
            try {
                nextVerse = RefRange.nextVerse(previousReference.endRef, passage.versification)
            } catch (error) {
                nextVerse = '001001001'
            }
        } else if (passage.references.length > 0) {
            nextVerse = passage.references[0].startRef
        }
        let ref = new RefRange(nextVerse, nextVerse)
        let reference = await video.addReference([ref], position)
        return reference
    }

    async addReference(reference: RefRange[], position: number) {
        const tolerance = 0.2
        let gap = (ref: PassageVideoReference) => Math.abs(ref.position - position) < tolerance
        if (this.references.find(gap)) {
            log(`addReference reject gap=${tolerance}`)
            return undefined
        }

        let ref = this.createReference(position)
        ref.references = reference

        log('addReference', JSON.stringify(reference))
        let doc = ref.toDocument()
        this.db.submitChange(doc)

        // Make sure we have latest "reference" to ref
        let _ref = this.references.find(r => r._id === ref._id)
        _ref && (_ref.newlyCreated = true)

        return _ref
    }

    createReference(position: number) {
        let newId = this.db.getNewId(this.references, new Date(Date.now()), 'ref_')
        let ref = new PassageVideoReference(this._id + '/' + newId, this.db)
        ref.position = position
        ref.rank = DBObject.numberToRank(position)
        return ref
    }

    // Set the position of a verse reference, handling the case where the reference
    // is moved to another video within the same base video.
    async saveReferencePosition(passage: Passage, referenceTime: number, reference: PassageVideoReference) {
        let exists = passage.videos.some(vid => vid.references.find(ref => ref._id === reference._id))
        let oldVideo = passage.findVideo(reference._id)
        if (!exists || !oldVideo) {
            return
        }

        let baseVideo = this.baseVideo(passage) || this
        let video = baseVideo.timeToVideo(passage, referenceTime)
        let position = video.timeToPosition(passage, referenceTime)

        let _reference: PassageVideoReference | undefined = undefined
        if (video._id !== oldVideo._id) {
            await oldVideo.removeReference(reference._id)
            _reference = await video.addReference(reference.references, position)
        } else {
            await reference.setPosition(position)
            _reference = video.references.find(ref => ref._id === reference._id)
        }

        return _reference
    }

    async removeReference(_id: string) {
        let idx = _.findIndex(this.references, { _id })
        if (idx < 0) return

        let item = this.references[idx]

        let doc = item._toDocument({})
        doc.removed = true
        this.db.submitChange(doc)
    }

    // Convert segment references to verse references. Do not convert a segment's
    // references if they're empty. Attempt to update verse references that are
    // close to a segment.
    async convertReferences(passage: Passage) {
        let baseVideo = this.baseVideo(passage) || this
        let existingVerseReferences = baseVideo.getVisibleReferences(passage)
        let segments = baseVideo.visibleSegments(passage).filter(seg => seg.references.length > 0)
        for (let seg of segments) {
            let closeVerseRefs = existingVerseReferences.filter(vr => Math.abs(vr.time - seg.time) < 0.2)
            if (closeVerseRefs.length === 0) {          // Safe to create new verse reference
                let video = seg.actualVideo(passage)
                if (video) {
                    await video.addReference(seg.references, seg.position)
                }
            } else {
                for (let verseRef of closeVerseRefs) {
                    await verseRef.setReferences(seg.references)
                }
            }
        }
    }

    async addHighlight(color: number, firstId: string, lastId: string, resourceName: string) {
        // If the user selected the words by dragging right to left first and last will
        // be reversed, switch them
        if (firstId > lastId) {
            let temp = lastId
            lastId = firstId
            firstId = temp
        }

        if (color === 0) {
            // if this is a clear color request, seach throug all existing non clear color
            // highlights for this resourceName. If you don't find one that overlaps with
            // this range is nothing to clear, just return
            // Two ranges overlap if end1 >= start2 && end2 >= start1
            if (!this.highlights.find(h => h.resourceName === resourceName && h.color && lastId >= h.firstId && h.lastId >= firstId)) {
                log("addHighligh no-action-necessary")
                return
            }
        }

        let newId = this.db.getNewId(this.glosses, new Date(Date.now()), 'hgh_')
        let ph = new PassageHighlight(this._id + '/' + newId, this.db)

        ph.color = color
        ph.firstId = firstId
        ph.lastId = lastId
        ph.resourceName = resourceName

        await this.db.put(ph.toDocument())
    }

    // Convert specified time to a position offset in the segment containing that time
    timeToPosition(passage: Passage, time: number) {
        let segment: PassageSegment

        if (this.isPatch) {
            // Patch videos only have one segment
            segment = this.segments[0]
        } else {
            // Otherwise search for the segment by time
            segment = this.timeToSegment(passage, time)
            segment = segment.actualSegment(passage)
        }

        log('timeToPosition', time, segment.time, segment.position, segment.endPosition)

        return limit(segment.position + time - segment.time, segment.position, segment.endPosition)
    }

    // Return videoPassage that plays at this time.
    // Either a manin video or a patch video (if the segment has been patched)
    timeToVideo(passage: Passage, time: number) {
        let segment = this.timeToSegment(passage, time)
        return segment.patchVideo(passage) || this
    }

    visibleSegments(passage: Passage) {
        return this.segments.map(segment => segment.actualSegment(passage))
    }

    timeToSegment(passage: Passage, time: number) {
        let si = this.timeToSegmentIndex(time)
        // log(`timeToSegment[${this._id}] si=${si}`)
        return this.segments[si]
    }

    timeToSegmentIndex(time: number) {
        if (this.segments.length === 0) {
            throw Error('no segments present')
        }

        let index = this.segments.findIndex(s => s.time > time)
        if (index === -1) {
            // If there is not segment with a position greater than this position
            // default to last segment
            index = this.segments.length - 1
        }
        else if (index > 0) {
            // If not the fist segment, use the segment before this
            index = index - 1
        }
        return index
    }

    // returns { segment, segmentIndex }
    positionToSegment(position: number) {
        if (this.segments.length === 0) {
            throw Error('no segments present')
        }

        let segmentIndex = this.segments.findIndex(s => s.position > position)
        if (segmentIndex === -1) {
            // If there is not segment with a position greater than this position
            // default to last segment
            segmentIndex = this.segments.length - 1
        }
        else if (segmentIndex > 0) {
            // If not the fist segment, use the segment before this
            segmentIndex = segmentIndex - 1
        }

        let segment = this.segments[segmentIndex]

        return { segment, segmentIndex }
    }

    //??? this is using time for the base segment, not actual segment, is that OK???
    positionToTime(position: number) {
        let { segment } = this.positionToSegment(position)
        return segment.positionToTime(position)
    }

    // For a patch video find the video it is a patch of
    baseVideo(passage: Passage, dontLogError?: boolean) {
        if (!this.isPatch) return null

        for (let video of passage.videos) {
            if (video.segments.some(segment => segment.videoPatchHistory.includes(this._id))) {
                return video
            }
        }

        if (this.removed) return null

        // Should we be throwing an exception here?
        // There is not much we can do about it.
        dontLogError || log('### cannot find baseVideo for patch', fmt({
            passage: passage.name,
            id: '_' + this._id,
            creationDate: this.creationDate,
        }))

        return null
    }

    log(passage: Passage | null, label: string) {
        log('[PassageVideo] ' + label, JSON.stringify(this.dbg(passage, 's'), null, 4))
    }

    log2(tag: string, values: any) {
        let _id = this._id.split('/').slice(-1)[0]
        log(`PassageVideo[${_id}] ${tag}`, JSON.stringify(values, null, 4))
    }

    // Set the starting time in the main video timeline for each segment.
    setSegmentTimes(passage: Passage, forceReset?: boolean) {
        if (!forceReset && this.segmentTimesSet) return

        let time = 0

        if (this.isPatch) {
            // should not be calling this function for a patch segment since patch segment
            // times should be set by calling this function on the containing video
            debugger
            console.error('###setSgmentTimes called on patch segment')
            return
        }

        this.segments.forEach((segment, i) => {
            let duration = segment.duration
            segment.time = time

            // If there are patch video on this segment set all of them to start at the current
            // time. Save the duration of the last (onTop) segment as the duration of this
            // segment.
            for (let patchId of segment.videoPatchHistory) {
                let video = passage.findVideo(patchId)
                if (!video) {
                    log('setSegmentTimes no video!')
                    continue // should never happen
                }

                let segment = video.segments[0]
                segment.time = time
                duration = segment.duration
            }

            time += duration
        })

        this.computedDuration = time // set duration based on total duration of segments
        // this.log2(`setSegmentTimes[${time.toFixed(2)}]`,
        //     this.segments.map(s => ({time: s.time.toFixed(2), duration: s.duration.toFixed(2)})))

        this.segmentTimesSet = true
    }

    // For all notes in this video (including notes on patches)
    // set the note time and segmentIndex.
    private setNoteTimes(passage: Passage) {
        for (let note of this.notes) {
            if (this.segments.length === 0) {
                // 2020-06-09 there is a bug that is causing video to have not segments
                // ignore these video for now
                log(`### video has no segments ${this._id}`)
                continue
            }

            let { segmentIndex, segment } = this.positionToSegment(note.position)

            note.segmentIndex = segmentIndex
            let time = segment.positionToTime(note.position)

            let actualSegment = segment.actualSegment(passage)
            let actualSegmentEndTime = actualSegment.positionToTime(actualSegment.endPosition)

            note.time = Math.min(time, actualSegmentEndTime)

            note.onTop = !segment.isPatched
            note.inIgnoredSegment = actualSegment.ignoreWhenPlayingVideo
        }

        for (let {patchVideo, onTop, segmentIndex, actualSegment} of this.patchVideos(passage)) {
            patchVideo.setupNotesForPatch(passage, actualSegment, segmentIndex, onTop)
        }
    }

    private setReferenceTimes(passage: Passage) {
        for (let reference of this.references) {
            if (this.segments.length === 0) {
                log(`### video has no segments ${this._id}`)
                continue
            }

            let { segment, segmentIndex } = this.positionToSegment(reference.position)
            let time = segment.positionToTime(reference.position)

            let actualSegment = segment.actualSegment(passage)
            let actualSegmentEndTime = actualSegment.positionToTime(actualSegment.endPosition)

            reference.time = Math.min(time, actualSegmentEndTime)
            reference.segmentIndex = segmentIndex
        }

        for (let { patchVideo, onTop, actualSegment, segmentIndex } of this.patchVideos(passage)) {
            patchVideo.setupReferencesForPatch(passage, actualSegment, segmentIndex, onTop)
        }
    }

    // Iterate over all videos which are patches to this base (i.e. !isPatch) video.
    // Ignores (with ###log message) any videos listed as patches in videoPatchHistory
    // but not actually found in passage
    * patchVideos(passage: Passage) {
        for (let segmentIndex=0; segmentIndex<this.segments.length; ++segmentIndex) {
            let segment = this.segments[segmentIndex]
            let actualSegment = segment.actualSegment(passage)

            let { videoPatchHistory } = segment

            for (let i=0; i<videoPatchHistory.length; ++i) {
                let videoId = videoPatchHistory[i]
                let patchVideo = passage.findVideo(videoId)
                if (!patchVideo) continue  // ignore missing patch video

                let onTop = i === videoPatchHistory.length - 1

                yield ({patchVideo, segmentIndex, actualSegment, onTop})
            }
        }
    }

    // Setup time and onTop attributes for all notes on this patch
    private setupNotesForPatch(passage: Passage, actualSegment: PassageSegment, segmentIndex: number, onTop: boolean) {
        // Limit start and end times to match the top most (aka 'actual') patch (since it

        let startTime = actualSegment.time
        let endTime = actualSegment.time + actualSegment.duration

        let segment = this.segments[0]

        this.notes.forEach(note => {
            let time = segment.positionToTime(note.position)
            note.segmentIndex = segmentIndex
            note.time = limit(time, startTime, endTime)
            note.onTop = onTop
            note.inIgnoredSegment = actualSegment.ignoreWhenPlayingVideo
        })
    }

    private setupReferencesForPatch(passage: Passage, actualSegment: PassageSegment, segmentIndex: number, onTop: boolean) {
        // Limit start and end times to match the top most (aka 'actual') patch

        let startTime = actualSegment.time
        let endTime = actualSegment.time + actualSegment.duration

        let segment = this.segments[0]

        this.references.forEach(ref => {
            let time = segment.positionToTime(ref.position)
            ref.time = limit(time, startTime, endTime)
            ref.segmentIndex = segmentIndex
        })
    }

    // Find the most recent unviewed note. Unviewed notes are those created after
    // the specified cutoff date that are unviewed.
    firstUnviewedNoteAfterDate(passage: Passage, username: string, cutoff: Date, includeConsultantOnlyNotes: boolean) {
        let visibleNotes = this.getVisibleNotes(passage, includeConsultantOnlyNotes)
        let unviewedItems = visibleNotes.flatMap(note => note.unviewedItemsAfterDate(username, cutoff, includeConsultantOnlyNotes))
        unviewedItems = _.sortBy(unviewedItems, 'creationDate')
        let newestUnviewedItem = unviewedItems[unviewedItems.length - 1]
        return newestUnviewedItem ? passage.findNote(newestUnviewedItem._id) || null : null
    }

    // Find the most recent unresolved note. Unresolved notes are those created after
    // the specified cutoff date that are unresolved.
    mostRecentUnresolvedNote(passage: Passage, cutoff: Date, includeConsultantOnlyNotes: boolean) {
        let visibleNotes = this.getVisibleNotes(passage, includeConsultantOnlyNotes)
        let unresolvedItems = visibleNotes.filter(note => !note.resolved)
                        .filter(note => includeConsultantOnlyNotes || !note.consultantOnly)
                        .flatMap(note => note.items)
                        .filter(item => newerThanCutoffDate(item.creationDate, cutoff))
        unresolvedItems = _.sortBy(unresolvedItems, 'creationDate')
        let newestUnresolvedItem = unresolvedItems[unresolvedItems.length - 1]
        return newestUnresolvedItem ? passage.findNote(newestUnresolvedItem._id) || null : null
    }

    isUnviewedAfterDate(username: string, cutoff: Date) {
        let newerThanCutoff = newerThanCutoffDate(this.creationDate, cutoff)
        let isCreator = this.creator === username
        let hasViewed = this.viewedBy.includes(username)
        return newerThanCutoff && !isCreator && !hasViewed
    }

    // Return a a list of all the notes for this video, sorted by ascending time
    // ??? does this need to deal with resolved status?
    getVisibleNotes(passage: Passage, showConsultantOnlyNotes: boolean) {
        if (!this.verifyIsBaseVideo()) return []

        let notes = [ ...this.notes ]
        for (let { patchVideo } of this.patchVideos(passage)) {
            notes = notes.concat(patchVideo.notes)
        }

        // If all items have been deleted from note and the note description is empty, do not display it
        notes = notes.filter(note => note.items.length > 0 || note.description.trim())

        this.setSegmentTimes(passage) // must be called before setNoteTimes
        this.setNoteTimes(passage)

        notes = notes.filter(note => showConsultantOnlyNotes || !note.consultantOnly)

        notes = _.sortBy(notes, 'time')
        // this.log('getVisibleNotes', {_: notes.map(note => note.time.toFixed(2))})

        return notes
    }

    verifyIsBaseVideo() {
        if (this.isPatch) {
            debugger
            log(`### Is not base video ${this._id}`)
            return false
        }

        return true
    }

    // Return a a list of all the notes for this video with x/y/width values set
    // forceSmallMarkers is only used for unit testing
    getDrawableNotes(passage: Passage, noteBarWidth: number, showConsultantOnlyNotes: boolean, forceSmallMarkers?: boolean) {
        let notes = this.getVisibleNotes(passage, showConsultantOnlyNotes)

        let useSmallMarkers = forceSmallMarkers || (notes.length > 12)

        let markerWidth = useSmallMarkers ? smallNoteMarker : largeNoteMarker
        notes.forEach(note => note.width = markerWidth)

        // Setup the x, y position for each note to make it not overlap other notes in the time line
        notes.forEach((note, index) => note.setupMarker(notes, index, this.computedDuration, noteBarWidth))

        this.log2('getDrawableNotes', {
            noteBarWidth,
            duration: this.computedDuration,
            _: notes.map(note => note.x.toFixed(0))})

        return notes
    }

    /*
     * Get a list of all the verse numbers (PassageVideoReference[]) that are currently visible.
     * Order them by increasing time.
     */
    getVisibleReferences(passage: Passage) {
        if (!this.verifyIsBaseVideo()) return []

        /**
         * Add all the references on upatched segments
         */
        let references: PassageVideoReference[] = []
        for (let reference of this.references) {
            let { segment } = this.positionToSegment(reference.position)
            if (!segment.isPatched) {
                references.push(reference)
            }
        }

        /**
         * Add all the references on patched videos
         */
        for (let { patchVideo, onTop } of this.patchVideos(passage)) {
            if (!onTop) continue

            for (let reference of patchVideo.references) {
                references.push(reference)
            }
        }

        this.setSegmentTimes(passage) // must be called before setReferenceTimes
        this.setReferenceTimes(passage)

        references = _.sortBy(references, 'time')
        return references
    }

    getVisibleRefRanges(passage: Passage) {
        let references = this.getVisibleReferences(passage)
        return references.flatMap(pvr => pvr.references)
    }

    // Return a a list of all the visible glosses for this base video, sorted by ascending time
    getVisibleGlosses(passage: Passage) {
        if (!this.verifyIsBaseVideo()) return []

        let glosses: PassageGloss[] = []
        this.setSegmentTimes(passage) // ensure segment times up to date

        for (let gloss of this.glosses) {
            let { segment, segmentIndex } = this.positionToSegment(gloss.position)
            // A gloss on the base video is visible if it starts in an upatched segment
            if (!segment.isPatched) {
                gloss.segmentIndex = segmentIndex
                gloss.time = segment.positionToTime(gloss.position)
                glosses.push(gloss)
            }
        }

        // A gloss on a patch video is visible if the patch is visible
        for (let { patchVideo, onTop, segmentIndex } of this.patchVideos(passage)) {
            if (!onTop) continue // if patch is not visible, skip its glosses

            let segment = patchVideo.segments[0] // a patch has exactly one segment

            for (let gloss of patchVideo.glosses) {
                gloss.segmentIndex = segmentIndex
                gloss.time = segment.positionToTime(gloss.position)
                glosses.push(gloss)
            }
        }

        glosses = _.sortBy(glosses, 'time')

        return glosses
    }

    displayedCreationDate(dateFormatter: IDateFormatter) {
        let { creationDate, version } = this
        let date = new Date(creationDate)
        let base = dateFormatter.format(date)
        let ending = version > 0 ? `-${version}` : ''
        return `${base}${ending}`
    }

    findSegmentIndex(_id: string /* segment _id */, throwIfMissing?: boolean) {
        let segmentIndex = this.segments.findIndex(s => s._id === _id)

        if (throwIfMissing && segmentIndex < 0) throw Error('Something went wrong. Could not find segment.')

        return segmentIndex
    }

    // Get the first non-empty reference that starts before the specified time
    getRefRanges(passage: Passage, currentTime: number) {
        let visibleReferences = this.getVisibleReferences(passage)

        let references: RefRange[] = []
        for (let ref of visibleReferences) {
            if (ref.time > currentTime) {
                break
            }
            if (ref.references.length > 0) {
                references = ref.references
            }
        }

        return references
    }

    // comments
    // logging

    // create minimum number of slices of base video and latest patches that don't
    // have gaps between them
    createSlicesWithNoGaps(passage: Passage,
            selectionStartTime: number, // -1 if no selection in effect
            selectionEndTime: number)   // -1 if no selection in effect
    {
        let baseVideo = this.baseVideo(passage) || this
        let visibleSegments = baseVideo.segments.filter(seg => !seg.actualSegment(passage).ignoreWhenPlayingVideo)
        let newSlices: VideoSlice[] = []

        for (let segment of visibleSegments) {
            let actualVideo = segment.actualVideo(passage) || baseVideo
            let actualSegment = segment.actualSegment(passage)
            let { position, endPosition } = actualSegment

            const time = actualSegment.time
            const endTime = actualSegment.time + actualSegment.endPosition - actualSegment.position
            log('createSlicesWithNoGaps', fmt({actualVideo, position, endPosition, time, endTime}))

            // If there is a selection ...
            // If this segment ends before the selection starts, skip the segment
            // If this segment starts before the selection starts, move the start position of
            // the segment forward.
            if (selectionStartTime > 0) {
                if (endTime <= selectionStartTime) continue
                if (time < selectionStartTime) {
                    position = position + selectionStartTime - time
                    log('trim position', fmt({position}))
                }
            }

            // If there is a selection ...
            // If this segment starts after the selection ends, skip the segment
            // If this segment ends after the selection ends, move the end position of
            // the segment backward.
            if (selectionEndTime > 0) {
                if (time >= selectionEndTime) continue
                if (endTime > selectionEndTime) {
                    endPosition = endPosition - (endTime - selectionEndTime)
                    log('trim endPosition', fmt({ endPosition }))
                }
            }

            // If this segment is contigous with the previous segment, adjust the end position of the
            // last slice
            let lastSlice = newSlices.slice(-1)[0] // undefined if no new slices created yet
            if (this.isContiguousVideo(lastSlice?.video._id, lastSlice?.endPosition, actualVideo._id, position)) {
                log(`adjust endPosition ${lastSlice.endPosition} = ${endPosition}`)
                lastSlice.endPosition = endPosition
            } else {
                const newSlice: VideoSlice = { video: actualVideo, position, endPosition, src: '' }
                log('newSlice', fmt(newSlice))
                newSlices.push(newSlice)
            }
        }

        return newSlices
    }

    // createSlices(passage: Passage) {
    //     let baseVideo = this.baseVideo(passage) || this
    //     let visibleSegments = baseVideo.visibleSegments(passage)
    //     let slices = []
    //     for (let segment of visibleSegments) {
    //         let actualVideo = segment.actualVideo(passage)
    //         if (!actualVideo) {
    //             throw new Error('No video for this segment')
    //         }
    //         let actualSegment = segment.actualSegment(passage)
    //         let { position, endPosition } = actualSegment
    //         slices.push(new VideoSlice(actualVideo, position, endPosition, ''))
    //     }

    //     return slices
    // }

    private isContiguousVideo(
            videoId1: string | undefined,
            endPosition: number | undefined,
            videoId2: string,
            position: number )
    {
        if (videoId1 !== videoId2) return false

        const ALittleBit = 0.05
        const closeEnough = (a: number, b: number) => Math.abs(a - b) < ALittleBit

         return closeEnough(endPosition ?? -1, position)
    }

    /**
     * Get a displayable name for this video:
     *     portion_name / passage_name creation_date - patch_version
     * When excludeLatestDate===true, omit date if this is the
     * latest video for this passage.
     */
    displayName(_rt: IRoot, excludeLatestDate?: boolean) {
        let { project, dateFormatter } = _rt

        let passage = project.findPassage(this._id)
        let name = `??? / ???`

        if (passage) {
            name = passage.displayName(_rt)
            let latestVideo = passage.getLatestVideo()
            if (this._id !== latestVideo?._id || !excludeLatestDate) {
                return name + ' ' + this.displayedCreationDate(dateFormatter)
            }
        }

        return name
    }
}

const defaultBuffer = 10
    // By default the main video corresponding to a note with be
    // +/- 10 seconds around the position of the note

const smallNoteMarker = 8
const largeNoteMarker = 15

export class PassageNote extends DBObject {
    @observable type: string = '0'
        // Shape and color of note.
        // Decode using NoteSelector.noteSelector(type)
    
    @observable description: string = '' // One line description of note
    @observable canResolve = true // true iff note is not locked
    @observable items: PassageNoteItem[] = []
    
    // Position in seconds of note in video.
    // Start and end of video citation for note.
    @observable position: number = 0
    @observable startPosition: number = 0
    @observable endPosition: number = 0
    
    @observable _rev = 0

    // The following variables are not presisted in the online store.
    // They are set by videoPassage.setupNotes

    segmentIndex: number = 0   // Index of PassageSegment in the base (i.e. !isPatch) video for this note.
    time: number = 0   // time for this note in main video timeline
    onTop = true // true if this note is not covered up by a later patch
    inIgnoredSegment = false
    y = 0 // vertical pixel offset for this note in main video timeline
    x = 0 // horizontal pixel offset
    width = 15 // marker width in timeline in pixels

    static DeletedColor = '#FFFFFF'

    constructor(_id: string, db: IDB) {
        super(_id, db)
        this.rank = DBObject.numberToRank(this.position)
    }

    toDocument() {
        let { type, description, canResolve, position, startPosition, endPosition, rank } = this
        return this._toDocument({ type, description, canResolve, position, startPosition, endPosition, rank })
    }

    dbg(details?: string) {
        let doc = this.toDocument()
        doc.time = this.time.toFixed(2)
        doc.segmentIndex = this.segmentIndex
        doc.onTop = this.onTop
        doc.y = this.y
        doc.x = this.x
        doc.width = this.width

        if (details?.includes('i')) {
            doc.items = this.items.map(item => item.dbg())
        }

        return doc
    }

    async setCanResolve(newValue: boolean) {
        if (this.canResolve === newValue) {
            return
        }
        let doc = this._toDocument({})
        doc.canResolve = newValue
        await this.db.put(doc)
    }

    async remove() {
        const doc = this._toDocument({removed: true})
        await this.db.put(doc)
    }

    async resolve(passage: Passage) {
        if (this.resolved) {
            return
        }
        let item = this.createItem()
        item.resolved = true
        await this.addItem(item, passage)
    }

    async unresolve(passage: Passage) {
        if (!this.resolved) {
            return
        }
        let item = this.createItem()
        item.unresolved = true
        await this.addItem(item, passage)
    }

    async setType(type: string) {
        let doc = this._toDocument({})
        if (this.type === type) {
            return
        }
        doc.type = type
        await this.db.put(doc)
    }

    async setDescription(description: string) {
        let doc = this._toDocument({})
        if (this.description === description) {
            return
        }
        doc.description = description
        await this.db.put(doc)
    }

    setDefaultStartPosition(duration: number) {
        this.startPosition = Math.max(this.position - defaultBuffer,  0)
    }

    setDefaultEndPosition(duration: number) {
        if (!duration) {
            // log('WARNING setDefaultEndPosition but no duration available')
            duration = this.position + defaultBuffer
        }

        this.endPosition = Math.min(this.position + defaultBuffer, duration)
    }

    async setPositions(notePosition: number, start: number, end: number) {
        let { startPosition, position, endPosition } = this

        let doc = this._toDocument({})

        if (notePosition !== position) {
            doc.position = notePosition
        }

        if (start !== startPosition) {
            // Force start position to be before position.
            doc.startPosition = Math.min(start, notePosition - 0.1)
        }

        if (end !== endPosition) {
            // Force endPosition to be at least a little bit after position
            doc.endPosition = Math.max(end, notePosition + 0.1)
        }

        doc.rank = DBObject.numberToRank(notePosition)

        // If position, startPosition, or endPosition have changed, write db entry
        if (doc.position !== undefined || doc.startPosition !== undefined || doc.endPosition !== undefined) {
            await this.db.put(doc)
        }
    }

    @computed get resolved() {
        let rsv = false
        for (let item of this.items) {
            if (item.resolved) rsv = true
            else if (item.unresolved) rsv = false
        }

        return rsv
    }

    unviewedItemsAfterDate(username: string, cutoff: Date, includeConsultantOnlyNotes: boolean) {
        if (this.resolved) {
            return []
        }
        let lastUnresolvedIndex = _.findLastIndex(this.items, item => item.unresolved)
        let itemsAfterUnresolved = this.items.slice(lastUnresolvedIndex + 1)
        return itemsAfterUnresolved.filter(item => includeConsultantOnlyNotes || !item.consultantOnly)
                        .filter(item => item.isUnviewedAfterDate(username, cutoff))
    }

    createItem() {
        let creationDate = this.db.getNewId(this.items, new Date(Date.now()))
        let itemId = this._id + '/' + creationDate
        let item = new PassageNoteItem(itemId, this.db)
        item.position = this.position

        return item
    }

    async addItem(item: PassageNoteItem, passage: Passage | null, creator?: string): Promise<PassageNote> {
        log('PassageNote addItem', item._id, this._id, passage?._id)

        let doc = item.toDocument()
        if (creator) {
            doc.creator = normalizeUsername(creator)
        }

        await this.db.put(doc)

        let note = passage?.findNote(item._id)
        if (!note) {
            debugger
            throw Error(`PassageNote not created ${this._id}, ${passage?._id}`)
        }
        return note
    }

    async removeItem(_id: string) {
        await remove(this.items, _id)
    }

    clone() {
        let updatedNote = new PassageNote(this._id, this.db)
        updatedNote.creationDate = this.creationDate
        updatedNote.rank = this.rank
        updatedNote.removed = this.removed
        updatedNote.startPosition = this.startPosition
        updatedNote.position = this.position
        updatedNote.endPosition = this.endPosition
        updatedNote._rev = this._rev
        updatedNote.type = this.type
        updatedNote.description = this.description
        updatedNote.canResolve = this.canResolve
        updatedNote.items = this.items.map(item => item.clone())
        return updatedNote
    }

    // Find the PassageVideo containing this note
    toVideo(passage: Passage) {
        for (const v of passage.videos) {
            for (const n of v.notes) {
                if (n._id === this._id) {
                    return v
                }
            }
        }
        return null
    }

    @computed get consultantOnly() {
        let items = this.items.filter(item => !item.resolved && !item.unresolved)
        if (items.length === 0) return false
        return items.every(item => item.consultantOnly)
    }

    // CALCULATE NOTE MARKER POSITION
    // Adjust width, y, time to avoid overlaps with other markers

    // True if this note marker overlaps with any previous note marker
    private overlaps(notes: PassageNote[], index: number) {
        for (let i=0; i<index; ++i) {
            if (notes[index].overlap(notes[i])) return true
        }

        return false
    }

    // True if marker for this note overlaps with note b
    private overlap(b: PassageNote) {
        let { width, x, y } = this

        return Math.abs(x - b.x) < width && Math.abs(y - b.y) < width
    }

    private isSmallMarker() {
        return this.width === smallNoteMarker
    }

    // Shrink this marker all markers within 3 small marker widths
    // private shrinkMarker(notes: PassageNote[], index: number, secondsPerPixel: number) {
    //     let timeDelta = 3 * smallNoteMarker * secondsPerPixel // make everything within this time delta small

    //     for (let i = 0; i < notes.length; ++i) {
    //         if (notes[i].time >= this.time - timeDelta && notes[i].time <= this.time + timeDelta) {
    //             notes[i].width = smallNoteMarker
    //         }
    //     }
    // }

    private lowerMarker() {
        this.y = smallNoteMarker
    }

    private raiseMarker() {
        this.y = -(smallNoteMarker)
    }

    // Set the width, x, y, and (as a last resort) time for this note
    // so that its marker does not collide with other notes
    // ??? do we need a separate DrawablePassageNote class?
    setupMarker(notes: PassageNote[], index: number, duration: number, componentWidth: number) {
        const secondsPerPixel = duration / componentWidth
        let note = notes[index]
        note.x = note.time / secondsPerPixel - note.width/2
        if (index === 0) return // first marker cannot overlap because no previous markers

        let xMax = duration / secondsPerPixel

        // Give up, after 200 tries we still have overlap
        for (let i=0; i<200; ++i) {
            if (!this.overlaps(notes, index)) break

            if (note.isSmallMarker()) {
                this.raiseMarker()
                if (!this.overlaps(notes, index)) break

                this.lowerMarker()
                if (!this.overlaps(notes, index)) break
            }

            this.y = 0 // reset to base line
            // push marker forward
            this.x = notes[index-1].x + note.width + 1
            this.x = Math.min(note.x, xMax)

            //!!! cheating and letting all the notes overlap at then end
        }
    }

    visibleItems(includeConsultantOnly: boolean) {
        return this.items.filter(item => includeConsultantOnly || !item.consultantOnly)
    }


    /*
     * Search for text in all strings associated with note.
     * Return true if found.
     */
    textFound(searchText: string) {
        function _search(_searchText: string, _text: string) {
            _text = _text.toLowerCase()
            let found = _text.includes(_searchText)
            return found
        }

        searchText = searchText.toLowerCase()
        if (_search(searchText, this.description)) {
            return true
        }
        for (let item of this.items) {
            if (_search(searchText, item.text)) {
                return true
            }
        }

        return false
    }

    static nonDeletedColors(colors: string[]) {
        return colors.filter(color => color !== PassageNote.DeletedColor)
    }

    colorIndex() {
        let noteSelector = NoteSelector.noteSelector(this.type)
        return noteSelector.colorIndex
    }

    shapeIndex() {
        let noteSelector = NoteSelector.noteSelector(this.type)
        return noteSelector.shapeIndex
    }

    async setColor(colorIndex: number) {
        const noteType = NoteSelector.getType(this.shapeIndex(), colorIndex)
        await this.setType(noteType)
    }

    // END PassageNote
}

export class PassageNoteItem extends DBObject {
    position: number = 0
    duration: number = 0
    @observable fileType: string = ''
    @observable url: string = ''
    @observable text: string = ''
    @observable resolved: boolean = false
    @observable unresolved: boolean = false
    @observable consultantOnly = false

    @observable _rev = 0
    // email address of people who have viewed this
    @observable viewedBy: string[] = []

    constructor(_id: string, db: IDB) {
        super(_id, db)
        this.setUMTRank()  // ensure items sorted by UMT time
    }

    toDocument() {
        let { position, duration, fileType, url, text, resolved, unresolved, viewedBy, rank, consultantOnly } = this
        return this._toDocument({ position, duration, fileType, url, text, resolved, unresolved, viewedBy, rank, consultantOnly })
    }

    dbg() {
        let doc = this.toDocument()
        // Following code removed because it causes circular references for tests
        // let { url } = this
        // if (url) {
        //     let vcr = VideoCacheRecord._get(url)
        //     if (vcr) {
        //         doc._downloaded = vcr.downloaded ? 'true' : '*** NOT DOWNLOADED ***'
        //     } else {
        //         doc._downloaded = '*** NOT IN CACHE ***'
        //     }
        // }

        return doc
    }

    isTextItem() {
        return this.text || !this.url
    }

    isVideoItem() {
        return this.fileType && !isAllowedImage(this.fileType)
    }

    isImageItem() {
        return this.fileType && isAllowedImage(this.fileType)
    }

    async setConsultantOnly(consultantOnly: boolean) {
        if (this.consultantOnly === consultantOnly) {
            return
        }

        let doc = this._toDocument({})
        doc.consultantOnly = consultantOnly
        await this.db.put(doc)
    }

    async updateText(text: string): Promise<void> {
        let doc = this._toDocument({})
        if (this.text === text) {
            return
        }
        doc.text = text
        await this.db.put(doc)
    }

    async addViewedBy(email: string) {
        let { viewedBy } = this
        email = normalizeUsername(email)

        if (email === '' || this.creator === email || viewedBy.includes(email)) return

        let doc = this._toDocument({})
        // Force the _id for a task change to be differnt than the _id for the base passage
        doc._id += '@viewedby'

        doc.viewedByUser = email
        await this.db.put(doc)
    }

    isUnviewedAfterDate(username: string, cutoff: Date) {
        let { creator, viewedBy, resolved, unresolved } = this
        let newerThanCutoff = newerThanCutoffDate(this.creationDate, cutoff)
        let isCreator = creator === username
        let hasViewed = viewedBy.includes(username)
        return newerThanCutoff && !resolved && !unresolved && !isCreator && !hasViewed
    }

    clone() {
        let newItem = new PassageNoteItem(this._id, this.db)
        newItem.creationDate = this.creationDate
        newItem.rank = this.rank
        newItem.removed = this.removed
        newItem.position = this.position
        newItem.duration = this.duration
        newItem.url = this.url
        newItem.fileType = this.fileType
        newItem.text = this.text
        newItem.resolved = this.resolved
        newItem.unresolved = this.unresolved
        newItem._rev = this._rev
        newItem.viewedBy = this.viewedBy
        newItem.consultantOnly = this.consultantOnly
        return newItem
    }

    displayedCreationDate(dateFormatter: IDateFormatter) {
        let date = new Date(this.creationDate)
        return dateFormatter.format(date)
    }
 }

 /* Approval status for individual video segments.
  * User can independently add a check mark and/or a cirle.
  * For example, a check mark might mean 'Content has been review and is correct'.
  * Circle might mean 'Style has been reviewed and is correct'.
  * Some teams might only use the check mark.
  */
export enum PassageSegmentApproval {
    State0, // neither circle nor check mark
    State1, // check mark only
    State2, // circle only
    State3, // check mark + circle
}

/* Zero or tiny segments are hard to display and break our ability to seek to the
 * correct segment based on the time ... so don't let segments get too short.
 */
export const MIN_SEGMENT_LENGTH = 0.1


export class PassageSegment extends DBObject {
    // A segment may be replaced by patch.
    // Each patch is a PassageVideo.
    // The _id of the PassageVideo containing the patch is stored here.
    // If a segment has been patched multiple times the latest patch is
    // the last entry in the array.
    @observable videoPatchHistory: string[] = []
    endPosition = 0 // end of segment in containing video
    position = 0    // start of segment in containing video
    @observable approved: PassageSegmentApproval = PassageSegmentApproval.State0
    @observable approvedBy = ''
    @observable approvalDate = ''
    @observable labels: PassageSegmentLabel[] = []
    glosses: PassageSegmentGloss[] = []   // These are glosses for the entire segment
    @observable ignoreWhenPlayingVideo = false
    @observable sketchPaths: CanvasPath[] = [] // paths for sketching on video. 1.0 means number of pixels in full height of video display

    // Time offset for this note in main video timeline
    // Set by videoPassage.setupSegmentsAndNotes
    time: number = 0

    // Human readable form of references for this segment, e.g. Gen 3.1-10; Ex 4.11
    references: RefRange[] = []

    cc: string = ''  // closed caption
    @observable _rev = 0

    constructor(_id: string, db?: IDB) {
        super(_id, db)
        this.setIgnoreWhenPlayingVideo = this.setIgnoreWhenPlayingVideo.bind(this)
    }

    toDocument() {
        let { videoPatchHistory, position, endPosition, approved, approvalDate, approvedBy, labels, cc, references, glosses } = this
        let serializedReferences = JSON.stringify(references)
        return this._toDocument({ videoPatchHistory, position, endPosition, approved, approvalDate, approvedBy, labels, cc, references: serializedReferences, glosses })
    }

    dbg(passage: Passage | null, details?: string) {
        let doc = this.toDocument()

        doc.time = this.time
        doc.labels = this.labels.map(label => label.dbg())
        doc.glosses = this.glosses.map(gloss => gloss.dbg())

        doc.patches = this.videoPatchHistory.map(patchId => ({
            patchId,
            patchVideo: passage?.findVideo(patchId)?.dbg(passage, details),
            patchSegment: passage?.findVideo(patchId)?.segments[0].dbg(passage, details),
        }))

        return doc
    }

    log(passage: Passage | null, label: string) {
        log('[PassageSegment] ' + label, JSON.stringify(this.dbg(passage), null, 4))
    }

    async addVideoPatchToHistory(video: PassageVideo) {
        let { _id, isPatch } = video
        if (!isPatch) { throw Error('Video is not a patch') }
        if (this.videoPatchHistory.includes(_id)) { return }

        let doc = this.toDocument()
        doc.videoPatchHistory = [...this.videoPatchHistory, _id]
        await this.db.put(doc)
    }

    async removeVideoPatchFromHistory(video: PassageVideo) {
        let { _id } = video
        let { videoPatchHistory } = this
        let index = videoPatchHistory.findIndex(e => e === _id)
        if (videoPatchHistory.length <= 0 || index < 0) {
            return
        }
        let doc = this.toDocument()
        doc.videoPatchHistory = videoPatchHistory.filter(e => e !== _id)
        await this.db.put(doc)
    }

    async setStartPosition(value: number, video: PassageVideo) {
        await this.setPositions(value, null, video)
    }

    async setEndPosition(value: number, video: PassageVideo) {
        await this.setPositions(null, value, video)
    }

    // We have to change both positions in a single update to db otherwise
    // the display looks really odd during the time delay between when we
    // see the result of updating the start position and the result of updating
    // the ending position.
    async setPositions(value: number | null, endValue: number | null, video: PassageVideo) {
        let changed = false
        let doc = this.toDocument()

        let hardStartPosition = this.hardStartPosition(video)
        let hardEndPosition = this.hardEndPosition(video)
        if (hardStartPosition === -1 || hardEndPosition === -1 || this.videoPatchHistory.length > 0 ) {
            log('### setPositions failed')
            return
        }

        let minEndValue = this.position + MIN_SEGMENT_LENGTH

        if (value !== null) {
            value = Math.max(value, hardStartPosition)
            // Don't let start position get too close to end of segment
            // 0 length segments do not display and mess up our seek by time logic
            value = Math.min(value, hardEndPosition - MIN_SEGMENT_LENGTH)

            // Don't let endPosition get too close to position
            minEndValue = value + MIN_SEGMENT_LENGTH

            if (value !== this.position) {
                changed = true
                doc.position = value
            }
        }

        if (endValue !== null) {
            endValue = Math.max(endValue, minEndValue)
            endValue = Math.min(endValue, hardEndPosition)

            if (endValue !== this.endPosition) {
                changed = true
                doc.endPosition = endValue
            }
        }

        if (!changed) return

        this.db.submitChange(doc)
    }

    isAllowedToDrag(passageVideo: PassageVideo) {
        let { segments } = passageVideo
        let index = segments.indexOf(this)

        // Adjusting the boundaries of patches is complicated. We don't allow
        // users to drag the boundaries of segments that are either in a patch,
        // or are adjacent to a patch.
        let isAllowedToDrag = index > 0 && !this.isPatched && !segments[index - 1]?.isPatched
        return isAllowedToDrag
    }

    canChangePositionToTime(time: number, passageVideo: PassageVideo) {
        let hardStartPosition = this.hardStartPosition2(passageVideo)
        let hardEndPosition = this.hardEndPosition(passageVideo)
        let position = this.timeToPosition(time)
        let tooClose = passageVideo.segments.filter(seg => seg._id !== this._id).find(seg => Math.abs(time - seg.time) < MIN_SEGMENT_LENGTH)
        return !this.isPatched && !tooClose && position > hardStartPosition && position < hardEndPosition
    }

    setDefaultEndPosition(video: PassageVideo) {
        let hardEndPosition = this.hardEndPosition(video)
        if (hardEndPosition >= 0) {
            this.endPosition = hardEndPosition
        }
    }

    get duration() {
        return this.endPosition - this.position
    }

    hardStartPosition2(video: PassageVideo) {
        let { segments } = video
        let index = segments.findIndex(s => s._id === this._id)
        if (index < 0) return -1
        return index > 0 ? segments[index - 1].position : 0
    }

    hardStartPosition(video: PassageVideo) {
        let { segments } = video
        let index = segments.findIndex(s => s._id === this._id)
        if (index < 0) return -1
        return index > 0 ? segments[index - 1].endPosition : 0
    }

    hardEndPosition(video: PassageVideo) {
        let { segments, duration } = video
        let index = segments.findIndex(s => s._id === this._id)
        if (index < 0) return -1
        return index < segments.length - 1 ? segments[index + 1].position : duration
    }

    async setApproved(approval: PassageSegmentApproval, username: string) {
        if (this.approved === approval) {
            return
        }
        let doc = this._toDocument({})
        doc.approved = approval
        doc.approvalDate = this.db.getDate()
        doc.approvedBy = username
        await this.db.put(doc)
    }

    async setReferences(references: RefRange[]) {
        let serializedReferences = JSON.stringify(references)
        log('setReferences', serializedReferences)
        if (JSON.stringify(this.references) === serializedReferences) {
            log('setReferences no change')
            return
        }

        let doc = this.toDocument()
        doc.references = serializedReferences
        await this.db.put(doc)
    }

    async setLabels(labels: PassageSegmentLabel[]) {
        let serializedLabels = JSON.stringify(labels)
        if (JSON.stringify(this.labels) === serializedLabels) {
            return
        }
        let doc = this._toDocument({})
        doc.labels = labels

        await this.db.put(doc)
    }

    async setGloss(identity: string, gloss: string) {
        let glosses = this.glosses.map(g => Object.assign({}, g))
        let sg = glosses.find(g => g.identity === identity)
        if (sg) {
            sg.gloss = gloss
        } else if (gloss.trim() !== '') {
            glosses.push(new PassageSegmentGloss(identity, gloss))
        }

        // Ensure that all glosses are ordered by identify of creator
        glosses = _.sortBy(glosses, g => g.identity)

        if (JSON.stringify(this.glosses) === JSON.stringify(glosses)) {
            return
        }
        let doc = this.toDocument()
        doc.glosses = glosses

        await this.db.put(doc)
    }

    getRefRanges(passageVideo: PassageVideo, passage: Passage): RefRange[] {
        let references: RefRange[] = []

        let baseVideo = passageVideo.baseVideo(passage)
        if (!baseVideo) {
            baseVideo = passageVideo
        }
        let segment = this.actualSegment(passage)
        let visibleSegments = baseVideo.visibleSegments(passage)
        for (let _seg of visibleSegments) {
            if (_seg.references.length > 0) references = _seg.references
            if (_seg._id === segment._id) break
        }

        return references
    }

    // If this segment is patched, return the 0'th segment from the patched video.
    // Otherwise return this segment.
    actualSegment(passage: Passage) {
        let patchVideo = this.patchVideo(passage)
        return patchVideo ? patchVideo.segments[0] : this
    }

    // If this segment is patched, return the sketchPaths from the patched video.
    // Otherwise return the sketchPaths for this segment.
    actualSegmentSketchPaths(passage: Passage) {
        const segment = this.actualSegment(passage)
        return segment?.sketchPaths ?? []
    }

    // Return passageVideo for latest patch for this segment.
    // Return undefined if no patch present for segment.
    patchVideo(passage: Passage) {
        let { videoPatchHistory } = this
        let latestPatchId = videoPatchHistory.slice(-1)[0]
        if (!latestPatchId) return undefined

        let video = passage?.findVideo(latestPatchId)

        return video || null
    }

    // If segment is patched return the patch video
    // Otherise return the passage video.
    actualVideo(passage: Passage) {
        let video = this.patchVideo(passage)
        if (video) return video
        video = passage.findVideo(this._id)
        return video || null
    }

    // Has this segment been patched?
    get isPatched() {
        return this.videoPatchHistory.length > 0
    }

    // Convert a position in this segment to a time.
    // Don't allow times earlier than 0.
    positionToTime(position: number) {
        let time = Math.max(this.time + position - this.position, 0)
        return time
    }

    // Convert a time to a position in the visible area of this segment
    timeToPosition(time: number, limitToSegment?: boolean) {
        let position = this.position + time - this.time

        if (limitToSegment) {
            position = Math.max(position, this.position)
            position = Math.min(position, this.endPosition)
        }

        return position
    }

    async setIgnoreWhenPlayingVideo(value: boolean) {
        if (this.ignoreWhenPlayingVideo === value) {
            return
        }
        let doc = this._toDocument({ model: 10, ignoreWhenPlayingVideo: value })
        await this.db.put(doc)
    }

    async setSketchPaths(paths: CanvasPath[]) {
        const sketchPaths = JSON.stringify(paths)
        if (JSON.stringify(this.sketchPaths) === sketchPaths) return

        const doc = this._toDocument({ sketchPaths })
        await this.db.put(doc)
    }
}

export class PassageSegmentLabel {
    constructor(public x: number, public y: number,
        public xText: number = x,
        public yText: number = y,
        public text: string = '') { }

    dbg() {
        let type = this.constructor.name
        let {x, y, xText, yText, text} = this
        let doc = { type, x, y, xText, yText, text }
        return doc
    }
}

export class PassageSegmentGloss {
    constructor(
        public identity: string,  // email of user or A B C D
        public gloss: string
    ) {}

    dbg() {
        let type = this.constructor.name
        let { identity, gloss } = this
        let doc = { type, identity, gloss }
        return doc
    }
}

// Wrapper around gloss to allow drawing it in GlossBar
export interface IDrawablePassageGloss {
    x: number
    time: number,
    duration: number,
    width: number   // width of gloss in pixels
    gloss: PassageGloss
}

export class PassageGloss extends DBObject {
    position = 0   // time offset in video to start of gloss
                   // based on the position of the following gloss
    text = ''

    // When nonblank ties this gloss to a ProjectTerm.
    // See diagram immediately before 'class ProjectTerm' in ProjectModels.ts
    // NOT CURRENTLY USED - will look up 'text' in the list of all ProjectTerms for passage
    // @observable lexicalLink = ''

    @observable _rev = 0

    // These values are not store in DB
    time: number = 0    // time for this gloss in the timeline
    segmentIndex = 0    // which segment is this on in the base (non-patched) video?

    model = 12

    constructor(_id: string, db: IDB) {
        super(_id, db)
        this.duration = this.duration.bind(this)
    }

    toDocument() {
        let { position, text } = this
        return this._toDocument({ position, text: text.trim() })
    }

    dbg() {
        let { text, position } = this
        return { text, position }
    }

    async set(position: number, text: string) {
        text = text.trim()
        if (this.text === text && this.position === position) return

        let doc = this._toDocument({ model: this.model, text, position })
        await this.db.put(doc)
    }

    // NOT CURRENTLY USED
    // async setLexicalLink(lexicalLink: string) {
    //     if (this.lexicalLink === lexicalLink) return

    //     let doc = this._toDocument({ model: this.model, lexicalLink })
    //     await this.db.put(doc)
    // }

    // Find the PassageVideo containing this gloss
    toVideo(passage: Passage) {
        return passage.videos.find(v => v.glosses.find(g => g._id === this._id))
    }

    toSegment(passage: Passage) {
        let video = this.toVideo(passage)
        if (!video) return null

        let { segment } = video.positionToSegment(this.position)

        return segment
    }


    static async addTestData(pv: PassageVideo) {
        if (pv.glosses.length) return

        await pv.addGloss(0, 'ONE')
        await pv.addGloss(1, 'TWO')
        await pv.addGloss(2, 'THREE')
        await pv.addGloss(3, 'FOUR')
        await pv.addGloss(4, 'FIVE')
        await pv.addGloss(5, 'SIX')
        await pv.addGloss(6, 'SEVEN')
        await pv.addGloss(7, 'EIGHT')
    }

    /** The length of the gloss
     * 
     * WARNING: If you need to go thru and find duration for all the glosses in a video
     * this method would be very slow, use durationByIndex instead.
     * 
     * @param passage The containing passage
     * @param passageVideo The base video. The gloss might be on the video, or on a patch of the video.
    */
    duration(passage: Passage, passageVideo: PassageVideo) {
        let visibleGlosses = passageVideo.getVisibleGlosses(passage)
        let index = visibleGlosses.findIndex(gloss => gloss._id === this._id)

        if (index === -1) {
            log(`Could not find gloss ${this._id}`) // Should never happen
            return 10 // return arbitrary length
        }

        return this.durationByIndex(visibleGlosses, index)
    }

    // Find the duration of a gloss based on its index in the list of visible glosses
    durationByIndex(visibleGlosses: PassageGloss[], index: number) {
        // if this is the last gloss, we want it to have some length
        let endingTime = this.time + 10
        if ((index + 1) < visibleGlosses.length) {
            let nextGloss = visibleGlosses[index + 1]
            endingTime = nextGloss.time
        }
        return endingTime - this.time
    }
}

// A highlighted range of words in a written enhanced resource text, e.g. RSV89

export class PassageHighlight extends DBObject {
    public color: number = 0
    public firstId: string = '';
    public lastId: string = '';
    public resourceName: string = '';

    constructor(_id: string, db: IDB) {
        super(_id, db)
    }

    toDocument() {
        let { color, firstId, lastId, resourceName } = this
        return this._toDocument({ color, firstId, lastId, resourceName })
    }

    // Look through highlights and find the last highlight for this resource
    // and word id. Return its color. If not found return 0.
    static highlighted(highlights: PassageHighlight[], spanId: string, resourceName: string) {
        if (!spanId) return 0

        for (let i = highlights.length - 1; i >= 0; --i) {
            let h = highlights[i]
            if (h.resourceName !== resourceName) continue
            if (spanId >= h.firstId && spanId <= h.lastId) {
                return h.color
            }
        }

        return 0
    }

}

interface ProjectTermRoot {
    uiLanguage: string,
    project: Project,
    transliterateLemmas: boolean,
}

/* (edited with https://asciiflow.com/legacy/)

!!!add references to this diagram in other files

MarbleLemma:
    id, e.g., SDBG:σάββατον:000000
    This data comes from public/data/sdbgs.json (Greek) and sdbhs.json (Hebrew).
    And is gotten via fetchLemmas() in src/scrRefs/Lemmas.ts
    These files are built from .xml files supplied by the Marble project.
    A single lemma can have several different meanings.
    The info for eaching meaning is found in a LexMeaning

LexMeaning:
    id, e.g., sdbg004423001003000
    lexicalLink, e.g., SDBG:σάββατον:000002
        It is odd that MarbleLemma.id and LexMeaning.lexical have same format

                                                 +-------------+
                                                 | PassageGloss|
                                                 |             |
                                                 | lexicalLink |
                                                 |             |
          +-------------+                        +------+------+
          | MarbleLemma |                               |
          |             |                               |
termId ===| id          |    +--------------+    +------+------+    Portion 'Glossary' (isGlossary === true)
          | meanings    |==+ | LexMeaning   |    | ProjectTerm |
          |             |    |              |    |             |    +----------+
          |             |    | lexicalLink  |====| lexicalLink |    | Passage  |
          |             |    | definitions  |    | isKeyTerm   |    |          |
          |             |    | references   |    | glosses     |==+ | name     |
          +-------------+    +--------------+    +-------------+    +----------+
 */

/**
 * A ProjectTerm contains project specific information about a Marble lexical item
 * (which is a sense of a Greek or Hebrew term)
 */
export class ProjectTerm extends DBObject {
    lexicalLink = ''

    /**
     * Keyterms represent lexical items which the project wishes to focus on.
     * Typically these are terms in which we want to limit to a specific renderings.
     */
    @observable isKeyTerm = false

    /**
     * This is a semicolon separated list of glosses.
     * Each gloss is the name of a passage in the Glossary portion.
     */
    @observable glosses: string = ''

    private passages: Passage[] = []

    model = 12

    constructor(_id: string, db?: IDB) {
        super(_id, db)
    }

    toDocument() {
        let { lexicalLink, isKeyTerm, glosses } = this
        return this._toDocument({ lexicalLink, isKeyTerm, glosses })
    }

    dbg() {
        let { _id, lexicalLink, isKeyTerm, glosses } = this
        lexicalLink = lexicalLink ? lexicalLink : '*none*'
        return { _id, lexicalLink, isKeyTerm, glosses }
    }

    async setIsKeyTerm(isKeyTerm: boolean) {
        log('setIsKeyTerm', fmt({ isKeyTerm, oldIsKeyTerm: this.isKeyTerm }))
        if (isKeyTerm === this.isKeyTerm) {
            return
        }

        let doc = this._toDocument({ model: this.model, isKeyTerm })
        await this.db.put(doc)
    }

    /**
     * Glosses are the allowed textual representations for thus term.
     * Normally they use the writtens language used locally by project members.
     * Use ;'s to separate values, e.g. 'sàbado; semana'.
     * Glosses are stored in the passage name of the corresponding passage(s) in the
     * Glossary portion.
     */
    async setGlosses(glosses: string, project: Project) {
        log('setGlosses', fmt({ glosses }))
        glosses = glosses.trim()

        let doc = this._toDocument({ model: this.model, glosses })
        await this.db.put(doc)

        let _glosses = this.getGlosses()
        for (let _gloss of _glosses) {
            await ProjectTerm.getPassage(_gloss, project)
        }
    }

    getGlosses() {
        let parts = this.glosses.split(';')
        return parts.map(part => part.trim()).filter(part => part)
    }

    findGloss(gloss: string) {
        gloss = gloss.trim()
        let glosses = this.getGlosses()
        let _gloss = glosses.find(g => g.trim().toLowerCase() === gloss.toLowerCase())
        return _gloss
    }

    /**
     * Get passsage corresponding to gloss.
     * Create it in Glossary if not already present.
     */
    static async getPassage(gloss: string, project: Project) {
        let glossaryPortion = await this.getGlossaryPortion(project)
        let passage = glossaryPortion.passages.find(p => p.name.trim().toLowerCase() === gloss.trim().toLowerCase())

        if (!passage) {
            passage = await glossaryPortion.addPassage(gloss)
            //!!! move to correct position
        }
        log('addPassage', fmt({ gloss, glossaryPortion, passage }))

        return passage
    }

    getPassageIfExists(gloss: string, project: Project) {
        let portion = project.portions.find(p => p.isGlossary)
        if (portion === undefined) return undefined

        let passage = portion.passages
            .find(p => p.name.toLocaleLowerCase() === gloss.toLocaleLowerCase())
        return passage
    }

    /** Is this Hebrew or Greek term used in a range of verses? It is assumed that bbbcccvvvs are in the "Original" versification */
    doesOccurInVerses(bbbcccvvvs: string[]) {
        let lemma = this.getMarbleLemma()
        if (!lemma) { return false }

        let lexMeaning = lemma.meanings.find(meaning => meaning.lexicalLink === this.lexicalLink)
        if (!lexMeaning) { return false }

        return lexMeaning.references.some(reference => bbbcccvvvs.includes(reference))
    }

    // getInfo(rt: IRoot) {
    //     let { uiLanguage, transliterateLemmas } = rt

    //     let sourceGlosses = ''
    //     let termSenseName = ''

    //     let lemma = this.getMarbleLemma()
    //     //log('getInfo', fmt({ lemma, uiLanguage }))

    //     if (!lemma) { return { sourceGlosses, termSenseName } }

    //     let lexMeaning = lemma.meanings.find(meaning => meaning.lexicalLink === this.lexicalLink)
    //     if (lexMeaning) {
    //         termSenseName = getTermSenseName(lemma, lexMeaning, transliterateLemmas)
    //         sourceGlosses = lexMeaning.glosses(uiLanguage)
    //     }
    //     //log('getInfo', fmt({ sourceGlosses, termSenseName }))

    //     return { sourceGlosses, termSenseName }
    // }

    hasVideo() {
        return this.passages.some(p => p.hasVideo())
    }

    getMarbleLemma() {
        return MarbleLemmas.get(this.lexicalLink)
    }

    /**
     * Get the portion containing the glossary entries.
     * Create it if necessary.
     */
    static async getGlossaryPortion(project: Project) {
        let { portions } = project
        let portion: Portion | undefined

        portion = portions.find(p => p.isGlossary)
        if (portion) {
            log('getGlossaryPortion found')
            return portion
        }

        let glossary = /* translator: important */ t`Glossary`

        portion = portions.find(p => p.name === glossary)
        if (portion) {
            portion.isGlossary = true
            await project.db.put(portion.toDocument())
            return portion
        }

        portion = await project!.addPortion(glossary, true)
        log('getGlossaryPortion added', fmt({portion}))
        return portion
    }
}

export function createThumbnailVideo(
    db: IDB, _id: string,
    { url, fileType, size, srcVideoUrl, selectionStartTime, selectionEndTime }: IThumbnailVideo
) {
    const thumbnail = new PassageThumbnailVideo(_id, db)
    thumbnail.url = url
    thumbnail.fileType = fileType
    thumbnail.size = size
    thumbnail.srcVideoUrl = srcVideoUrl
    thumbnail.selectionStartTime = selectionStartTime
    thumbnail.selectionEndTime = selectionEndTime
    return thumbnail
}


function limit(value: number, low: number, high: number) {
    value = Math.max(value, low)
    value = Math.min(value, high)
    return value
}