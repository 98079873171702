/*
    Set up a websocket connection to AWS asking to be notified whenever any
    change has been made to the central DB for a project. Otherwise, we would
    need to keep polling for changes.
 */

 /*
    Front end:
    
        * Start app. Sync, then initialize a websocket connection. We want to sync so we are ensured we start with up-to-date data.
        * When a user submits a doc to the database, they will call sync. 
        * The back end will send back the docs the user just sent, with a few extra attributes. 
        * Other users in the project will be sent a notification through their websocket connection that a change has occurred. 
          The notification will include the maximum sequence number that the back end currently has in the database.
        * If the sequence number we get from the backend is greater than the maximum sequence we currently have, we will sync.
        * Every 2.5 minutes, we will ping the server to make sure our websocket connection is kept alive. 
          Even if we haven’t been notified of new changes, we will force a sync. 
          This is a sanity check in case the back end stops notifying us of changes.
        * About every 2 hours, the back end will close the websocket connection. 
          This is set in stone by AWS. After a 10 second timeout, attempt to reconnect.

    Back end:

        * When a user establishes a websocket connection, add their connection id (generated by AWS automatically) to the database.
        * When a user syncs, send the updated docs back to the user that initiated the sync. 
        * In addition, if any new data was put into the database, notify all users in the project. 
        * Normally, when a user disconnects from their websocket, we will automatically remove their entry from the connections table 
          in the database. If for some reason that doesn’t happen, we can detect that when we try to send a notification to that user. 
          We can then delete that user from the database.
  */

import { doOnceWhenBackOnline, getIsAppOnlineOrDefault } from '../components/utils/ServiceStatus'
import API from "./API"

let log = require('debug')('sltt:DBChangeNotifier')

export class DBChangeNotifier {
    private socket: WebSocket | null = null
    private keepAliveIntervalId: null | ReturnType<typeof setTimeout> = null

    // Wait fpr a timeout before trying a reconnect after lost of connection
    private reconnectTimeoutId: null | ReturnType<typeof setTimeout> = null
    private reconnectIntervalMs = 10000
    
    private keepAliveIntervalMs = 150000     // 2.5 min
    private connectionRequested = false

    constructor(private projectName: string, 
        private onChange: (maxseq: number) => Promise<void> /* called whenever DB for the project has new data */ ) {
    }

    requestNotifications() {
        if (!this.connectionRequested) {
            log('requestNotifications')
            
            this.connectionRequested = true
            this.reconnectTimeoutId && clearTimeout(this.reconnectTimeoutId)
            this.onChange(Number.MAX_SAFE_INTEGER)  // Unconditionally check for changes so we have the latest changes
                .then(() => this.connect())
        }
    }

    public disconnect() {
        console.log('disconnect', this.projectName) // rollbar
        log('disconnect', this.projectName)
        this.connectionRequested = false
        this.reconnectTimeoutId && clearTimeout(this.reconnectTimeoutId)
        this.keepAliveIntervalId && clearInterval(this.keepAliveIntervalId)
        this.socket?.close()    
    }

    private connect() {
        this.socket = new WebSocket(`${API.getDBNotificationUrl()}/?project=${this.projectName}`)

        this.socket.onopen = e => {
            log('connect', this.projectName)
            this.reconnectIntervalMs = 10000
            this.keepAliveIntervalId = setInterval(() => this.keepConnectionAlive(), this.keepAliveIntervalMs)
        }

        this.socket.onerror = e => {
            // Do exponential backoff on retries up to 5 minutes
            this.reconnectIntervalMs = Math.min(5*60*1000, 2 * this.reconnectIntervalMs)
            log('error', this.projectName, this.reconnectIntervalMs)

            this.socket?.close()
        }

        //DB_UPDATED message means someone has changed the project, call onChange.
        this.socket.onmessage = e => {
            // log('message', this.projectName)
            let message = JSON.parse(e.data)
            let { action, value: maxseq } = message
            if (action === 'DB_UPDATED') {
                this.onChange(maxseq)
            }
        }

        // https://docs.aws.amazon.com/apigateway/latest/developerguide/limits.html
        // This will be called:
        // - after a period of inactivity (pinging the server periodically should prevent this)
        // - after a certain amount of time, whether there was any activity or not
        // - if there is an error with the websocket
        // - if the browser is closed when the connection is open
        this.socket.onclose = e => {
            log('close', this.projectName)
            this.keepAliveIntervalId && clearInterval(this.keepAliveIntervalId)
            this.socket = null
            this.connectionRequested = false
            if (getIsAppOnlineOrDefault(true)) {
                this.reconnectTimeoutId = setTimeout(() => this.requestNotifications(), this.reconnectIntervalMs)
            } else {
                doOnceWhenBackOnline('DBChangeNotifier.requestNotifications()', () => this.requestNotifications())
            }
        }
    }

    private keepConnectionAlive() {
        this.onChange(Number.MAX_SAFE_INTEGER) // Unconditionally check for changes in case we are not being notified for one reason or another
        let pingData = JSON.stringify({ action: 'PING', value: '' })
        this.socket?.send(pingData)
    }
}