import isConnectedToInternet from 'is-online'
import { featureFlag } from './LocalStorage'

let log = require('debug')('sltt:ServiceStatus')

const isTesting = process.env.NODE_ENV === 'test'
let isAppOnline: boolean | undefined = isTesting || featureFlag('online') || (featureFlag('offline') ? false : undefined)
const shouldBypassInternetCheck = () => isTesting || featureFlag('online') || featureFlag('offline')

/**
 * synchronous check for isAppOnline. If isAppOnline is undefined, return defaultValue.
 * @param defaultValue 
 * @returns (isAppOnline: true | false) | defaultValue
 */
export const getIsAppOnlineOrDefault = (defaultValue: boolean) => isAppOnline !== undefined ? isAppOnline : defaultValue

const shouldPollIsAppOnline = () => !isTesting /* can mock false, otherwise don't poll during unit tests */
/**
 * async check for isAppOnline. If isAppOnline is undefined, wait for it to be set then return its value.
 * @returns isAppOnline: true | false
 */
export const getIsAppOnlineOrWait = async () => {
    return new Promise((resolve) => {
        if (isAppOnline !== undefined) {
            // log('getIsAppOnlineOrWait immediate', isAppOnline)
            resolve(isAppOnline)
            return
        }

        const checkInterval = setInterval(() => {
            if (isAppOnline !== undefined) {
                clearInterval(checkInterval)
                // log('getIsAppOnlineOrWait delayed', isAppOnline)
                resolve(isAppOnline)
            }
        }, 100)
    })
}

const hashedCallbacks: {[id: string]: () => void } = {} 

/**
 * Call the registered callback once when the app is back online.
 * (if already online, do immediately.)
 * @param id - to ensure callback is only called once
 * @param callback - to be called when app is back online
 */
export function doOnceWhenBackOnline(id: string, callback: () => void) {
    if (isAppOnline) {
        console.log(`doOnceWhenBackOnline: ${id}...already online`)
        callback()
        return
    }
    if (hashedCallbacks[id]) {
        log(`doOnceWhenBackOnline: ${id}...already queued`)
        return
    } else {
        hashedCallbacks[id] = callback
        console.log(`doOnceWhenBackOnline: ${id}...queued`)
    }
}

function callHashedCallbacks() {
    for (const key in hashedCallbacks) {
        try {
            console.log(`callHashedCallbacks: ${key}...calling`)
            hashedCallbacks[key]()
        } finally {
            delete hashedCallbacks[key]
        }
    }
}

/** 
 * Will not resolve until we've detected the app is online.
 */
export async function delayUntilOnline(logContext: string, pollInterval: number = 10000) {
    log(`offLine. Polling every ${pollInterval}ms until back online: ${logContext}`)
    while (!(await getIsAppOnlineOrWait())) {
        await new Promise(resolve => setTimeout(resolve, pollInterval))
    }
    log(`onLine: ${logContext}`)
}

export const MESSAGE_IS_APP_ONLINE_STATUS_UPDATED = 'isAppOnlineUpdated'

export const updateOnlineStatus = async () => {
    // for some reason typescript complains about updateOnlineStatusIntervalId
    // if updateOnlineStatus has optional parameter
    await overridableUpdateOnlineStatus() // don't override
}

export const overridableUpdateOnlineStatus = async (isAppOnlineOverride?: boolean) => {
    const isAppOnlineOriginal = isAppOnline
    if (isAppOnlineOverride !== undefined) {
        isAppOnline = isAppOnlineOverride
    } else if (!MockableServiceStatus.shouldBypassInternetCheck()) {
        isAppOnline = await MockableServiceStatus.isConnectedToInternet({ timeout: 5000 })
    }
    if (featureFlag('offline') && featureFlag('online')) {
        console.log('offline and online feature flags are both set. Toggling online/offline')
        isAppOnline = !isAppOnline // just alternate
    } else if (featureFlag('online')) {
        isAppOnline = true
    } else if (featureFlag('offline')) {
        isAppOnline = false
    }
    if (isAppOnline) {
        callHashedCallbacks()
    }
    if (isAppOnline !== isAppOnlineOriginal) {
        window.dispatchEvent(new CustomEvent(MESSAGE_IS_APP_ONLINE_STATUS_UPDATED))
    }
}

let updateOnlineStatusIntervalId: null | ReturnType<typeof setInterval> = null

const startUpdatingOnlineStatus = () => {
    if (updateOnlineStatusIntervalId !== null) {
        clearInterval(updateOnlineStatusIntervalId)
    }

    // Something strange is happening with this line.
    // MDN claims setInterval returns a number.
    // Typescript claims it returns a NodeJS.Timeout.
    // I think the critical thing is that whatever it returns is the same type as clearInterval expects.
    updateOnlineStatusIntervalId = setInterval(updateOnlineStatus, 10000)
}

shouldPollIsAppOnline() && startUpdatingOnlineStatus()

export const MockableServiceStatus = {
    shouldBypassInternetCheck,
    shouldPollIsAppOnline,
    isConnectedToInternet,
    resetIsAppOnline: () => isAppOnline = undefined,
}
